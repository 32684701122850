import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import noimage from "../assets/compressed/no-image.webp";

const ImageCarouselModal = ({ images, startIndex = 0, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(startIndex);
    const isNoImageOnly = images.length === 1 && images[0] === noimage;

    const nextSlide = () => {
        if (!isNoImageOnly) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }
    };

    const prevSlide = () => {
        if (!isNoImageOnly) {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
        }
    };

    return (
        <div className="quest-more-image-overlay">
            <button className="quest-more-image-close-button" onClick={onClose}>
                <RxCross2 size={24} />
            </button>
            <div className="quest-more-image-carousel-container">
                {!isNoImageOnly && (
                    <button className="quest-more-image-prev-button" onClick={prevSlide}>
                        <IoIosArrowBack className="fs-4" />
                    </button>
                )}
                <img src={images[currentIndex]} alt="Carousel" className="quest-more-image-carousel" />
                {!isNoImageOnly && (
                    <button className="quest-more-image-next-button" onClick={nextSlide}>
                        <IoIosArrowForward className="fs-4" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default ImageCarouselModal;
