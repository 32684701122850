import React, { useState } from "react";
import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import hashtag from "../assets/compressed/hashtag.svg";
import song1 from "../assets/compressed/avatar1.jpg";
import song2 from "../assets/compressed/avatar2.jpg";
import quest from "../assets/compressed/quest.webp";
import quest1 from "../assets/compressed/quest3.webp";
import quest2 from "../assets/compressed/quest2.webp";
import taggedImage2 from "../assets/compressed/post2.jpg";
import taggedImage3 from "../assets/compressed/post3.jpg";
import taggedImage4 from "../assets/compressed/post4.jpg";
import taggedImage5 from "../assets/compressed/post5.jpg";
import { Link } from "react-router-dom";

const flicks = [
    { id: 1, image: taggedImage3, user: "Albert Einstein", username: "YO_albert" },
    { id: 2, image: taggedImage2, user: "Albert Einstein", username: "YO_albert" },
    { id: 3, image: taggedImage3, user: "Albert Einstein", username: "YO_albert" },
    { id: 4, image: taggedImage4, user: "Albert Einstein", username: "YO_albert" },
    { id: 5, image: taggedImage5, user: "Albert Einstein", username: "YO_albert" },
    { id: 6, image: taggedImage2, user: "Albert Einstein", username: "YO_albert" },
];

const quests = [
    { id: 1, image: quest, title: "Visit my plant nursery", description: "I'll pay $10 if you visit my plant nursery and take a photo." },
    { id: 2, image: quest1, title: "Visit my plant nursery", description: "I'll pay $10 if you visit my plant nursery and take a photo." },
    { id: 3, image: quest2, title: "Visit my plant nursery", description: "I'll pay $10 if you visit my plant nursery and take a photo." },
];

const tags = [
    { id: 1, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 2, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 3, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
];

const songs = [
    { id: 1, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", avatar: song1 },
    { id: 2, username: "Summer Vibes", handle: "Arvind Goyal", avatar: song2 },
    { id: 3, username: "Beautiful Morning", handle: "Arvind Goyal", avatar: song1 },
];

const peoples = [
    { id: 1, username: "Julia Smith", handle: "@juliasmith", avatar: avatar1, followed: false },
    { id: 2, username: "Christina Hopper", handle: "@chrishopper", avatar: avatar2, followed: false },
];

const Suggestions = ({ onNavigate, onClose }) => {
    const [people, setPeople] = useState(peoples);

    const toggleFollow = (id) => {
        setPeople((prevPeople) =>
            prevPeople.map((person) =>
                person.id === id ? { ...person, followed: !person.followed } : person
            )
        );
    };

    return (

        <div className="suggestion-overlay position-absolute">
            <div className="d-flex justify-content-between align-items-center mb-4 w-100 suggestion-heading">
                <h6 className="mb-0">Search Results</h6>
            </div>

            <div className="suggestion-section mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0">People</h6>
                    <button className="suggestion-btn-link" onClick={() => { onNavigate("people"); onClose(); }}>
                        See All
                    </button>
                </div>
                <div className="row mt-2 px-3 people-section">
                    {people.map((person) => (
                        <div to="/friend-profile" key={person.id} className="col-12 col-md-6 suggestion-item d-flex align-items-center gap-3 ">
                            <img src={person.avatar} alt="Avatar" className="suggestion-avatar" />
                            <Link to="/friend-profile" className="d-flex flex-column text-decoration-none">
                                <span className="suggestion-name">{person.username}</span>
                                <span className="suggestion-username">{person.handle}</span>
                            </Link>
                            <button
                                className={`suggestion-button ms-auto ${person.followed ? "suggestion-following-button" : ""}`}
                                onClick={() => toggleFollow(person.id)}
                            >
                                {person.followed ? "Following" : "Follow"}
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="suggestion-section flick-section mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0">Flicks</h6>
                    <button className="suggestion-btn-link" onClick={() => { onNavigate("flicks"); onClose(); }}>
                        See All
                    </button>
                </div>
                <div className="suggestion-flicks-container px-3">
                    <div className="suggestion-flicks-grid">
                        {flicks.slice(0, 6).map((flick) => (
                            <div key={flick.id} className="suggestion-flick-card">
                                <img src={flick.image} alt="Flick Post" className="suggestion-flick-image" />
                                <div className="suggestion-flick-info">
                                    <img src={avatar1} alt="user avatar" className="suggestion-avatar-icon" />
                                    <div>
                                        <p className="suggestion-user-name mb-0">{flick.user}</p>
                                        <p className="suggestion-user-handle mb-0">{flick.username}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="suggestion-section  mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0">Music</h6>
                    <button className="suggestion-btn-link" onClick={() => { onNavigate("music"); onClose(); }}>
                        See All
                    </button>
                </div>
                <div className="row mt-2 px-3 music-section">
                    {songs.map((song) => (
                        <div key={song.id} className="col-md-4 col-12 suggestion-item d-flex align-items-center gap-3">
                            <img src={song.avatar} alt="Avatar" className="suggestion-music-avatar" />
                            <div className="d-flex flex-column">
                                <span className="suggestion-song-name">{song.username}</span>
                                <span className="suggestion-song-username">{song.handle}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Quest Section (3 in a row) */}
            <div className="suggestion-section quest-section mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0">Quest</h6>
                    <button className="suggestion-btn-link" onClick={() => { onNavigate("quest"); onClose(); }}>
                        See All
                    </button>
                </div>
                <div className="row mt-2 px-3">
                    {quests.map((quest) => (
                        <div key={quest.id} className="col-4 suggestion-item d-flex gap-3">
                            <img src={quest.image} alt="Quest" className="suggestion-quest-image" />
                            <div className="d-flex flex-column">
                                <span className="suggestion-quest-name">{quest.title}</span>
                                <span className="suggestion-quest-description">{quest.description}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Tags Section (3 in a row) */}
            <div className="suggestion-section mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0">Tags</h6>
                    <button className="suggestion-btn-link" onClick={() => { onNavigate("tags"); onClose(); }}>
                        See All
                    </button>
                </div>
                <div className="row mt-2 px-3 teg-section">
                    {tags.map((tag) => (
                        <div key={tag.id} className="col-md-4 col-12 suggestion-item d-flex align-items-center gap-3">
                            <img src={tag.avatar} alt="Avatar" className="suggestion-tag-avatar" />
                            <div className="d-flex flex-column">
                                <span className="suggestion-tag-name">{tag.username}</span>
                                <span className="suggestion-tag-username">{tag.handle}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );

};

export default Suggestions;
