import React, { useState } from "react";
import { HuePicker } from "react-color";

const textStyles = [
    { fontFamily: "'Lobster', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Pacifico', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Bebas Neue', sans-serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Playfair Display', serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Rubik Wet Paint', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Permanent Marker', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Anton', sans-serif", fontSize: "20px", fontWeight: "900", color: "white" },
    { fontFamily: "'Great Vibes', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Black Ops One', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Indie Flower', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Caveat', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Righteous', sans-serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Satisfy', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Exo 2', sans-serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Orbitron', sans-serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Fredericka the Great', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Bungee', sans-serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Gloria Hallelujah', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Luckiest Guy', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Cinzel', serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Abril Fatface', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Fjalla One', sans-serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Montserrat Alternates', sans-serif", fontSize: "20px", fontWeight: "600", color: "white" },
    { fontFamily: "'Zilla Slab', serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Sacramento', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Shadows Into Light', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Alfa Slab One', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Patua One', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Carter One', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Dancing Script', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Bitter', serif", fontSize: "20px", fontWeight: "bold", color: "white" },
    { fontFamily: "'Merienda', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Courgette', cursive", fontSize: "20px", fontWeight: "normal", color: "white" },
    { fontFamily: "'Contrail One', cursive", fontSize: "20px", fontWeight: "bold", color: "white" },
];

const TextOptions = ({ onSelectTextStyle, onSelectColor }) => {
    const [selectedColor, setSelectedColor] = useState("#ffffff");

    const handleColorChange = (color) => {
        setSelectedColor(color.hex);
        onSelectColor(color.hex);
    };
    return (
        <>
            <div className="text-options-container">
                <h6 className="text-style-heading mt-2">Text Styles</h6>
                {textStyles.map((style, index) => {
                    const fontFamilyName = style.fontFamily.split(",")[0].replace(/['"]/g, "");
                    return (
                        <div
                            key={index}
                            className="text-option"
                            style={{ fontFamily: style.fontFamily, fontSize: style.fontSize, fontWeight: style.fontWeight, color: style.color, cursor: "pointer" }}
                            onClick={() => onSelectTextStyle({ ...style, color: selectedColor })}>
                            {fontFamilyName}
                        </div>
                    );
                })}
            </div>
            <div className="color-picker-section">
                <HuePicker
                    color={selectedColor}
                    onChange={handleColorChange}
                    direction="vertical"
                    className="hue-picker"
                />
            </div>
        </>
    );
};

export default TextOptions;

