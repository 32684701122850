import React, { useState } from "react";
import questData from "../data/QuestData";
import QuestActions from "./QuestActions";
import QuestList from "./QuestList";

const SponsoredQuest = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredQuests, setFilteredQuests] = useState(
        questData.filter((quest) => quest.questType === "sponsored")
    );

    const handleSearch = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchTerm(searchValue);

        let updatedQuests = questData.filter((quest) => quest.questType === "sponsored");

        if (searchValue) {
            updatedQuests = updatedQuests.filter(
                (quest) =>
                    quest.title.toLowerCase().includes(searchValue) ||
                    quest.description.toLowerCase().includes(searchValue) ||
                    quest.location.toLowerCase().includes(searchValue)
            );
        }
        setFilteredQuests(updatedQuests);
    };

    const applyFilters = (filters) => {
        let filtered = questData.filter((quest) => quest.questType === "sponsored");
        const min = filters.minAmount ? Number(filters.minAmount) : 0;
        const max = filters.maxAmount ? Number(filters.maxAmount) : Infinity;
        filtered = filtered.filter((q) => q.amount >= min && q.amount <= max);
        if (filters.selectedType !== "All") {
            filtered = filtered.filter((q) => q.status === filters.selectedType);
        }
        setFilteredQuests(filtered);
    };

    const applySorting = (options) => {
        let sortedQuests = [...filteredQuests];
        sortedQuests.sort((a, b) => {
            let dateComparison = 0;
            let amountComparison = 0;
            if (options.date !== "None") {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                dateComparison = options.date === "asc" ? dateA - dateB : dateB - dateA;
            }
            if (options.amount !== "None") {
                amountComparison = options.amount === "asc" ? a.amount - b.amount : b.amount - a.amount;
            }
            return dateComparison !== 0 ? dateComparison : amountComparison;
        });
        setFilteredQuests(sortedQuests);
    };

    return (
        <div className="quest-container">
            <QuestActions
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={handleSearch}
                applyFilters={applyFilters}
                applySorting={applySorting}
            />
            <QuestList quests={filteredQuests} activeTab="Sponsored" />
        </div>
    );
};

export default SponsoredQuest;
