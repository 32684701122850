import React, { useState } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import avatar3 from "../assets/compressed/avatar3.jpg";
import avatar4 from "../assets/compressed/avatar4.jpg";
import avatar5 from "../assets/compressed/avatar5.jpg";
import avatar6 from "../assets/compressed/avatar6.jpg";
import avatar7 from "../assets/compressed/avatar7.jpg";
import avatar8 from "../assets/compressed/avatar8.jpg";
import post1 from "../assets/compressed/post1.jpg";
import post2 from "../assets/compressed/post2.jpg";
import post3 from "../assets/compressed/post3.jpg";
import post4 from "../assets/compressed/post4.jpg";
import FriendRequest from "../components/FriendRequest";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import "../styles/Global.css";
import "../styles/Notification.css";

const Notification = () => {
    const [notifications, setNotifications] = useState([]);
    const [showFriendRequest, setShowFriendRequest] = useState(false); // New state
    const addNotification = (newNotification) => {
        setNotifications((prev) => [newNotification, ...prev]);
    };
    const handleFollowBack = (id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.map((notif) =>
                notif.id === id ? { ...notif, buttonState: "following" } : notif
            )
        );
    };
    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>

                <main className="col-12 col-md-10 col-lg-7 notification-main-content">
                    <div className="notifications-section">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="notification-title mb-0">Notifications</h1>
                            <span
                                className="friend-request-link mx-2 d-xl-none"
                                onClick={() => setShowFriendRequest(true)}
                            >
                                Friend Requests
                            </span>

                        </div>
                        <hr className="m-0" />

                        {/* Show Recent Notifications only if there are any */}
                        {notifications.length > 0 && (
                            <div className="notifications-category mt-4">
                                <h2 className="notification-category-title mb-0">Recent</h2>
                                {notifications.map((notification) => (
                                    <div key={notification.id} className="notification-item d-flex align-items-center justify-content-between mt-3">
                                        <div className="d-flex align-items-center">
                                            <img src={notification.avatar} alt="User Avatar" className="notification-avatar me-3" />
                                            <p className="notification-text mb-0 fw-light">
                                                <strong>{notification.username}, &nbsp;</strong>{notification.message}
                                            </p>
                                        </div>
                                        <div>
                                            {notification.buttonState === "follow_back" ? (
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => handleFollowBack(notification.id)}
                                                >
                                                    Follow Back
                                                </button>
                                            ) : (
                                                <button className="btn btn-secondary btn-sm" disabled>
                                                    Following
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="notifications-category mt-4">
                            <h2 className="notification-category-title mb-0">Today</h2>

                            {/* Comment Notification */}
                            <div className="notification-item d-flex align-items-start justify-content-between mt-3">
                                <div className="d-flex">
                                    <img
                                        src={avatar1}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <div>
                                        <p className="notification-text mb-0 fw-light">
                                            <strong>dua_lipa,</strong> commented on your post:
                                        </p>
                                        <p className="notification-comment">
                                            <em>"Looks great!"</em>&nbsp; <span className="notification-time">32m</span>
                                        </p>
                                    </div>
                                </div>
                                <img
                                    src={post1}
                                    alt="Commented Post"
                                    className="notification-post-thumbnail"
                                />
                            </div>

                            {/* Follow Request */}
                            <div className="notification-item d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={avatar2}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <p className="notification-text mb-0 fw-light">
                                        <strong>James_Levis,</strong> requested to follow you.&nbsp; <span className="notification-time">52m</span>
                                    </p>
                                </div>
                                <div className="request-buttons">
                                    <button className="btn btn-primary btn-sm me-2">Accept</button>
                                    <button className="btn btn-secondary btn-sm">Remove</button>
                                </div>
                            </div>

                            {/* Following Notification */}
                            <div className="notification-item d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={avatar3}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <p className="notification-text mb-0 fw-light">
                                        <strong>mavshi_lol,</strong> started following you.&nbsp; <span className="notification-time">1h</span>
                                    </p>
                                </div>
                                <button className="btn btn-primary btn-sm">Follow back</button>
                            </div>

                            {/* Like Notification */}
                            <div className="notification-item d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={avatar4}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <p className="notification-text mb-0 fw-light">
                                        <strong>dua_lipa,</strong> liked your post.&nbsp; <span className="notification-time">2h</span>
                                    </p>
                                </div>
                                <img
                                    src={post2}
                                    alt="Liked Post"
                                    className="notification-post-thumbnail"
                                />
                            </div>
                        </div>
                        <div className="notifications-category mt-4">
                            <h2 className="notification-category-title mb-0">This Week</h2>

                            {/* Comment Notification */}
                            <div className="notification-item d-flex align-items-start justify-content-between mt-3">
                                <div className="d-flex">
                                    <img
                                        src={avatar5}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <div>
                                        <p className="notification-text mb-0 fw-light">
                                            <strong>dua_lipa,</strong> commented on your post:
                                        </p>
                                        <p className="notification-comment">
                                            <em>Its looks good man!!❤️😍.</em>&nbsp; <span className="notification-time">3d</span>
                                        </p>
                                    </div>
                                </div>
                                <img
                                    src={post3}
                                    alt="Commented Post"
                                    className="notification-post-thumbnail"
                                />
                            </div>


                            <div className="notification-item d-flex align-items-start justify-content-between mt-3">
                                <div className="d-flex">
                                    <img
                                        src={avatar6}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <div>
                                        <p className="notification-text mb-0 fw-light">
                                            <strong>dua_lipa,</strong> Created a Quest, GoFlick :
                                        </p>
                                        <p className="notification-comment">
                                            <em>Visit my dairy storen i’ll pay...</em>&nbsp; <span className="notification-time">4d</span>
                                        </p>
                                    </div>
                                </div>
                                <img
                                    src={post4}
                                    alt="Commented Post"
                                    className="notification-post-thumbnail"
                                />
                            </div>

                            {/* Like Notification */}
                            <div className="notification-item d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={avatar7}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <p className="notification-text mb-0 fw-light">
                                        <strong>dua_lipa,</strong> Who you might know is on Flicks&nbsp; <span className="notification-time">1w</span>
                                    </p>
                                </div>
                            </div>

                            <div className="notification-item d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={avatar8}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <p className="notification-text mb-0 fw-light">
                                        <strong>dua_lipa,</strong> Followed you back.&nbsp; <span className="notification-time">1w</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="notifications-category mt-4">
                            <h2 className="notification-category-title mb-0">This Month</h2>

                            {/* Comment Notification */}
                            <div className="notification-item d-flex align-items-start justify-content-between mt-3">
                                <div className="d-flex">
                                    <img
                                        src={avatar1}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <div>
                                        <p className="notification-text mb-0 fw-light">
                                            <strong>dua_lipa,</strong> commented on your post:
                                        </p>
                                        <p className="notification-comment">
                                            <em>"Looks great!"</em>&nbsp; <span className="notification-time">32m</span>
                                        </p>
                                    </div>
                                </div>
                                <img
                                    src={post1}
                                    alt="Commented Post"
                                    className="notification-post-thumbnail"
                                />
                            </div>

                            {/* Follow Request */}
                            <div className="notification-item d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={avatar2}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <p className="notification-text mb-0 fw-light">
                                        <strong>James_Levis,</strong> requested to follow you.&nbsp; <span className="notification-time">52m</span>
                                    </p>
                                </div>
                                <div className="request-buttons">
                                    <button className="btn btn-primary btn-sm me-2">Accept</button>
                                    <button className="btn btn-secondary btn-sm">Remove</button>
                                </div>
                            </div>

                            {/* Following Notification */}
                            <div className="notification-item d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={avatar3}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <p className="notification-text mb-0 fw-light">
                                        <strong>mavshi_lol,</strong> started following you.&nbsp; <span className="notification-time">1h</span>
                                    </p>
                                </div>
                                <button className="btn btn-primary btn-sm">Follow back</button>
                            </div>

                            {/* Like Notification */}
                            <div className="notification-item d-flex align-items-center justify-content-between mt-3 mb-5">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={avatar4}
                                        alt="User Avatar"
                                        className="notification-avatar me-3"
                                    />
                                    <p className="notification-text mb-0 fw-light">
                                        <strong>dua_lipa,</strong> liked your post.&nbsp; <span className="notification-time">2h</span>
                                    </p>
                                </div>
                                <img
                                    src={post2}
                                    alt="Liked Post"
                                    className="notification-post-thumbnail"
                                />
                            </div>
                        </div>
                        {/* Friend Request Overlay */}
                        {showFriendRequest && (
                            <div className="friend-request-overlay">
                                <div className="friend-request-content">
                                    {/* Back Button */}
                                    <button
                                        className="btn btn-link back-button"
                                        onClick={() => setShowFriendRequest(false)}
                                    >
                                        <MdOutlineKeyboardBackspace />
                                    </button>
                                    <h2 className="friend-request-title">Follow Requests</h2>
                                    <FriendRequest addNotification={addNotification} />
                                </div>
                            </div>
                        )}
                    </div>
                </main>

                <aside className="col-3 d-none d-lg-flex flex-column py-3 home-right-sidebar">
                    <div className="home-user-details mb-1 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <img src={avatar1} alt="User Avatar" className="user-avatar" />
                            <div className="ms-3">
                                <p className="username mb-0">John Smith</p>
                                <p className="person-name mb-0">@johnsmith2045 </p>
                            </div>
                        </div>

                        <div className="user-icons">
                            <svg
                                className="mx-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="29"
                                viewBox="0 0 31 29"
                                fill="none"
                            >
                                <path
                                    d="M27.5556 29H3.44444C1.55 29 0 27.55 0 25.7778V3.22222C0 1.45 1.55 0 3.44444 0H27.5556C29.45 0 31 1.45 31 3.22222V25.7778C31 27.55 29.45 29 27.5556 29Z"
                                    fill="#007AFF"
                                />
                                <path
                                    d="M21.3557 18.5012C21.3557 22.605 17.3104 23.1179 16.5652 23.1179V25.375H14.649V23.1179C14.0102 23.0153 9.53906 22.605 9.53906 17.6804H13.0521C13.0521 18.1934 13.0521 20.5531 15.7135 20.5531C17.9491 20.5531 17.9491 18.809 17.9491 18.6038C17.9491 14.9104 10.2843 16.4493 10.2843 10.704C10.2843 7.2158 13.5844 6.08726 15.0748 6.08726V3.625H16.991V6.08726C18.162 6.18986 21.4621 7.11321 21.4621 11.4222H17.9491C17.9491 10.4988 17.7362 8.65212 15.7135 8.65212C13.6909 8.65212 13.6908 10.3962 13.6908 10.704C13.5844 14.0896 21.3557 12.6533 21.3557 18.5012Z"
                                    fill="white"
                                />
                            </svg>
                            <i className="bi bi-gear"></i>
                        </div>
                    </div>
                    <hr />
                    <FriendRequest addNotification={addNotification} />
                </aside>
            </div >
            <MobileNav />
        </div >
    );
};

export default Notification;
