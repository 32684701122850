import React, { useState, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { MdNavigateNext, MdNavigateBefore, MdMoreVert } from "react-icons/md";
import { FaPlay, FaVolumeUp, FaEllipsisH } from "react-icons/fa";
import FlickStarLogo from "../assets/compressed/logo1.png";
import song from "../assets/compressed/song.png";
import { GrEmoji } from "react-icons/gr";
import { FiHeart } from "react-icons/fi";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { VscEye } from "react-icons/vsc";
import { CiPaperplane } from "react-icons/ci";
import useOutsideClick from "../hooks/useOutsideClick";
import { GoSearch } from "react-icons/go";
import { ImBin } from "react-icons/im";

const StoryView = ({ stories, currentStoryIndex, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(currentStoryIndex);
    const [showViewers, setShowViewers] = useState(false);
    const [activeViewer, setActiveViewer] = useState(null);
    const [confirmAction, setConfirmAction] = useState(null);
    const [replyViewer, setReplyViewer] = useState(null);

    const viewerOptionsRef = useRef(null);
    const confirmBoxRef = useRef(null);

    useOutsideClick(viewerOptionsRef, () => setActiveViewer(null), activeViewer !== null);

    useOutsideClick(confirmBoxRef, () => setConfirmAction(null), confirmAction !== null);

    const isYourStory = stories[currentIndex]?.id === 1;

    const goNext = () => {
        if (currentIndex < stories.length - 1) {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    const goPrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    const getClassNameForStory = (index) => {
        const difference = index - currentIndex;
        if (difference === 0) return "current-story";
        if (Math.abs(difference) === 1) return "nearby-story";
        if (Math.abs(difference) === 2) return "far-story";
        return "hidden-story";
    };

    const handleConfirm = (action, viewer) => {
        setConfirmAction({ action, viewer });
    };

    const closeConfirm = () => {
        setConfirmAction(null);
    };

    const handleReply = (viewer) => {
        setReplyViewer(viewer);
    };

    const closeReply = () => {
        setReplyViewer(null);
    };

    return (
        <div className="story-view-overlay">
            <div className="story-view-header">
                <div className="logo-content mb-4 mt-4">
                    <img src={FlickStarLogo} alt="FlickStar Logo" className="logo" />
                    <div className="content">FlickStar</div>
                </div>

                {isYourStory && showViewers && (
                    <>
                        {/* Delete Icon */}
                        <button className="story-delete-icon" onClick={() => console.log('Delete action triggered')}>
                            <ImBin />
                        </button>

                        {/* Three Dots Icon */}
                        <button className="more-options-icon" onClick={() => console.log('More options clicked')}>
                            <MdMoreVert />
                        </button>
                    </>
                )}
                <button className="close-button" onClick={onClose}>
                    <RxCross2 />
                </button>
            </div>

            <div className={`story-view-content ${showViewers ? "shrinked" : ""}`}>
                <div className="story-carousel">
                    {stories.map((story, index) => {
                        const className = getClassNameForStory(index);

                        if (className === "hidden-story" && !isYourStory) return null;

                        if (isYourStory && story.id === 1) {
                            return (
                                <div key={index} className="story current-story">

                                    <img
                                        src={story.images}
                                        alt={story.name}
                                        className="story-view-image"
                                        onClick={() => setShowViewers(true)}
                                    />
                                    {!showViewers && (
                                        <>
                                            <div className="viewers-section" onClick={() => setShowViewers(true)}>
                                                <div className="avatars">
                                                    {story.viewers.slice(0, 3).map((viewer, idx) => (
                                                        <img
                                                            key={idx}
                                                            src={viewer.avatar}
                                                            alt={`Viewer ${idx}`}
                                                            className="viewer-avatar"
                                                        />
                                                    ))}
                                                </div>
                                                <p className="views-text">{story.views} Views</p>
                                            </div>
                                            <div className="story-info">
                                                <div className="progress-container mt-2 mb-2">
                                                    <div
                                                        className="progress"
                                                    ></div>
                                                </div>

                                                <div className="avatar-section mt-4">
                                                    <img
                                                        src={story.img}
                                                        alt={story.name}
                                                        className="avatar"
                                                    />
                                                    <div className="details">
                                                        <p className="username">{story.username}</p>
                                                        <div className="story-song-info">
                                                            <img
                                                                src={song}
                                                                alt="song-avatar"
                                                                className="story-song-avatar"
                                                            />
                                                            <span>{story.song}</span>
                                                            <span className="story-dot">•</span>
                                                            <span>Charlie Puth</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Icons */}
                                                <div className="icons mt-4">
                                                    <FaPlay />
                                                    <FaVolumeUp />
                                                    <FaEllipsisH />
                                                    <RxCross2 onClick={onClose} className="d-md-none fw-bold" />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            );
                        }

                        return (
                            <div
                                key={index}
                                className={`story ${className}`}
                                onClick={() => setCurrentIndex(index)}
                            >
                                <img
                                    src={story.images}
                                    alt={story.name}
                                    className="story-view-image"
                                />

                                <div
                                    className={` ${index === currentIndex
                                        ? ""
                                        : Math.abs(index - currentIndex) === 1
                                            ? "story-info-nearby"
                                            : Math.abs(index - currentIndex) === 2
                                                ? "story-info-far"
                                                : ""
                                        }`}
                                >
                                    {index !== currentIndex && (
                                        <img
                                            src={story.img}
                                            alt={story.name}
                                            className="avatar"
                                        />
                                    )}
                                    {index !== currentIndex && <p>{story.name}</p>}

                                    {index === currentIndex && (
                                        <>
                                            <div className="story-info">
                                                <div className="progress-container mt-2 mb-2">
                                                    <div
                                                        className="progress"
                                                    ></div>
                                                </div>

                                                <div className="avatar-section mt-4">
                                                    <img
                                                        src={story.img}
                                                        alt={story.name}
                                                        className="avatar"
                                                    />
                                                    <div className="details">
                                                        <p className="username">{story.username}</p>
                                                        <div className="story-song-info">
                                                            <img
                                                                src={song}
                                                                alt="song-avatar"
                                                                className="story-song-avatar"
                                                            />
                                                            <span>{story.song}</span>
                                                            <span className="story-dot">•</span>
                                                            <span>Charlie Puth</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Icons */}
                                                <div className="icons mt-4">
                                                    <FaPlay />
                                                    <FaVolumeUp />
                                                    <FaEllipsisH />
                                                    <RxCross2 onClick={onClose} className="d-md-none fw-bold" />

                                                </div>
                                            </div>

                                            {/* Reply Section */}
                                            <div className="reply-section">
                                                <div className="reply-input-container">
                                                    <input
                                                        type="text"
                                                        placeholder="Add a reply..."
                                                        className="reply-input"
                                                    />
                                                    <button className="emoji-button">
                                                        <GrEmoji className="fs-5" />
                                                    </button>
                                                    <button className="emoji-button">
                                                        <FiHeart className="fs-5" />
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>

                {showViewers && isYourStory && (
                    <div className="viewers-container">
                        <div className="d-flex align-items-center justify-content-between">
                            <h6 className="d-flex align-items-center">
                                <VscEye className="me-2 fs-5" /> 19
                            </h6>
                            <GoSearch />
                        </div>


                        <hr />
                        <h6 className="view-heading">Viewers</h6>
                        {stories[currentIndex].viewers.map((viewer, idx) => (
                            <div
                                key={idx}
                                className="viewer-row"
                            >
                                <div className="viewer-avatar-container">
                                    <img
                                        src={viewer.avatar}
                                        alt={viewer.username}
                                        className="viewer-avatar"
                                        onClick={() => setActiveViewer(idx === activeViewer ? null : idx)}
                                    />
                                    {viewer.icon === 'heart' && <span className="icon-heart">❤️</span>}
                                    {viewer.icon === 'love' && <span className="icon-love">😍</span>}
                                    {viewer.icon === 'fire' && <span className="icon-fire">🔥</span>}
                                </div>
                                <span
                                    className="viewer-username"
                                    onClick={() => setActiveViewer(idx === activeViewer ? null : idx)}
                                >
                                    {viewer.username}
                                </span>
                                <IoPaperPlaneOutline
                                    className="paperplane-icon fs-4"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleReply(viewer);
                                    }}
                                />
                                {activeViewer === idx && (
                                    <div
                                        className="viewer-options-card position-absolute"
                                        ref={viewerOptionsRef}
                                    >
                                        <div
                                            className="viewer-option block"
                                            onClick={() => handleConfirm("Block", viewer)}
                                        >
                                            Block
                                        </div>
                                        <div
                                            className="viewer-option"
                                            onClick={() => handleConfirm("Remove", viewer)}
                                        >
                                            Remove Follower
                                        </div>
                                        <div className="viewer-option">View Profile</div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}


                {replyViewer && (
                    <div className="storyview-reply-box">
                        <button
                            className="storyview-reply-close"
                            onClick={closeReply}
                        >
                            <RxCross2 />
                        </button>
                        <img src={replyViewer.avatar} alt={replyViewer.username} className="storyview-reply-avatar me-2" />
                        <span className="storyview-reply-text">Reply to {replyViewer.username}</span>
                        <div className="storyview-reply-input-wrapper">
                            <input
                                type="text"
                                className="storyview-reply-input-field"
                                placeholder="Write a reply..."
                            />
                            <CiPaperplane className="storyview-send-reply-icon fs-2" />
                        </div>
                    </div>
                )}

                {confirmAction && (
                    <div className="confirm-box" ref={confirmBoxRef}>
                        <img src={confirmAction.viewer.avatar} alt={confirmAction.viewer.username} className="confirm-avatar" />
                        <p className="confirm-text">
                            Are you sure you want to {confirmAction.action} {confirmAction.viewer.username}?
                        </p>
                        <div className="confirm-buttons">
                            <button className="confirm-button" onClick={() => { closeConfirm(); }}>
                                {confirmAction.action}
                            </button>
                            <button className="cancel-button" onClick={closeConfirm}>Close</button>
                        </div>
                    </div>
                )}

                {!isYourStory && (
                    <div className="story-navigation">
                        {currentIndex > 0 && (
                            <button className="story-nav-button story-left" onClick={goPrevious}>
                                <MdNavigateBefore />
                            </button>
                        )}
                        {currentIndex < stories.length - 1 && (
                            <button className="story-nav-button story-right" onClick={goNext}>
                                <MdNavigateNext />
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default StoryView;
