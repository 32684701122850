import React, { useEffect, useRef, useState } from "react";
import "react-advanced-cropper/dist/style.css";
import { RxCross2 } from "react-icons/rx";
import BottomActions from "./BottomActions";
import PopupConfirmation from "./PopupConfirmation";
import AspectRatioOptions from "./AspectRatioOptions";
import CropTools from "./CropTools";
import ToolButtons from "./ToolButtons";
import ImagePreview from "./ImagePreview";
import StoryMusic from "./StoryMusic";
import ColorPicker from "./ColorPicker";
import FiltersPanel from "./FiltersPanel";
import DrawingCanvas from "./DrawingCanvas";
import UndoRedoControls from "./UndoRedoControls";
import TextOptions from "./TextOptions";
import StickerPicker from "./StickerPicker";
import StorySong from "./StorySong";
import StorySetting from "./StorySetting";

const StoryOptions = ({ file, onCancel }) => {
    const cropperRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [activeTool, setActiveTool] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(undefined);
    const [rotation, setRotation] = useState(0);
    const [bgColor, setBgColor] = useState("rgba(255, 255, 255, 1)");
    const [flip, setFlip] = useState({ horizontal: false, vertical: false });
    const [showAspectOptions, setShowAspectOptions] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
    const [setZoom] = useState(1);
    const [setPosition] = useState({ x: 0, y: 0 });
    const [objectFit, setObjectFit] = useState("cover");
    const [isFinalized, setIsFinalized] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("no-effect");
    const [undoRedoFunctions, setUndoRedoFunctions] = useState({ undo: null, redo: null, canUndo: false, canRedo: false });
    const [textItems, setTextItems] = useState([]);
    const [selectedTextIndex, setSelectedTextIndex] = useState(null);
    const [addedStickers, setAddedStickers] = useState([]);
    const [selectedSong, setSelectedSong] = useState(null);
    const [savedEdits, setSavedEdits] = useState(null);
    const [videoSrc, setVideoSrc] = useState(null);
    const audioRef = useRef(null);

    useEffect(() => {
        if (file) {
            const initialImage = URL.createObjectURL(file);
            const fileType = file.type.split("/")[0];

            if (fileType === "image") {
                setImageSrc(initialImage);
                setVideoSrc(null);
            } else if (fileType === "video") {
                setVideoSrc(initialImage);
                setImageSrc(null);
            }
        }
    }, [file]);

    const handleCancel = () => {
        setActionType("cancel");
        setShowPopup(true);
    };

    const handleTextClick = (index) => {
        setSelectedTextIndex(index);
    };

    const handleTextStyleSelect = (style) => {
        setTextItems((prevItems) => {
            if (selectedTextIndex !== null) {
                const newItems = [...prevItems];
                newItems[selectedTextIndex].style = style;
                return newItems;
            } else {
                const container = document.querySelector(".image-preview-container");
                if (!container) return prevItems;
                const containerRect = container.getBoundingClientRect();
                return [
                    ...prevItems,
                    {
                        text: "Click to edit",
                        style,
                        x: containerRect.width / 2 - 50,
                        y: containerRect.height / 2 - 20,
                    },
                ];
            }
        });
        setSelectedTextIndex(null);
    };

    const handleColorSelect = (color) => {
        if (selectedTextIndex !== null) {
            setTextItems((prevItems) => {
                const newItems = [...prevItems];
                newItems[selectedTextIndex].style.color = color;
                return newItems;
            });
        }
    };

    const handleStickerSelect = (sticker) => {
        setAddedStickers([...addedStickers, { src: sticker, x: 50, y: 50 }]);
    };

    const handleSongSelect = (song) => {
        setSelectedSong(song);
    };

    const confirmAction = () => {
        if (actionType === "cancel") {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                audioRef.current = null;
            }
            setSelectedSong(null);
            onCancel();
        }
        setShowPopup(false);
    };

    const handleToolClick = (tool) => {
        if (tool === "aspect") {
            setShowAspectOptions((prev) => !prev);
            setShowColorPicker(false);
        } else if (tool === "bgColor") {
            setShowColorPicker((prev) => !prev);
            setShowAspectOptions(false);
        } else {
            setShowAspectOptions(false);
            setShowColorPicker(false);
            setActiveTool(tool);
        } if (tool === "crop") {
            setObjectFit("contain");
        } if (tool === "draw") {
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            let img = new Image();
            img.src = imageSrc;
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                if (selectedFilter !== "no-effect") {
                    ctx.filter = selectedFilter;
                    ctx.drawImage(canvas, 0, 0);
                }
                const finalImage = canvas.toDataURL();
                setImageSrc(finalImage);
            };
        };
    };

    const handleAspectRatioChange = (ratio) => {
        if (ratio === "original") {
            setAspectRatio(undefined);
            setObjectFit("contain");
        } else if (ratio === "fit") {
            setAspectRatio(undefined);
            setObjectFit("cover");
        } else {
            setAspectRatio(ratio);
            setObjectFit("contain");
        } setShowAspectOptions(false);
    };

    const handleRotation = () => {
        setRotation((prev) => (prev + 90) % 360);
    };
    const handleFlipHorizontal = () => {
        setFlip((prev) => ({ ...prev, horizontal: !prev.horizontal }));
    };

    const handleFlipVertical = () => {
        setFlip((prev) => ({ ...prev, vertical: !prev.vertical }));
    };

    const handleBgColorChange = (color) => {
        setBgColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    };

    const saveDrawing = (newImage) => {
        if (newImage) {
            setImageSrc(newImage);
            setCroppedImage(newImage);
            setActiveTool(null);
        }
    };

    const handleReset = () => {
        setCroppedImage(null);
        setRotation(0);
        setFlip({ horizontal: false, vertical: false });
        setAspectRatio(undefined);
        setObjectFit("cover");
        setZoom(1);
        setPosition({ x: 0, y: 0 });
        setBgColor("#fff");
        setActiveTool(null);
        setIsFinalized(false);
        setShowColorPicker(false);
        setSelectedFilter("no-effect");
        setTextItems([]);
        setSelectedTextIndex(null);
        setAddedStickers([]);
        setSelectedSong(null);
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current = null;
        }
        if (file) {
            const initialImage = URL.createObjectURL(file);
            setImageSrc(initialImage);
        }
    };

    const handleEdit = () => {
        if (savedEdits) {
            setImageSrc(savedEdits.imageSrc);
            setCroppedImage(savedEdits.croppedImage);
            setRotation(savedEdits.rotation);
            setFlip(savedEdits.flip);
            setTextItems(savedEdits.textItems);
            setAddedStickers(savedEdits.addedStickers);
            setSelectedSong(savedEdits.selectedSong);
            setSelectedFilter(savedEdits.selectedFilter);
        }
        setIsFinalized(false);
    };

    const handleCropDone = () => {
        if (cropperRef.current) {
            const canvas = cropperRef.current.getCanvas();
            if (canvas) {
                const tempCanvas = document.createElement("canvas");
                const tempCtx = tempCanvas.getContext("2d");
                tempCanvas.width = canvas.width;
                tempCanvas.height = canvas.height;
                tempCtx.translate(tempCanvas.width / 2, tempCanvas.height / 2);
                tempCtx.rotate((rotation * Math.PI) / 180);
                tempCtx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
                tempCtx.drawImage(canvas, -canvas.width / 2, -canvas.height / 2);
                const newImage = tempCanvas.toDataURL();
                setCroppedImage(newImage);
                setImageSrc(newImage);
                setRotation(0);
                setFlip({ horizontal: false, vertical: false });
                setActiveTool(null);
                setShowColorPicker(false);
            }
        }
    };
    const finalizeChanges = () => {
        setSavedEdits({
            imageSrc,
            croppedImage,
            rotation,
            flip,
            textItems,
            addedStickers,
            selectedSong,
            selectedFilter,
        });
        setIsFinalized(true);
        setActiveTool(null);
    };

    const handleDownload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.src = imageSrc;
        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            if (selectedFilter !== "no-effect") {
                ctx.filter = selectedFilter;
                ctx.drawImage(image, 0, 0);
            }
            textItems.forEach((item) => {
                ctx.font = "20px Arial";
                ctx.fillStyle = item.style.color || "#000";
                ctx.fillText(item.text, item.x, item.y);
            });

            let loadedStickers = 0;
            addedStickers.forEach((sticker) => {
                const stickerImage = new Image();
                stickerImage.crossOrigin = "anonymous";
                stickerImage.src = sticker.src;
                stickerImage.onload = () => {
                    ctx.drawImage(stickerImage, sticker.x, sticker.y, 100, 100);
                    loadedStickers++;
                    if (loadedStickers === addedStickers.length) {
                        exportCanvas(canvas);
                    }
                };
                stickerImage.onerror = () => console.warn("Failed to load sticker:", sticker.src);
            });

            if (addedStickers.length === 0) {
                exportCanvas(canvas);
            }
        };
    };

    const exportCanvas = (canvas) => {
        setTimeout(() => {
            try {
                canvas.toBlob((blob) => {
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "story-image.png";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }, "image/png");
            } catch (error) {
                console.error("Failed to export image:", error);
            }
        }, 500);
    };

    return (
        <div className="story-options-container">
            <div className="top-section">
                {!isFinalized && (
                    <button className="reset-button" onClick={handleReset}>
                        Reset
                    </button>
                )}
                {activeTool === "draw" && !isFinalized && (<UndoRedoControls undo={undoRedoFunctions.undo} redo={undoRedoFunctions.redo} canUndo={undoRedoFunctions.canUndo} canRedo={undoRedoFunctions.canRedo} />)}
                <div className="cancel-finalize-buttons text-end">
                    {!isFinalized && (
                        <button className="finalize-button" onClick={finalizeChanges}>
                            Preview
                        </button>
                    )}
                    <button className="close-button" onClick={handleCancel} ><RxCross2 /></button>
                </div>
            </div>
            <div className="image-container">
                <ToolButtons activeTool={activeTool} handleToolClick={handleToolClick} isFinalized={isFinalized} />
                {activeTool === "draw" ? (<DrawingCanvas imageSrc={imageSrc} videoSrc={videoSrc} onSaveDrawing={saveDrawing} setUndoRedoFunctions={setUndoRedoFunctions} bgColor={bgColor} filter={selectedFilter} />
                ) : (
                    <ImagePreview
                        activeTool={activeTool}
                        cropperRef={cropperRef}
                        croppedImage={croppedImage}
                        imageSrc={imageSrc}
                        bgColor={bgColor}
                        videoSrc={videoSrc}
                        rotation={rotation}
                        flip={flip}
                        objectFit={objectFit}
                        aspectRatio={aspectRatio}
                        filter={selectedFilter}
                        textItems={textItems}
                        setTextItems={setTextItems}
                        selectedTextIndex={selectedTextIndex}
                        setSelectedTextIndex={setSelectedTextIndex}
                        handleTextClick={handleTextClick}
                        addedStickers={addedStickers}
                        setAddedStickers={setAddedStickers}
                        selectedSong={selectedSong}
                        setSelectedSong={setSelectedSong}
                    />
                )}
            </div>

            {((!isFinalized && activeTool !== "music") || selectedSong) && (<StoryMusic selectedSong={selectedSong} audioRef={audioRef} onSelectSong={() => setActiveTool("music")} onDeleteSong={() => setSelectedSong(null)} />)}
            {activeTool === "crop" && !isFinalized && (<CropTools handleToolClick={handleToolClick} handleRotation={handleRotation} handleFlipHorizontal={handleFlipHorizontal} handleFlipVertical={handleFlipVertical} handleCropDone={handleCropDone} />)}
            {activeTool === "music" && (<StorySong onSongSelect={handleSongSelect} selectedSong={selectedSong} />)}
            {activeTool === "sticker" && (<StickerPicker onSelectSticker={handleStickerSelect} />)}
            {activeTool === "text" && (<TextOptions onSelectTextStyle={handleTextStyleSelect} onSelectColor={handleColorSelect} />)}
            {activeTool === "filter" && <FiltersPanel onSelectFilter={setSelectedFilter} activeFilter={selectedFilter} />}
            {showAspectOptions && <AspectRatioOptions handleAspectRatioChange={handleAspectRatioChange} />}
            {showColorPicker && <ColorPicker mode="background" bgColor={bgColor} handleBgColorChange={handleBgColorChange} />}
            {isFinalized && <BottomActions onEdit={handleEdit} onDownload={handleDownload} />}
            <PopupConfirmation showPopup={showPopup} actionType={actionType} confirmAction={confirmAction} setShowPopup={setShowPopup} />
            {isFinalized && (<StorySetting />)}
        </div>
    );
};

export default StoryOptions;