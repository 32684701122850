import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import axios from "axios";
import WaveSurfer from "wavesurfer.js";
import Hover from "wavesurfer.js/dist/plugins/hover.esm.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import { IoPlayCircleOutline, IoPauseCircleOutline } from "react-icons/io5";
import MusicBackground from "../assets/compressed/music.webp"
import { CgMathPlus } from "react-icons/cg";

const StorySong = ({ onSongSelect }) => {
    const [songs, setSongs] = useState([]);
    const [filteredSongs, setFilteredSongs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedSong, setSelectedSong] = useState(null);
    const [currentPlaying, setCurrentPlaying] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [uploadedSongs, setUploadedSongs] = useState(() => {
        const savedSongs = sessionStorage.getItem("uploadedSongs");
        return savedSongs ? JSON.parse(savedSongs) : [];
    });
    const [selectedSongRegions, setSelectedSongRegions] = useState({});
    const [currentTime, setCurrentTime] = useState(0);
    const [songDuration, setSongDuration] = useState(30);
    const regionData = useRef({ start: 0, end: 15 });
    const audioRef = useRef(null);
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const regionRef = useRef(null);
    const songListRef = useRef(null);
    const scrollPosition = useRef(0);
    const fileInputRef = useRef(null);
    const progressRef = useRef(null);

    useEffect(() => {
        const fetchSongs = async () => {
            try {
                const hollywoodResponse = await axios.get("https://itunes.apple.com/search?term=pop&entity=song&limit=100");
                const bollywoodResponse = await axios.get("https://itunes.apple.com/search?term=bollywood&entity=song&limit=100");
                const allSongs = [...(hollywoodResponse.data.results || []), ...(bollywoodResponse.data.results || [])];
                setSongs(allSongs);
                setFilteredSongs(allSongs);
            } catch (error) {
                console.error("Error fetching songs", error);
            }
        };
        fetchSongs();
    }, []);

    useEffect(() => {
        if (selectedSong && waveformRef.current) {
            if (wavesurfer.current) {
                wavesurfer.current.destroy();
            }
            const regionsPlugin = RegionsPlugin.create();
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: "#888",
                progressColor: "#fff",
                cursorColor: "#fff",
                barWidth: 6,
                responsive: true,
                height: 50,
                barGap: 7,
                barRadius: 2,
                minPxPerSec: 10,
                scrollParent: true,
                hideScrollbar: true,
                plugins: [
                    Hover.create({
                        lineColor: "#ff0000",
                        lineWidth: 2,
                        labelBackground: "#555",
                        labelColor: "#fff",
                        labelSize: "11px",
                    }),
                    regionsPlugin,
                ],
            });

            wavesurfer.current.load(selectedSong.previewUrl);
            wavesurfer.current.once("ready", () => {
                setSongDuration(wavesurfer.current.getDuration());

                const savedRegion = selectedSongRegions[selectedSong.trackId] || { start: 10, end: 25 };
                regionRef.current = regionsPlugin.addRegion({
                    start: savedRegion.start,
                    end: savedRegion.end,
                    color: "rgba(255, 0, 0, 0.3)",
                    drag: true,
                    resize: false,
                });
                regionData.current = { start: savedRegion.start, end: savedRegion.end };
                regionRef.current.on("update-end", (region) => {
                    if (!region) return;
                    regionData.current.start = region.start;
                    regionData.current.end = region.start + 15;
                    region.update({
                        start: region.start,
                        end: region.start + 15,
                    });
                });
                wavesurfer.current.on("audioprocess", () => {
                    setCurrentTime(wavesurfer.current.getCurrentTime());
                });

                wavesurfer.current.on("seek", () => {
                    setCurrentTime(wavesurfer.current.getCurrentTime());
                });
            });

            return () => wavesurfer.current?.destroy();
        }
    }, [selectedSong]);


    const playSelectedRegion = () => {
        if (wavesurfer.current && regionRef.current) {
            const start = regionRef.current.start;
            const end = start + 15;

            if (isPlaying) {
                wavesurfer.current.pause();
                setIsPlaying(false);
            } else {
                wavesurfer.current.setTime(start);
                wavesurfer.current.play();
                setIsPlaying(true);

                const stopAtRegionEnd = () => {
                    if (wavesurfer.current.getCurrentTime() >= end) {
                        wavesurfer.current.pause();
                        wavesurfer.current.setTime(start);
                        wavesurfer.current.un("audioprocess", stopAtRegionEnd);
                        setIsPlaying(false);
                    }
                };
                wavesurfer.current.on("audioprocess", stopAtRegionEnd);
            }
        }
    };

    useLayoutEffect(() => {
        if (!selectedSong && songListRef.current) {
            songListRef.current.scrollTop = scrollPosition.current;
        }
    }, [selectedSong]);

    const handleSearch = async (query) => {
        setSearchTerm(query);

        if (!query.trim()) {
            setFilteredSongs(songs);
            return;
        }
        try {
            const response = await axios.get(`https://itunes.apple.com/search?term=${query}&entity=song&limit=50`);
            setFilteredSongs(response.data.results || []);
        } catch (error) {
            console.error("Error searching for songs", error);
        }
    };

    const handleSongClick = (song) => {
        if (songListRef.current) {
            scrollPosition.current = songListRef.current.scrollTop;
        }
        setSelectedSong(song);
        stopInlinePlayback();
    };

    const handleCancel = () => {
        setSelectedSong(null);
    };


    const handleUseAudio = () => {
        if (selectedSong && regionRef.current) {
            if (wavesurfer.current && wavesurfer.current.isPlaying()) {
                wavesurfer.current.pause();
            }

            const updatedSong = {
                trackId: selectedSong.trackId,
                trackName: selectedSong.trackName,
                artistName: selectedSong.artistName,
                previewUrl: selectedSong.previewUrl,
                artworkUrl60: selectedSong.artworkUrl60,
                startTime: regionRef.current.start,
                endTime: regionRef.current.start + 15,
            };
            setSelectedSongRegions(prevRegions => ({
                ...prevRegions,
                [selectedSong.trackId]: { start: updatedSong.startTime, end: updatedSong.endTime }
            }));
            onSongSelect(updatedSong);
            setSelectedSong(null);
        }
    };

    const handleProgressClick = (event) => {
        if (wavesurfer.current && progressRef.current) {
            const rect = progressRef.current.getBoundingClientRect();
            const clickX = event.clientX - rect.left;
            const percentage = clickX / rect.width;
            const newTime = percentage * songDuration;
            wavesurfer.current.setTime(newTime);
            setCurrentTime(newTime);
        }
    };

    const togglePlayPause = (song) => {
        if (currentPlaying === song.trackId) {
            if (audioRef.current) {
                if (!audioRef.current.paused) {
                    audioRef.current.pause();
                    setCurrentPlaying(null);
                } else {
                    audioRef.current.play();
                    setCurrentPlaying(song.trackId);
                }
            }
        } else {
            stopInlinePlayback();
            audioRef.current = new Audio(song.previewUrl);
            audioRef.current.play();
            setCurrentPlaying(song.trackId);

            audioRef.current.onended = () => {
                setCurrentPlaying(null);
            };
        }
    };

    const stopInlinePlayback = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current = null;
        }
        setCurrentPlaying(null);
    };

    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const objectUrl = URL.createObjectURL(file);

            const uploadedSong = {
                trackId: `local-${Date.now()}`,
                trackName: file.name.replace(/\.[^/.]+$/, ""),
                artistName: "Local File",
                previewUrl: objectUrl,
                artworkUrl60: MusicBackground,
            };

            const updatedSongs = [uploadedSong, ...uploadedSongs];

            setUploadedSongs(updatedSongs);
            setSongs((prevSongs) => [uploadedSong, ...prevSongs]);
            setFilteredSongs((prevSongs) => [uploadedSong, ...prevSongs]);
            sessionStorage.setItem("uploadedSongs", JSON.stringify(updatedSongs));
        }
    };
    useEffect(() => {
        const savedSongs = sessionStorage.getItem("uploadedSongs");
        if (savedSongs) {
            const parsedSongs = JSON.parse(savedSongs);
            setSongs((prevSongs) => {
                const uniqueSongs = [...parsedSongs, ...prevSongs].reduce((acc, song) => {
                    if (!acc.some((s) => s.trackId === song.trackId)) {
                        acc.push(song);
                    }
                    return acc;
                }, []);
                return uniqueSongs;
            });
            setFilteredSongs((prevSongs) => {
                const uniqueFiltered = [...parsedSongs, ...prevSongs].reduce((acc, song) => {
                    if (!acc.some((s) => s.trackId === song.trackId)) {
                        acc.push(song);
                    }
                    return acc;
                }, []);
                return uniqueFiltered;
            });
        }
    }, []);

    return (
        <div className="story-music">
            {!selectedSong && (
                <div className="song-header-container">
                    <div className="song-header">
                        <h3 className="song-heading mb-0">Music</h3>
                        <button className="add-music-button" onClick={openFileDialog}><CgMathPlus /></button>
                    </div>
                    <input
                        type="file"
                        ref={fileInputRef}
                        accept="audio/*"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                    />
                    <input
                        type="text"
                        placeholder="Search by song or movie name..."
                        className="song-search"
                        value={searchTerm}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
            )}
            {!selectedSong ? (
                <div className="music-list" ref={songListRef}>
                    {filteredSongs.map((song) => (
                        <div key={song.trackId} className="song-item">
                            <div className="song-info" onClick={() => handleSongClick(song)}>
                                <img src={song.artworkUrl60} alt={song.trackName} className="song-image" />
                                <div>
                                    <div className="song-title">{song.trackName}</div>
                                    <div className="song-artist">{song.artistName}</div>
                                </div>
                            </div>
                            <button
                                className="play-pause-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    togglePlayPause(song);
                                }}
                            >
                                {currentPlaying === song.trackId ? <IoPauseCircleOutline className="fs-4" /> : <IoPlayCircleOutline className="fs-4" />}
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="song-bar">
                    <img src={selectedSong.artworkUrl60} alt={selectedSong.trackName} className="selected-song-image" />
                    <p className="song-bar-title">{selectedSong.trackName}</p>
                    <div ref={waveformRef} className="waveform-container"></div>
                    <div className="full-song-progress-container" ref={progressRef} onClick={handleProgressClick}>
                        <div className="full-song-progress-bar">
                            <div
                                className="selected-region"
                                style={{
                                    left: regionRef.current
                                        ? `${(regionRef.current.start / songDuration) * 100}%`
                                        : "0%",
                                    width: regionRef.current
                                        ? `${((regionRef.current.end - regionRef.current.start) / songDuration) * 100}%`
                                        : "50%",
                                }}
                            ></div>
                            <div
                                className="playback-indicator"
                                style={{
                                    left: regionRef.current
                                        ? `${(Math.max(currentTime, regionRef.current.start) / songDuration) * 100}%`
                                        : "0%",
                                }}
                            ></div>
                        </div>
                        <div className="time-labels">
                            <span className="start-time">0.0</span>
                            <span className="end-time">{songDuration.toFixed(1)}</span>
                        </div>
                    </div>

                    <div className="song-bar-controls mt-3">
                        <button className="cancel-audio-button" onClick={handleCancel}>Cancel</button>
                        <button className="play-pause-button" onClick={playSelectedRegion}>
                            {isPlaying ? <IoPauseCircleOutline className="fs-1" /> : <IoPlayCircleOutline className="fs-1" />}
                        </button>
                        <button className="use-audio-button" onClick={handleUseAudio}>Use Audio</button>
                    </div>
                </div>

            )}
        </div>
    );
};

export default StorySong;
