import React, { useState } from "react";
import quest from "../assets/compressed/quest.webp";
import quest3 from "../assets/compressed/quest3.webp";
import quest2 from "../assets/compressed/quest2.webp";
import quest1 from "../assets/compressed/quest1.webp";
import avatar3 from "../assets/compressed/avatar3.jpg";
import avatar5 from "../assets/compressed/avatar5.jpg";
import avatar7 from "../assets/compressed/avatar7.jpg";
import FlickStarLogo from "../assets/compressed/logo1.png";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { PiShareFat } from "react-icons/pi";

const questData = [
    {
        image: quest2,
        avatar: avatar5,
        name: "John Doe",
        username: "@johndoe",
        title: "Visit My Coffee Cafe",
        status: "OnFlick",
        description: "Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us! Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us!",
        amount: 100,
    },
    {
        image: quest3,
        avatar: FlickStarLogo,
        name: "FlickStar",
        username: "Sponsored",
        title: "Visit My Coffee Cafe",
        status: "GoFlick",
        description: "Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us! Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us!",
        amount: 100,
    },
    {
        image: quest1,
        avatar: avatar7,
        name: "John Doe",
        username: "@johndoe",
        title: "Visit My Coffee Cafe",
        status: "GoFlick",
        description: "Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us! Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us!",
        amount: 100,
    },
    {
        image: quest,
        avatar: avatar3,
        name: "John Doe",
        username: "@johndoe",
        title: "Visit My Coffee Cafe",
        status: "GoFlick",
        description: "Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us! Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us!",
        amount: 100,
    },
];

const QuestCard = ({ data }) => {
    const [isLiked, setIsLiked] = useState(false);

    const toggleLike = () => {
        setIsLiked(!isLiked);
    };
    return (
        <div className="search-main-quest-card mb-2">
            <div className="search-main-quest-image-wrapper">
                <img src={data.image} alt="Quest" className="search-main-quest-image" />

                <div className="search-main-quest-overlay-top-left">
                    <img src={data.avatar} alt="Avatar" className="search-main-quest-avatar" />
                    <div className="search-main-quest-user-info">
                        <span className="search-main-quest-user-name">{data.name}</span>
                        <span className="search-main-quest-user-username">{data.username}</span>
                    </div>
                </div>

                <div className="search-main-quest-overlay-top-right">
                    <div className="quest-icon-circle"><PiShareFat className="fs-4 quest-icon" /></div>
                    <div className="quest-icon-circle" onClick={toggleLike}>
                        {isLiked ? (
                            <GoHeartFill className="fs-4 quest-icon liked" />
                        ) : (
                            <GoHeart className="fs-4 quest-icon" />
                        )}
                    </div>
                </div>
            </div>

            <div className="search-main-quest-title-wrapper">
                <h5 className="search-main-quest-title mb-0">{data.title}</h5>
                <span className="search-main-quest-go-flick">{data.status}</span>
            </div>

            <div className="search-main-quest-overlay-bottom">
                <p className="search-main-quest-description mb-0">{data.description}</p>
                <span className="search-main-quest-amount">${data.amount}</span>
            </div>
        </div>
    );
};

const SearchQuest = () => {
    return (
        <div className="search-main-quest-container">
            {questData.map((data, index) => (
                <QuestCard key={index} data={data} />
            ))}
        </div>
    );
};

export default SearchQuest;
