import React, { useState } from "react";

const deactivateReasons = [
    "Time Consuming.",
    "Privacy issues.",
    "Break needed.",
    "Too many ads.",
    "Content removal.",
    "Security risk.",
    "Duplicate account.",
    "Data worries.",
    "Following issues."
];

const deleteReasons = [
    "Created a second account.",
    "Privacy issues.",
    "Trouble getting started.",
    "Too many ads.",
    "Content removal.",
    "I don't like the app experience.",
    "Too many notifications.",
    "Content isn't relevant.",
    "Other."
];

const ManageAccount = () => {
    const [activeFlow, setActiveFlow] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedReason, setSelectedReason] = useState("");
    const [password, setPassword] = useState("");
    const isDeactivate = activeFlow === "deactivate";
    const isDelete = activeFlow === "delete";
    const getHeading = () => {
        if (isDeactivate) return "Deactivate my Account";
        if (isDelete) return "Delete my Account";
        return "";
    };

    const getDescription = () => {
        if (isDeactivate) {
            return "Deactivating your account will temporarily disable your profile, making it invisible to others. Your posts, comments, and interactions will be hidden until you reactivate your account by logging back in. However, some data may still be visible to others, such as messages you’ve sent. If you wish to permanently delete your account, you may need to follow a separate process. You can reactivate your account anytime by simply logging in. If you’re sure you want to proceed, tap “Deactivate” below.";
        }
        if (isDelete) {
            return "Deleting your account is a permanent action that will remove your profile, posts, comments, and all associated data from our platform. Once deleted, your account cannot be recovered, and any messages you’ve sent may still be visible to others. If you’re unsure, you may consider deactivating your account instead. Before proceeding, make sure to back up any important data. If you’re ready to delete your account permanently, tap “Delete” below.";
        }
        return "";
    };

    const reasons = isDeactivate ? deactivateReasons : isDelete ? deleteReasons : [];
    const handleActionClick = (type) => {
        setActiveFlow(type);
        setSelectedReason("");
        setPassword("");
    };

    return (
        <div className="manage-account-setting-wrapper">
            {!activeFlow ? (
                <>
                    <div className="manage-account-setting-section">
                        <p
                            className="manage-account-setting-title mb-0 manage-account-setting-clickable"
                            onClick={() => handleActionClick("deactivate")}
                        >
                            Deactivate my Account
                        </p>
                    </div>
                    <hr className="manage-account-setting-divider" />
                    <div className="manage-account-setting-section">
                        <p
                            className="manage-account-setting-delete"
                            onClick={() => handleActionClick("delete")}
                        >
                            Delete my Account
                        </p>
                    </div>
                </>
            ) : (
                <div className="manage-account-setting-wrapper">
                    <h3 className="manage-account-setting-heading">{getHeading()}</h3>
                    <p className="manage-account-setting-description">{getDescription()}</p>
                    <div className="manage-account-setting-form-wrapper">
                        <div className="manage-account-setting-form">
                            <label className="setting-personal-detail-label">Reason</label>
                            <input
                                type="text"
                                readOnly
                                placeholder="Select the reason"
                                value={selectedReason}
                                onClick={() => setShowOverlay(true)}
                                className="setting-personal-detail-input mb-4"
                            />
                            {selectedReason && (
                                <>
                                    <label className="setting-personal-detail-label">Enter your password</label>
                                    <input
                                        type="password"
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="setting-personal-detail-input mb-4"
                                    />
                                </>
                            )}
                            <button className="manage-account-setting-button">
                                {isDeactivate && "Deactivate My Account"}
                                {isDelete && "Delete My Account"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showOverlay && (
                <div className="manage-account-setting-overlay">
                    <div className="manage-account-setting-overlay-box">
                        <div className="manage-account-setting-overlay-header">
                            <span>{getHeading()}</span>
                            <button onClick={() => setShowOverlay(false)}>✕</button>
                        </div>
                        <div className="manage-account-setting-overlay-options">
                            {reasons.map((reason, idx) => (
                                <label key={idx} className="manage-account-setting-radio-label">
                                    <span>{reason}</span>
                                    <input
                                        type="radio"
                                        name="reason"
                                        value={reason}
                                        onChange={() => {
                                            setSelectedReason(reason);
                                            setShowOverlay(false);
                                        }}
                                        checked={selectedReason === reason}
                                    />
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageAccount;
