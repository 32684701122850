import React, { memo } from "react";
import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import avatar3 from "../assets/compressed/avatar3.jpg";
import avatar4 from "../assets/compressed/avatar4.jpg";
import avatar5 from "../assets/compressed/avatar5.jpg";
import avatar6 from "../assets/compressed/avatar6.jpg";
import avatar7 from "../assets/compressed/avatar7.jpg";
import avatar8 from "../assets/compressed/avatar8.jpg";
import post1 from "../assets/compressed/post1.jpg";
import post2 from "../assets/compressed/post2.jpg";
import post3 from "../assets/compressed/post3.jpg";
import post4 from "../assets/compressed/post4.jpg";
import post5 from "../assets/compressed/post5.jpg";

// Fixed story for "Your Story"
export const yourStory = {
    id: 1,
    name: "Your Story",
    img: avatar8,
    images: post2,
    song: "coldplay",
    username: "username",
    views: 13,
    viewers: [
        { avatar: avatar1, username: "Issac Newton", icon: "heart" },
        { avatar: avatar2, username: "Jessica", icon: "heart" },
        { avatar: avatar3, username: "Colt Steel", icon: "love" },
        { avatar: avatar4, username: "John Doe", icon: "fire" },
        { avatar: avatar5, username: "Christina", icon: "love" },
        { avatar: avatar6, username: "Nathan", icon: "none" },
        { avatar: avatar7, username: "Username", icon: "none" },
        { avatar: avatar8, username: "Abel Doe", icon: "none" },
    ]
};

const friendStories = [
    { id: 2, name: "Jessica", img: avatar1, images: post1, song: "coldplay", username: "username" },
    { id: 3, name: "Saylor", img: avatar2, images: post2, song: "coldplay", username: "username" },
    { id: 4, name: "Johndoe", img: avatar3, images: post3, song: "coldplay", username: "username" },
    { id: 5, name: "Martina", img: avatar4, images: post4, song: "coldplay", username: "username" },
    { id: 6, name: "Obama", img: avatar5, images: post5, song: "coldplay", username: "username" },
    { id: 7, name: "Nathan", img: avatar6, images: post1, song: "coldplay", username: "username" },
    { id: 8, name: "Taylor", img: avatar7, images: post2, song: "coldplay", username: "username" },
    { id: 9, name: "John", img: avatar8, images: post3, song: "coldplay", username: "username" },
    { id: 10, name: "Christina", img: avatar1, images: post4, song: "coldplay", username: "username" },
    { id: 11, name: "Arleen", img: avatar2, images: post5, song: "coldplay", username: "username" },
    { id: 12, name: "Robert", img: avatar3, images: post1, song: "coldplay", username: "username" },
];

export const stories = [yourStory, ...friendStories];

// Memoized StoryCard component
const StoryCard = memo(({ story, isYourStory }) => (
    <div className={`story-card text-center ${isYourStory ? "fixed-story" : ""}`}>
        <div className={`story-ring ${isYourStory ? "your-story-ring" : ""}`}>
            <img
                src={story.img}
                alt={`Story of ${story.name}`}
                className="story-image"
                loading="lazy"
                title={isYourStory ? "Add to Your Story" : `View ${story.name}'s story`}
            />
            {isYourStory && (
                <div className="add-story-icon">
                    <span className="plus-icon">+</span>
                </div>
            )}
        </div>

        <p className="story-name">{story.name}</p>
    </div>
));

export default StoryCard;
