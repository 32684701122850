import React, { useState, useRef } from "react";
import { FaFilter } from "react-icons/fa";
import { MdOutlineSort } from "react-icons/md";
import { Link } from "react-router-dom";
import FilterDropdown from "./FilterDropdown";
import SortDropdown from "./SortDropdown";
import useOutsideClick from "../hooks/useOutsideClick";

const QuestActions = ({ searchTerm, handleSearch, applyFilters, applySorting }) => {
    const [showFilter, setShowFilter] = useState(false);
    const [showSort, setShowSort] = useState(false);
    const filterRef = useRef(null);
    const sortRef = useRef(null);

    useOutsideClick(filterRef, () => setShowFilter(false), showFilter);
    useOutsideClick(sortRef, () => setShowSort(false), showSort);

    return (
        <div className="quest-actions d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex">
                <button className="quest-action-btn" onClick={() => setShowFilter((prev) => !prev)}>
                    <FaFilter className="me-2" /> Filter
                </button>
                {showFilter && (
                    <div ref={filterRef} className="filter-dropdown-container">
                        <FilterDropdown onApply={applyFilters} onClose={() => setShowFilter(false)} />
                    </div>
                )}
                <button className="quest-action-btn" onClick={() => setShowSort((prev) => !prev)}>
                    <MdOutlineSort className="me-2 fs-4" /> Sort
                </button>
                {showSort && (
                    <div ref={sortRef} className="sort-dropdown-container">
                        <SortDropdown onApply={applySorting} onClose={() => setShowSort(false)} />
                    </div>
                )}
                <input
                    type="text"
                    className="quest-search-input"
                    placeholder="Search quests..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>
            <Link to="/create-quest" className="text-decoration-none">
                <button className="quest-create-btn">Create a Quest</button>
            </Link>
        </div>
    );
};

export default QuestActions;
