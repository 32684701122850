import React, { useState } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import FriendSuggestions from "../components/FriendSuggestions";
import avatar1 from "../assets/compressed/avatar1.jpg";
import Flicks from "../components/Flicks";
import ProfileQuest from "../components/ProfileQuest";
import Tagged from "../components/Tagged";
import FollowersModal, { followersData } from "../components/FollowersModal";
import FollowingModal, { followingData } from "../components/FollowingModal";
import ShareProfileModal from "../components/ShareProfileModal";
import "../styles/Global.css";
import "../styles/Profile.css";

const Profile = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const tabIcons = [
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_86_12122)">
                <path d="M9.54688 2.72656L6.81641 0H4.54297L7.26953 2.72656H9.54688ZM15.4531 2.72656L12.7266 0H10.4531L13.1797 2.72656H15.4531ZM16.3633 0L19.0898 2.72656H20V0H16.3633ZM16.3633 6.36328H12.7266L15.4531 3.63672H13.1797L10.4531 6.36328H6.81641L9.54297 3.63672H7.26953L4.54297 6.36328H0.910156L3.63672 3.63672H0V18.1836C0 19.1836 0.816406 20 1.81641 20H18.1797C19.1836 20 19.9961 19.1836 19.9961 18.1836V3.63672H19.0859L16.3633 6.36328ZM0.910156 0H0V2.72656H3.63672L0.910156 0Z" stroke="currentColor" />
                <path d="M7.69141 12.4993V11.1335C7.69141 9.38071 8.75902 8.66365 10.0617 9.54006L11.0804 10.223L12.099 10.9059C13.4017 11.7823 13.4017 13.2164 12.099 14.0928L11.0804 14.7757L10.0617 15.4586C8.75902 16.335 7.69141 15.618 7.69141 13.8652V12.4993Z" stroke="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_86_12122">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none">
            <path d="M0.913086 23.073V2.00006L10.3359 7.42288" stroke="currentColor" strokeWidth="1.3" />
            <path d="M0.913086 6.4715L10.2398 11.8467" stroke="currentColor" strokeWidth="1.3" />
            <path d="M4.90576 20.933V13.2269L8.75183 15.3675V18.6973" stroke="currentColor" strokeWidth="1.3" />
            <path d="M12.3569 8.19335L18.6981 11.8473L12.3569 15.5013L12.3569 8.19335Z" stroke="currentColor" strokeWidth="1.3" />
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.1696 18.9486C8.49256 18.9486 5.51172 15.9678 5.51172 12.2907C5.51172 10.3189 6.36892 8.54723 7.73102 7.32812C8.90892 6.27387 10.4644 5.63281 12.1696 5.63281C15.8467 5.63281 18.8275 8.61365 18.8275 12.2907C18.8275 13.0913 18.6861 13.859 18.4271 14.5699C18.3338 14.8263 18.17 15.0494 17.9695 15.2343L17.8802 15.3167C17.264 15.8852 16.3389 15.958 15.6415 15.4929C15.1363 15.1562 14.8328 14.5891 14.8328 13.9819V12.2907M14.8328 12.2907C14.8328 13.7615 13.6405 14.9539 12.1696 14.9539C10.6988 14.9539 9.50646 13.7615 9.50646 12.2907C9.50646 10.8199 10.6988 9.62755 12.1696 9.62755C13.6405 9.62755 14.8328 10.8199 14.8328 12.2907Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
            <path d="M23 12C23 17.1854 23 19.7782 21.389 21.389C19.7782 23 17.1854 23 12 23C6.81455 23 4.22182 23 2.61092 21.389C1 19.7782 1 17.1854 1 12C1 6.81455 1 4.22182 2.61092 2.61092C4.22182 1 6.81455 1 12 1C17.1854 1 19.7782 1 21.389 2.61092C22.4602 3.68203 22.8192 5.18721 22.9394 7.6" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
        </svg>
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isFollowingModalOpen, setIsFollowingModalOpen] = useState(false);

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);


    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>

                <main className="col-12 col-md-10 col-lg-7 profile-main-content">
                    {/* Profile Header */}
                    <div className="profile-header d-flex align-items-center">
                        <img src={avatar1} alt="User Avatar" className="profile-avatar" />
                        <div className="profile-info ms-4 w-100">
                            {/* First Row: Name and Actions */}
                            <div className="d-flex align-items-center mb-4">
                                <h2 className="profile-username mb-0">Johnsmith_</h2>
                                <div className="profile-actions d-flex">
                                    <button className="btn profile-button">Edit Profile</button>
                                    <button className="btn profile-button" onClick={() => setIsShareModalOpen(true)}>Share Profile</button>
                                    {/* <button className="btn profile-button px-2 py-1"><HiOutlineUserPlus className="fs-5" /></button> */}
                                </div>
                            </div>

                            {/* Second Row: Stats */}
                            <div className="profile-stats d-flex mb-4">
                                <span><strong>120</strong> Posts</span>
                                <span onClick={() => setIsModalOpen(true)}>
                                    <strong>10K</strong> Followers
                                </span>
                                <span onClick={() => setIsFollowingModalOpen(true)}>
                                    <strong>500</strong> Following
                                </span>
                            </div>

                            {/* Third Row: Username, Category, Bio, and Profile Line */}
                            <div className="profile-details">
                                <p className="profile-handle mb-0">John Smith</p>
                                <p className="profile-category mb-0">Product/Service</p>
                                <p className="profile-bio mb-0">Your favourite fun clips 📲 In your language 🌍</p>
                                <p className="profile-link mb-0">upvox.net</p>
                            </div>
                        </div>
                        <div className="profile-actions-mobile">
                            <button className="btn profile-button">Edit Profile</button>
                            <button className="btn profile-button" onClick={() => setIsShareModalOpen(true)}>Share Profile</button>
                        </div>
                    </div>

                    <FollowersModal
                        followers={followersData}
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                    />

                    <FollowingModal
                        following={followingData}
                        isOpen={isFollowingModalOpen}
                        onClose={() => setIsFollowingModalOpen(false)}
                    />

                    <ShareProfileModal
                        isOpen={isShareModalOpen}
                        onClose={() => setIsShareModalOpen(false)} />

                    {/* Tab Navigation */}
                    <div className="profile-bottom-section">
                        <div className="profile-tabs d-flex">
                            {['Flick', 'Quest', 'Tagged'].map((tab, index) => (
                                <div key={index} className={`profile-tab ${activeIndex === index ? 'active' : ''}`} onClick={() => setActiveIndex(index)}>
                                    {tabIcons[index]} {tab}
                                </div>
                            ))}
                        </div>

                        {/* Tab Content */}
                        <div className="profile-tab-content">
                            {activeIndex === 0 && <Flicks />}
                            {activeIndex === 1 && <ProfileQuest />}
                            {activeIndex === 2 && <Tagged />}
                        </div>
                    </div>
                </main>

                <aside className="col-3 d-none d-lg-flex flex-column py-3 home-right-sidebar">
                    <div className="home-user-details mb-1 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <img src={avatar1} alt="User Avatar" className="user-avatar" />
                            <div className="ms-3">
                                <p className="username mb-0">John Smith</p>
                                <p className="person-name mb-0">@johnsmith2045 </p>
                            </div>
                        </div>

                        <div className="user-icons">
                            <svg
                                className="mx-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="29"
                                viewBox="0 0 31 29"
                                fill="none"
                            >
                                <path
                                    d="M27.5556 29H3.44444C1.55 29 0 27.55 0 25.7778V3.22222C0 1.45 1.55 0 3.44444 0H27.5556C29.45 0 31 1.45 31 3.22222V25.7778C31 27.55 29.45 29 27.5556 29Z"
                                    fill="#007AFF"
                                />
                                <path
                                    d="M21.3557 18.5012C21.3557 22.605 17.3104 23.1179 16.5652 23.1179V25.375H14.649V23.1179C14.0102 23.0153 9.53906 22.605 9.53906 17.6804H13.0521C13.0521 18.1934 13.0521 20.5531 15.7135 20.5531C17.9491 20.5531 17.9491 18.809 17.9491 18.6038C17.9491 14.9104 10.2843 16.4493 10.2843 10.704C10.2843 7.2158 13.5844 6.08726 15.0748 6.08726V3.625H16.991V6.08726C18.162 6.18986 21.4621 7.11321 21.4621 11.4222H17.9491C17.9491 10.4988 17.7362 8.65212 15.7135 8.65212C13.6909 8.65212 13.6908 10.3962 13.6908 10.704C13.5844 14.0896 21.3557 12.6533 21.3557 18.5012Z"
                                    fill="white"
                                />
                            </svg>
                            <i className="bi bi-gear"></i>
                        </div>
                    </div>
                    <hr />
                    <FriendSuggestions />
                </aside>
            </div >
            <MobileNav />
        </div >
    );
};

export default Profile;
