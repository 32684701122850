import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";

const LocationSelector = ({ selectedLocations, onSelect }) => {
    const countries = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Argentina", "Armenia", "Australia", "Austria",
        "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin",
        "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Bulgaria", "Burkina Faso",
        "Burundi", "Cambodia", "Cameroon", "Canada", "Chile", "China", "Colombia", "Costa Rica", "Croatia",
        "Cuba", "Cyprus", "Czech Republic", "Denmark", "Ecuador", "Egypt", "El Salvador", "Estonia",
        "Ethiopia", "Fiji", "Finland", "France", "Georgia", "Germany", "Ghana", "Greece", "Guatemala",
        "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel",
        "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Lebanon", "Malaysia",
        "Maldives", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Nepal",
        "Netherlands", "New Zealand", "Nigeria", "North Macedonia", "Norway", "Pakistan", "Palestine",
        "Panama", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Saudi Arabia",
        "Serbia", "Singapore", "Slovakia", "Slovenia", "South Africa", "South Korea", "Spain",
        "Sri Lanka", "Sweden", "Switzerland", "Thailand", "Turkey", "UAE", "UK", "USA", "Ukraine",
        "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
    ];

    const [showDropdown, setShowDropdown] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const filteredCountries = countries.filter((country) =>
        country.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const toggleDropdown = () => setShowDropdown(!showDropdown);
    const handleSelect = (country) => {
        if (selectedLocations.includes(country)) {
            onSelect(selectedLocations.filter((loc) => loc !== country));
        } else {
            onSelect([...selectedLocations, country]);
        }
    };

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (value.trim().length > 0) {
            setShowDropdown(true);
        }
    };

    return (
        <div className="location-container">
            <div className="location-header" onClick={toggleDropdown}>
                <h5 className="filter-heading mb-0">Location</h5>
                <span className="arrow"><IoIosArrowForward /></span>
            </div>

            <input
                type="text"
                placeholder="Search Location"
                className="location-search"
                value={searchTerm}
                onChange={handleSearch}
            />

            {showDropdown && (
                <div className="location-dropdown-right">
                    {filteredCountries.length > 0 ? (
                        filteredCountries.map((country) => (
                            <button
                                key={country}
                                className={`location-button ${selectedLocations.includes(country) ? "selected" : ""}`}
                                onClick={() => handleSelect(country)}
                            >
                                {country}
                            </button>
                        ))
                    ) : (
                        <p className="no-results">No results found</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default LocationSelector;
