import React, { useState } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import MainQuest from "../components/MainQuest";
import "../styles/Quest.css";
import "../styles/Global.css";
import SponsoredQuest from "../components/SponsoredQuest";
import MyQuest from "../components/MyQuest";
import { useLocation } from "react-router-dom";

const Quest = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.state?.activeTab || "All Quest");

    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>

                <main className="col-12 col-md-10 col-lg-10 quest-main-content">
                    <div className="quest-container">
                        <div className="quest-tab-bar d-flex">
                            {["All Quest", "Sponsored", "Profile"].map((tab) => (
                                <button
                                    key={tab}
                                    className={`quest-tab-button ${activeTab === tab ? "active" : ""}`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>

                        <div className="quest-content-container w-100 h-100 p-3">
                            {activeTab === "All Quest" && <div><MainQuest /></div>}
                            {activeTab === "Sponsored" && <div><SponsoredQuest /></div>}
                            {activeTab === "Profile" && <div><MyQuest /></div>}
                        </div>
                    </div>
                </main>
            </div>
            <MobileNav />
        </div>
    );
};

export default Quest;






