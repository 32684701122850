import React, { useState } from "react";
import LocationSelector from "./LocationSelector";

const FilterDropdown = ({ onApply, onClose }) => {
    const [minAmount, setMinAmount] = useState("");
    const [maxAmount, setMaxAmount] = useState("");
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [selectedType, setSelectedType] = useState("All");

    const applyFilters = () => {
        onApply({
            minAmount: minAmount ? Number(minAmount) : "",
            maxAmount: maxAmount ? Number(maxAmount) : "",
            selectedLocations,
            selectedType,
        });
        onClose();
    };

    return (
        <div className="filter-dropdown">
            <div className="filter-section">
                <h5 className="filter-heading">Amount</h5>
                <div className="amount-fields">
                    <input
                        type="number"
                        placeholder="Min"
                        value={minAmount}
                        onChange={(e) => setMinAmount(e.target.value)}
                        className="styled-input"
                    />
                    <input
                        type="number"
                        placeholder="Max"
                        value={maxAmount}
                        onChange={(e) => setMaxAmount(e.target.value)}
                        className="styled-input"
                    />
                </div>
            </div>

            <LocationSelector selectedLocations={selectedLocations} onSelect={setSelectedLocations} />

            <div className="filter-section">
                <h5 className="filter-heading">Type</h5>
                {["All", "GoFlick", "OnFlick"].map((type) => (
                    <label key={type} className="filter-type-option">
                        <input
                            type="radio"
                            value={type}
                            checked={selectedType === type}
                            onChange={() => setSelectedType(type)}
                        />
                        {type}
                    </label>
                ))}
            </div>

            <div className="filter-buttons">
                <button onClick={onClose}>Cancel</button>
                <button onClick={applyFilters}>Apply</button>
            </div>
        </div>
    );
};

export default FilterDropdown;
