import React, { useState } from "react";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { PiShareFat } from "react-icons/pi";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import noimage from "../assets/compressed/no-image.webp";

const getStatusClass = (status) => {
    switch (status?.toLowerCase()) {
        case "in review": return "in-review";
        case "opened": return "opened";
        case "closed": return "closed";
        case "pending": return "pending";
        case "approved": return "approved";
        case "rejected": return "rejected";
        default: return "";
    }
};

const QuestCard = ({ data, activeTab, subTab }) => {
    const navigate = useNavigate();
    const handleApplicantsClick = () => {
        navigate(`/quests/${data.id}/applicants`);
    };

    const [isLiked, setIsLiked] = useState(data.questType === "favorite");
    const toggleLike = () => setIsLiked(!isLiked);

    const isCreated = data.questType === "created";
    const isApplied = data.questType === "applied";
    const isFavorite = data.questType === "favorite";

    return (
        <div className="quest-card mb-2">
            <div className="quest-image-wrapper">
                <img src={data.images?.[0] || noimage} alt="Quest" className="quest-image" />
                {!isCreated && (
                    <div className="quest-overlay-top-left">
                        <img src={data.avatar} alt="Avatar" className="quest-avatar" />
                        <div className="quest-user-info">
                            <span className="quest-user-name">{data.name}</span>
                            <span className="quest-user-username">{data.username}</span>
                        </div>
                    </div>
                )}
                <div className="quest-overlay-top-right">
                    {isCreated ? (
                        <button className={`quest-status ${getStatusClass(data.questStatus)}`}>{data.questStatus}</button>
                    ) : isApplied ? (
                        <button className={`quest-status ${getStatusClass(data.appliedStatus)}`}>{data.appliedStatus}</button>
                    ) : !isFavorite && (
                        <>
                            <div className="quest-icon-circle"><PiShareFat className="fs-4 quest-icon" /></div>
                            <div className="quest-icon-circle" onClick={toggleLike}>
                                {isLiked ? (
                                    <GoHeartFill className="fs-4 quest-icon liked" />
                                ) : (
                                    <GoHeart className="fs-4 quest-icon" />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="quest-title-wrapper">
                <h5 className="quest-title mb-0">{data.title}</h5>
                {isCreated && (
                    <span className="quest-applicants" onClick={handleApplicantsClick}>{data.applicantCount}/{data.totalApplicants}<IoIosArrowForward className="fs-5" /></span>
                )}
            </div>

            {data.id && (
                <Link to={`/quests/${data.id}`} state={{ activeTab, subTab }} className="quest-card-link">
                    <div className="quest-overlay-bottom">
                        <div className="quest-overlay-row">
                            <p className="quest-description mb-0">{data.description}</p>
                            <span className="quest-amount">${data.amount}</span>
                        </div>
                        <div className="quest-overlay-row">
                            <button className={`quest-go-flick ${data.status === "GoFlick" ? "goflick" : "onflick"}`}>{data.status}</button>
                            <span className="quest-date">{data.date}</span>
                        </div>
                    </div>
                </Link>
            )}
        </div>
    );
};

export default QuestCard;
