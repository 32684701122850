import React, { useRef, useEffect, useState } from "react";
import ColorPicker from "./ColorPicker";
import { PiPenBold, PiHighlighterBold, PiPaintBrushBold } from "react-icons/pi";
import { TbEraser } from "react-icons/tb";
import { AiOutlineClear } from "react-icons/ai";

const DrawingCanvas = ({ imageSrc, videoSrc, onSaveDrawing, setUndoRedoFunctions, bgColor }) => {
    const canvasRef = useRef(null);
    const ctxRef = useRef(null);
    const [drawing, setDrawing] = useState(false);
    const [brushType, setBrushType] = useState("pencil");
    const [color, setColor] = useState("#ffffff");
    const [size, setSize] = useState(5);
    const [setCanvasSize] = useState({ width: 400, height: 500 });
    const [history, setHistory] = useState([]);
    const [redoStack, setRedoStack] = useState([]);

    useEffect(() => {
        setUndoRedoFunctions({
            undo,
            redo,
            canUndo: history.length > 1,
            canRedo: redoStack.length > 0
        });
    }, [history, redoStack]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctxRef.current = ctx;
        const parentContainer = canvas.parentElement.getBoundingClientRect();
        setCanvasSize({ width: parentContainer.width, height: parentContainer.height });
        canvas.width = parentContainer.width;
        canvas.height = parentContainer.height;
        ctx.fillStyle = bgColor || "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        let video = null;
        if (videoSrc) {
            video = document.createElement("video");
            video.src = videoSrc;
            video.loop = true;
            video.muted = true;
            video.autoplay = true;
            video.crossOrigin = "anonymous";
            video.play();
            const drawVideoFrame = () => {
                if (!canvasRef.current || !ctxRef.current || video.paused || video.ended) return;

                const ctx = ctxRef.current;
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

                if (video.readyState >= 2) {
                    ctx.drawImage(video, 0, 0, canvasRef.current.width, canvasRef.current.height);
                }

                requestAnimationFrame(drawVideoFrame);
            };

            video.addEventListener("loadeddata", () => {
                drawVideoFrame();
            });
        } else if (imageSrc) {
            const img = new Image();
            img.src = imageSrc;
            img.onload = () => {
                const container = canvas.parentElement.getBoundingClientRect();
                const aspectRatio = img.width / img.height;
                let newWidth = container.width;
                let newHeight = container.width / aspectRatio;
                if (newHeight > container.height) {
                    newHeight = container.height;
                    newWidth = container.height * aspectRatio;
                }
                setCanvasSize({ width: container.width, height: container.height });
                canvas.width = container.width;
                canvas.height = container.height;
                ctx.fillStyle = bgColor || "#fff";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                const xOffset = (canvas.width - newWidth) / 2;
                const yOffset = (canvas.height - newHeight) / 2;
                ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
            };
        }
        const start = (e) => startDrawing(e);
        const move = (e) => draw(e);
        const stop = (e) => stopDrawing(e);
        canvas.addEventListener("mousedown", start);
        canvas.addEventListener("mousemove", move);
        canvas.addEventListener("mouseup", stop);
        canvas.addEventListener("mouseleave", stop);
        canvas.addEventListener("touchstart", start, { passive: false });
        canvas.addEventListener("touchmove", move, { passive: false });
        canvas.addEventListener("touchend", stop, { passive: false });

        return () => {
            canvas.removeEventListener("mousedown", start);
            canvas.removeEventListener("mousemove", move);
            canvas.removeEventListener("mouseup", stop);
            canvas.removeEventListener("mouseleave", stop);
            canvas.removeEventListener("touchstart", start);
            canvas.removeEventListener("touchmove", move);
            canvas.removeEventListener("touchend", stop);

            if (video) {
                video.pause();
                video.src = "";
            }
        };

    }, [imageSrc, videoSrc, bgColor]);

    useEffect(() => {
        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, []);

    const startDrawing = (e) => {
        document.body.classList.add("no-scroll");
        saveCanvasState();
        setRedoStack([]);
        setDrawing(true);
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        let clientX, clientY;
        if (e.touches) {
            clientX = e.touches[0].clientX;
            clientY = e.touches[0].clientY;
        } else {
            clientX = e.clientX;
            clientY = e.clientY;
        }
        const offsetX = (clientX - rect.left) * scaleX;
        const offsetY = (clientY - rect.top) * scaleY;
        ctxRef.current.globalCompositeOperation = "source-over";
        ctxRef.current.shadowBlur = 0;
        ctxRef.current.shadowColor = color;
        ctxRef.current.strokeStyle = color;
        ctxRef.current.globalAlpha = 1;
        ctxRef.current.beginPath();
        ctxRef.current.moveTo(offsetX, offsetY);
    };

    const draw = (e) => {
        if (!drawing) return;
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;
        let clientX, clientY;
        if (e.touches) {
            clientX = e.touches[0].clientX;
            clientY = e.touches[0].clientY;
        } else {
            clientX = e.clientX;
            clientY = e.clientY;
        }
        const offsetX = (clientX - rect.left) * scaleX;
        const offsetY = (clientY - rect.top) * scaleY;
        ctxRef.current.lineTo(offsetX, offsetY);
        ctxRef.current.stroke();
        ctxRef.current.moveTo(offsetX, offsetY);
        if (brushType === "pencil") {
            ctxRef.current.globalCompositeOperation = "source-over";
            ctxRef.current.lineWidth = size;
            ctxRef.current.globalAlpha = 1;
            ctxRef.current.strokeStyle = color;
            ctxRef.current.shadowBlur = 0;
            ctxRef.current.lineCap = "round";
        } else if (brushType === "soft") {
            ctxRef.current.globalCompositeOperation = "source-over";
            ctxRef.current.lineWidth = size * 2;
            ctxRef.current.globalAlpha = 1;
            ctxRef.current.strokeStyle = color;
            ctxRef.current.shadowBlur = 5;
            ctxRef.current.shadowColor = color;
            ctxRef.current.lineCap = "round";
        } else if (brushType === "brush") {
            ctxRef.current.globalCompositeOperation = "source-over";
            ctxRef.current.strokeStyle = color;
            ctxRef.current.globalAlpha = 1;
            ctxRef.current.lineWidth = size * 2.5;
            ctxRef.current.shadowBlur = 0;
            ctxRef.current.lineCap = "round";
        } else if (brushType === "eraser") {
            ctxRef.current.globalCompositeOperation = "destination-out";
            ctxRef.current.lineWidth = size * 3;
            ctxRef.current.globalAlpha = 1;
            ctxRef.current.strokeStyle = "rgba(0,0,0,1)";
            ctxRef.current.lineCap = "round";
            ctxRef.current.shadowBlur = 0;
            ctxRef.current.shadowColor = "rgba(0,0,0,0)";
        }
    };

    const stopDrawing = () => {
        setDrawing(false);
        ctxRef.current.closePath();
        saveCanvasState();
        if (brushType === "eraser") {
            const canvas = canvasRef.current;
            const ctx = ctxRef.current;
            const img = new Image();
            img.src = imageSrc;
            img.onload = () => {
                const container = canvas.parentElement.getBoundingClientRect();
                const aspectRatio = img.width / img.height;
                let newWidth = container.width;
                let newHeight = container.width / aspectRatio;
                if (newHeight > container.height) {
                    newHeight = container.height;
                    newWidth = container.height * aspectRatio;
                }
                const xOffset = (canvas.width - newWidth) / 2;
                const yOffset = (canvas.height - newHeight) / 2;
                ctx.globalCompositeOperation = "destination-over";
                ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
                ctx.globalCompositeOperation = "source-over";
            };
        }
    };

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = ctxRef.current;
        ctx.fillStyle = bgColor || "#fff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
            const container = canvas.parentElement.getBoundingClientRect();
            const aspectRatio = img.width / img.height;
            let newWidth = container.width;
            let newHeight = container.width / aspectRatio;
            if (newHeight > container.height) {
                newHeight = container.height;
                newWidth = container.height * aspectRatio;
            }
            const xOffset = (canvas.width - newWidth) / 2;
            const yOffset = (canvas.height - newHeight) / 2;
            ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
        };
    };

    const saveDrawing = () => {
        const canvas = canvasRef.current;
        const newImage = canvas.toDataURL("image/png");
        onSaveDrawing(newImage);
    };

    const saveCanvasState = () => {
        const canvas = canvasRef.current;
        const data = canvas.toDataURL();
        setHistory((prev) => {
            if (prev.length === 0 || prev[prev.length - 1] !== data) {
                return [...prev, data];
            }
            return prev;
        });
        setRedoStack([]);
    };
    const undo = () => {
        if (history.length < 2) return;
        setRedoStack((prev) => [...prev, history[history.length - 1]]);
        const newHistory = history.slice(0, -1);
        setHistory(newHistory);
        const img = new Image();
        img.src = newHistory[newHistory.length - 1];
        img.onload = () => {
            ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            ctxRef.current.drawImage(img, 0, 0);
        };
    };
    const redo = () => {
        if (redoStack.length === 0) return;
        const nextState = redoStack[redoStack.length - 1];
        setRedoStack((prev) => prev.slice(0, -1));
        setHistory((prev) => [...prev, nextState]);
        const img = new Image();
        img.src = nextState;
        img.onload = () => {
            ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            ctxRef.current.drawImage(img, 0, 0);
        };
    };

    return (
        <div className="image-container">
            <div className="image-preview-container">
                <canvas
                    ref={canvasRef}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={stopDrawing}
                    onTouchStart={startDrawing}
                    onTouchMove={draw}
                    onTouchEnd={stopDrawing}
                    className="image-preview"
                />
            </div>
            <div className="column brush-types">
                <PiPenBold
                    className={brushType === "pencil" ? "active selected" : ""}
                    onClick={() => setBrushType("pencil")}
                />
                <PiHighlighterBold
                    className={brushType === "soft" ? "active selected" : ""}
                    onClick={() => setBrushType("soft")}
                />
                <PiPaintBrushBold
                    className={brushType === "brush" ? "active selected" : ""}
                    onClick={() => setBrushType("brush")}
                />
                <TbEraser
                    className={brushType === "eraser" ? "active selected" : ""}
                    onClick={() => setBrushType("eraser")}
                />
                <AiOutlineClear className={brushType === "clear" ? "active selected" : ""}
                    onClick={clearCanvas} />

                <button className="done-button btn btn-outline" onClick={saveDrawing}>Save</button>
            </div>
            <div className="size-slider">
                <div className="slider-track"></div>
                <div className="slider-bar"></div>
                <input
                    type="range"
                    min="1"
                    max="20"
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                    className="slider"
                />
            </div>
            <div className="column color-picker">
                <ColorPicker
                    mode="stroke"
                    bgColor={color}
                    handleBgColorChange={(newColor) => setColor(newColor.hex)}
                />
            </div>
        </div>
    );

};

export default DrawingCanvas;
