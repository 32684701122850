import React, { useState } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import UploadContainer from "../components/UploadContainer";
import StoryOptions from "../components/StoryOptions";
import "../styles/Create.css";
import "../styles/Global.css";
import FlickOptions from "../components/FlickOptions";

const Create = () => {
    const [activeTab, setActiveTab] = useState("Story");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [setNextTab] = useState(null);

    const handleUpload = (file) => {
        setUploadedFile(file);
    };

    const handleCancelStory = () => {
        setUploadedFile(null);
    };

    const handleTabChange = (tab) => {
        if (uploadedFile) {
            setNextTab(tab);
        } else {
            setActiveTab(tab);
        }
    };


    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                {!uploadedFile && (
                    <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                        <LeftSidebar />
                    </aside>
                )}

                <main className={`col-12 ${uploadedFile ? "full-width" : "col-md-10 col-lg-10"} create-main-content`}>
                    <div className="create-container">
                        {!uploadedFile && (

                            <div className="create-tab-bar d-flex">
                                {["Story", "Flicks", "Quest"].map((tab) => (
                                    <button
                                        key={tab}
                                        className={`create-tab-button ${activeTab === tab ? "active" : ""}`}
                                        onClick={() => handleTabChange(tab)}
                                    >
                                        {tab}
                                    </button>
                                ))}
                            </div>
                        )}

                        <div className="content-container w-100 h-100">
                            {!uploadedFile ? (
                                <UploadContainer onUpload={handleUpload} activeTab={activeTab} />
                            ) : activeTab === "Story" ? (
                                <StoryOptions file={uploadedFile} onCancel={handleCancelStory} />
                            ) : activeTab === "Flicks" ? (
                                <FlickOptions file={uploadedFile} onCancel={handleCancelStory} />
                            ) : null}
                        </div>
                    </div>
                </main>
            </div>
            <MobileNav />
        </div>
    );
};

export default Create;
