import React, { useState, useEffect } from "react";

const PhoneImages = ({ images }) => {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [images]);

    return (
        <div className="left-section">
            <div className="phone-images">
                <img
                    src={images[currentImage]}
                    alt={`Phone ${currentImage + 1}`}
                    className="phone w-100 h-100 transition"
                />
            </div>
        </div>
    );
};

export default PhoneImages;
