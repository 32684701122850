import React from "react";
import { RxCross2 } from "react-icons/rx";
import { CiSearch } from "react-icons/ci";

const RecentSearches = ({ searches, onClear }) => (
    <div className="search-overlay position-absolute">
        <div className="d-flex justify-content-between align-items-center mb-3 w-100 search-heading">
            <h6 className="m-0">Recent Searches</h6>
            <RxCross2 className="fs-5" onClick={onClear} />
        </div>
        <div className="d-flex flex-wrap gap-2">
            {searches.map((search, index) => (
                <span key={index} className="search-chip d-flex align-items-center">
                    <CiSearch className="fs-6 me-1" /> {search}
                </span>
            ))}
        </div>
    </div>
);

export default RecentSearches;
