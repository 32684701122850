import React, { useState, useRef, useEffect } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import FriendSuggestions from "../components/FriendSuggestions";
import RecentSearches from "../components/RecentSearches";
import Suggestions from "../components/Suggestions";
import Flicks from "../components/Flicks";
import SearchQuest from "../components/SearchQuest";
import Music from "../components/Music";
import People from "../components/People";
import Tags from "../components/Tags";
import avatar1 from "../assets/compressed/avatar1.jpg";
import "../styles/Global.css";
import "../styles/Search.css";


const Search = () => {
    const [searchActive, setSearchActive] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [recentSearches] = useState([
        "FlickStar", "Quest", "JavaScript", "Frontend Development",
        "Web Performance", "Albert Jones", "Lorem Ipsum", "Jessica",
        "John Smith", "Dil Tu Jaan Tu", "Movies", "#recentsearches",
        "event planning", "Social Media", "Best Quest",
    ]);
    const searchRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchActive(false);
                setShowSuggestions(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        setShowSuggestions(value.length > 0);
    };

    // Navigate between tabs
    const navigateToTab = (tabName) => {
        const tabIndex = ["flicks", "quest", "music", "people", "tags"].indexOf(tabName);
        if (tabIndex !== -1) {
            setActiveIndex(tabIndex);
            setSearchActive(false);
            setShowSuggestions(false);
        }
    };

    // Close suggestions manually
    const closeSuggestions = () => {
        setShowSuggestions(false);
        setSearchActive(false);
    };

    const tabNames = ["Flicks", "Quest", "Music", "People", "Tags"];

    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>

                <main className="col-12 col-md-10 col-lg-7 search-main-content">
                    {/* Search Input */}
                    <div className="search-page-bar d-flex align-items-center position-relative" ref={searchRef}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            value={searchQuery}
                            onFocus={() => setSearchActive(true)}
                            onChange={handleSearchChange}
                        />
                        <i className="bi bi-search position-absolute me-3"></i>

                        {/* Show recent searches if input is empty */}
                        {searchActive && !showSuggestions && (
                            <RecentSearches
                                searches={recentSearches}
                                onSearch={(query) => {
                                    setSearchQuery(query);
                                    setShowSuggestions(true);
                                }}
                                onClear={() => setSearchActive(false)}
                            />
                        )}

                        {/* Show search suggestions */}
                        {showSuggestions && (
                            <Suggestions onNavigate={navigateToTab} onClose={closeSuggestions} />
                        )}
                    </div>

                    <hr />

                    {/* Tabs */}
                    <div className="tabs-container">
                        <div className="tabs">
                            <div
                                className="tab-active-bg"
                                style={{
                                    width: `${100 / tabNames.length}%`,
                                    left: `${(activeIndex / tabNames.length) * 100}%`,
                                }}
                            ></div>

                            {tabNames.map((tab, index) => (
                                <button
                                    key={index}
                                    className={`tab-btn ${activeIndex === index ? "active" : ""}`}
                                    onClick={() => setActiveIndex(index)}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>

                        {/* Content Based on Active Tab */}
                        <div className="tab-content mt-2">
                            {activeIndex === 0 && <Flicks />}
                            {activeIndex === 1 && <SearchQuest />}
                            {activeIndex === 2 && <Music />}
                            {activeIndex === 3 && <People />}
                            {activeIndex === 4 && <Tags />}
                        </div>
                    </div>
                </main>

                {/* Right Sidebar */}
                <aside className="col-3 d-none d-lg-flex flex-column py-3 home-right-sidebar">
                    <div className="home-user-details mb-1 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <img src={avatar1} alt="User Avatar" className="user-avatar" />
                            <div className="ms-3">
                                <p className="username mb-0">John Smith</p>
                                <p className="person-name mb-0">@johnsmith2045</p>
                            </div>
                        </div>

                        <div className="user-icons">
                            <svg
                                className="mx-2"
                                xmlns="http://www.w3.org/2000/svg"
                                width="31"
                                height="29"
                                viewBox="0 0 31 29"
                                fill="none"
                            >
                                <path
                                    d="M27.5556 29H3.44444C1.55 29 0 27.55 0 25.7778V3.22222C0 1.45 1.55 0 3.44444 0H27.5556C29.45 0 31 1.45 31 3.22222V25.7778C31 27.55 29.45 29 27.5556 29Z"
                                    fill="#007AFF"
                                />
                                <path
                                    d="M21.3557 18.5012C21.3557 22.605 17.3104 23.1179 16.5652 23.1179V25.375H14.649V23.1179C14.0102 23.0153 9.53906 22.605 9.53906 17.6804H13.0521C13.0521 18.1934 13.0521 20.5531 15.7135 20.5531C17.9491 20.5531 17.9491 18.809 17.9491 18.6038C17.9491 14.9104 10.2843 16.4493 10.2843 10.704C10.2843 7.2158 13.5844 6.08726 15.0748 6.08726V3.625H16.991V6.08726C18.162 6.18986 21.4621 7.11321 21.4621 11.4222H17.9491C17.9491 10.4988 17.7362 8.65212 15.7135 8.65212C13.6909 8.65212 13.6908 10.3962 13.6908 10.704C13.5844 14.0896 21.3557 12.6533 21.3557 18.5012Z"
                                    fill="white"
                                />
                            </svg>
                            <i className="bi bi-gear"></i>
                        </div>
                    </div>
                    <hr />
                    <FriendSuggestions />
                </aside>
            </div>
            <MobileNav />
        </div>
    );
};

export default Search;
