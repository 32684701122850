import React, { useState, useCallback } from "react";
import { Grid } from "@giphy/react-components";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { debounce } from "lodash";

const gf = new GiphyFetch("rJ1a1WWCfO1h5RpRgMdKNMgHmrMVdwMX");

const StickerPicker = ({ onSelectSticker }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [error, setError] = useState(false);

    const debouncedSetSearchQuery = useCallback(
        debounce((query) => setSearchQuery(query), 500),
        []
    );

    const fetchStickers = (offset) => {
        return gf
            .search(searchQuery || "trending", {
                type: "stickers",
                offset,
                limit: 10,
            })
            .catch((err) => {
                console.error("Error fetching stickers:", err);
                setError(true);
            });
    };

    if (error) {
        return <div className="error-message">Error loading stickers. Try again?</div>;
    }

    return (
        <div className="sticker-picker" onClick={(event) => event.stopPropagation()}>
            <h3 className="sticker-heading mb-3 mt-2">Stickers</h3>
            <input
                type="text"
                placeholder="Search a Sticker here..."
                onChange={(e) => debouncedSetSearchQuery(e.target.value)}
                className="sticker-search mb-3"
            />
            <Grid
                key={searchQuery}
                fetchGifs={fetchStickers}
                width={300}
                columns={3}
                gutter={6}
                className="sticker"
                onGifClick={(gif, event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onSelectSticker(gif.images.original.url);
                }}
            />
        </div>
    );
};

export default StickerPicker;
