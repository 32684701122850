import React, { useState } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import "../styles/Global.css";
import "../styles/Setting.css";
import PersonalDetails from "../components/PersonalDetails";
import DeviceTab from "../components/DeviceTab";
import NotificationTab from "../components/NotificationTab";
import AppearanceSettings from "../components/AppearanceSettings";
import ManageAccount from "../components/ManageAccount";
import Support from "../components/Support";
import PasswordSecurity from "../components/PasswordSecurity";

const tabs = [
    "Personal Details",
    "Password & Security",
    "Manage account",
    "Notifications",
    "Device",
    "Appearance",
    "Support",
    "Privacy & policy",
    "Terms of Services"
];

const Setting = () => {
    const [activeTab, setActiveTab] = useState("Personal Details");
    const renderTabContent = () => {
        switch (activeTab) {
            case "Personal Details":
                return <PersonalDetails />;
            case "Device":
                return <DeviceTab />;
            case "Notifications":
                return <NotificationTab />;
            case "Appearance":
                return <AppearanceSettings />;
            case "Manage account":
                return <ManageAccount />;
            case "Support":
                return <Support />;
            case "Password & Security":
                return <PasswordSecurity />;
            default:
                return <div className="text-white p-4">Coming soon...</div>;
        }
    };

    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>
                <main className="col-12 col-md-10 col-lg-10 setting-main-content">
                    <div className="main-setting-container">
                        <div className="main-setting-header">
                            <h1>Settings</h1>
                        </div>

                        <div className="main-setting-tabs">
                            {tabs.map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => {
                                        if (tab === "Privacy & policy") {
                                            window.open("https://www.flickstar.net/privacy-policy", "_blank");
                                        } else if (tab === "Terms of Services") {
                                            window.open("https://www.flickstar.net/terms-and-service", "_blank");
                                        } else {
                                            setActiveTab(tab);
                                        }
                                    }}
                                    className={`main-setting-tab-button ${activeTab === tab ? "active" : ""}`}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>

                        <div className="main-setting-content">
                            {renderTabContent()}
                        </div>
                    </div>
                </main>
            </div>
            <MobileNav />
        </div>
    );
};

export default Setting;






