import React, { useContext } from 'react';
import ThemeContext from '../context/ThemeContext';
import lightPreview from '../assets/compressed/apperance1.webp';
import darkPreview from '../assets/compressed/apperance2.webp';
import systemPreview from '../assets/compressed/apperance3.webp';
import { FaCheck } from "react-icons/fa6";

const themes = [
    { key: 'light', label: 'Light', image: lightPreview },
    { key: 'dark', label: 'Dark', image: darkPreview },
    { key: 'system', label: 'System', image: systemPreview }
];

function AppearanceSettings() {
    const { selected, applyTheme } = useContext(ThemeContext);

    return (
        <div className="setting-appearance-wrapper">
            <h2 className="setting-appearance-title">Appearance</h2>
            <hr className="setting-appearance-divider" />
            <div className="setting-appearance-section">
                <p className="setting-appearance-subtitle mb-1">Theme</p>
                <p className="setting-appearance-description">Customize Your UI theme</p>
                <div className="setting-appearance-options">
                    {themes.map(({ key, label, image }) => (
                        <div
                            key={key}
                            className="setting-appearance-card"
                            onClick={() => applyTheme(key)}
                        >
                            <img
                                src={image}
                                alt={`${label} Theme Preview`}
                                className={`setting-appearance-image ${selected === key ? 'selected' : ''}`}
                            />
                            {selected === key && (
                                <div className="setting-appearance-check">
                                    <FaCheck />
                                </div>
                            )}
                            <p className="setting-appearance-label">{label}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AppearanceSettings;
