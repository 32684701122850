import React from "react";

const Button = ({ text }) => {
    return (
        <button type="submit" className="button text-white">
            {text}
        </button>
    );
};

export default Button;
