import { MdCropRotate } from "react-icons/md";
import { LuPencilRuler, LuSticker } from "react-icons/lu";
import { IoColorFilterOutline } from "react-icons/io5";
import { PiTextAa, PiMusicNotesPlus } from "react-icons/pi";

const ToolButtons = ({ activeTool, handleToolClick, isFinalized }) => {
    if (isFinalized) return null;

    return (
        <div className="story-tools">
            <button className={`tool-button ${activeTool === "crop" ? "active" : ""}`} onClick={() => handleToolClick("crop")}><span> <MdCropRotate /></span></button>
            <button className={`tool-button ${activeTool === "draw" ? "active" : ""}`} onClick={() => handleToolClick("draw")}><span> <LuPencilRuler /></span></button>
            <button className={`tool-button ${activeTool === "filter" ? "active" : ""}`} onClick={() => handleToolClick("filter")}><span> <IoColorFilterOutline /></span></button>
            <button className={`tool-button ${activeTool === "text" ? "active" : ""}`} onClick={() => handleToolClick("text")}><span> <PiTextAa /></span></button>
            <button className={`tool-button ${activeTool === "music" ? "active" : ""}`} onClick={() => handleToolClick("music")}><span> <PiMusicNotesPlus /></span></button>
            <button className={`tool-button ${activeTool === "sticker" ? "active" : ""}`} onClick={() => handleToolClick("sticker")}><span> <LuSticker /></span></button>
        </div>
    );
};

export default ToolButtons;
