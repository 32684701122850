// ThemeContext.js

import React, { createContext, useState, useEffect } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const getSystemTheme = () => {
        return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    };

    const getInitialTheme = () => {
        const stored = localStorage.getItem("theme");
        return stored === "system" ? getSystemTheme() : stored || getSystemTheme();
    };

    const [theme, setTheme] = useState(getInitialTheme());
    const [selected, setSelected] = useState(localStorage.getItem("theme") || "system");

    const applyTheme = (choice) => {
        setSelected(choice);
        localStorage.setItem("theme", choice);
        setTheme(choice === "system" ? getSystemTheme() : choice);
    };

    useEffect(() => {
        document.documentElement.setAttribute("data-theme", theme);
    }, [theme]);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        const handleChange = () => {
            if (selected === "system") {
                setTheme(getSystemTheme());
            }
        };
        mediaQuery.addEventListener("change", handleChange);
        return () => mediaQuery.removeEventListener("change", handleChange);
    }, [selected]);

    return (
        <ThemeContext.Provider value={{ theme, selected, applyTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContext;
