import React, { useState } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import "../styles/Quest.css";
import "../styles/Global.css";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { PiShareFat } from "react-icons/pi";
import { MdLocationOn, MdOutlineMoreVert } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import noimage from "../assets/compressed/no-image.webp";
import questData from "../data/QuestData";
import StartQuestForm from "../components/StartQuestForm";
import ImageCarouselModal from "../components/ImageCarouselModal";

const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
        case "in review":
            return "in-review";
        case "opened":
            return "opened";
        case "closed":
            return "closed";
        case "pending":
            return "pending";
        case "approved":
            return "approved";
        case "rejected":
            return "rejected";
        default:
            return "";
    }
};

const QuestDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const handleApplicantsClick = () => {
        navigate(`/quests/${id}/applicants`);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [isQuestStarted, setIsQuestStarted] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [showCloseOverlay, setShowCloseOverlay] = useState(false);
    const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
    const quest = questData.find(q => q.id && q.id.toString() === id);

    const isFavorite = quest.questType === "favorite";
    const isApplied = quest.questType === "applied";
    const isCreated = quest.questType === "created";
    const [isLiked, setIsLiked] = useState(isFavorite);

    const displayedImages = quest.images?.length > 0 ? quest.images.slice(0, 3) : [noimage];
    const remainingImages = quest.images?.length > 3 ? quest.images.length - 3 : 0;
    const isSingleImage = displayedImages.length === 1 || displayedImages[0] === noimage;

    const handleBack = () => {
        navigate("/quests", {
            state: {
                activeTab: location.state?.activeTab,
                subTab: location.state?.subTab
            }
        });
    };

    const toggleLike = () => {
        setIsLiked(!isLiked);
    };

    const openModalWithIndex = (index) => {
        if (displayedImages.every(img => img === noimage)) return;
        setSelectedImageIndex(index);
        setIsModalOpen(true);
    };

    if (!id) {
        return <h2>Error: Invalid Quest ID</h2>;
    }

    if (!quest) {
        return <h2>Quest Not Found</h2>;
    }

    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar"><LeftSidebar /></aside>
                <main className="col-12 col-md-10 col-lg-10 quest-main-content">
                    <div className="quest-detail-container">
                        <div className="quest-detail-header">
                            <div className="quest-detail-left">
                                <h2 className="quest-detail-title d-flex align-items-center justify-content-start">
                                    <IoIosArrowBack className="me-2" onClick={handleBack} />
                                    {quest.title}
                                </h2>
                                <span className={`quest-go-flick ${quest.status === "GoFlick" ? "goflick" : "onflick"}`}>{quest.status}</span>
                                <div className="quest-detail-location">
                                    <MdLocationOn className="quest-detail-location-icon" />
                                    <span>{quest.location}</span>
                                </div>
                            </div>
                            <div className="quest-detail-right">
                                <span className="quest-detail-reward-amount">${quest.amount}</span>
                                <span className="quest-detail-reward-label">Rewarded Amount</span>
                                {!isQuestStarted && !isApplied && !isCreated && (<button className="quest-detail-start-button" onClick={() => setIsQuestStarted(true)}>Start Quest</button>)}
                                {isApplied && (<span className={`quest-detail-applied-status ${getStatusClass(quest.appliedStatus)}`}>{quest.appliedStatus}</span>)}
                                {isCreated && (<div className="quest-detail-edit-actions">
                                    <Link to="/create-quest"><button className="quest-detail-edit-button">Edit</button></Link>
                                    <div className="quest-detail-options">
                                        <button className="quest-detail-options-button" onClick={() => setShowOptions(!showOptions)}><MdOutlineMoreVert className="fs-3" /></button>
                                        {showOptions && (
                                            <div className="quest-detail-options-dropdown">
                                                <button className="quest-detail-close-quest" onClick={() => setShowCloseOverlay(true)}>Close Quest</button>
                                            </div>
                                        )}
                                    </div>
                                </div>)}
                            </div>
                        </div>
                        {!isQuestStarted ? (
                            <>
                                <div className={`quest-detail-image-grid ${isSingleImage ? "single-image" : ""}`}>
                                    <div className={`quest-detail-main-image ${isSingleImage ? "full-width" : ""}`} onClick={() => openModalWithIndex(0)}>
                                        <img src={displayedImages[0]} alt="Quest" className={`quest-detail-main-image-img ${displayedImages[0] === noimage ? "noimage-full" : ""}`} />
                                        <div className="quest-detail-image-actions">
                                            <button className="quest-detail-share-button" onClick={(e) => e.stopPropagation()}><PiShareFat className="fs-5" /> Share</button>
                                            <button className="quest-detail-like-button" onClick={(e) => { e.stopPropagation(); toggleLike(); }}>
                                                {isLiked ? (
                                                    <GoHeartFill className="fs-5 quest-icon liked" />
                                                ) : (
                                                    <GoHeart className="fs-5 quest-icon" />
                                                )} Like
                                            </button>
                                        </div>
                                    </div>
                                    {!isSingleImage && (
                                        <div className="quest-detail-side-images">
                                            {displayedImages.slice(1).map((image, index) => (
                                                <div key={index} className="quest-detail-side-image-wrapper" onClick={() => openModalWithIndex(index + 1)}>
                                                    <img src={image} alt="Quest" className="quest-detail-side-image" />
                                                    {index === 1 && remainingImages > 0 && (
                                                        <div className="quest-detail-extra-images-overlay" onClick={() => openModalWithIndex(index + 2)}>+{remainingImages}</div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {!isCreated && (<div className="quest-detail-user-info">
                                    <div className="quest-detail-user-left">
                                        <img src={quest.avatar} alt="Avatar" className="quest-detail-user-avatar" />
                                        <div className="quest-detail-user-detail">
                                            <span className="quest-detail-user-name">{quest.name}</span>
                                            <span className="quest-detail-user-username">{quest.username}</span>
                                        </div>
                                    </div>
                                    <span className="quest-detail-last-updated">Last updated {quest.date}</span>
                                </div>
                                )}
                                {isCreated && (
                                    <div className="quest-detail-insights mt-4">
                                        <h5>Insights</h5>
                                        <div className="quest-detail-insights-grid">
                                            <div className="quest-insight-item" onClick={handleApplicantsClick} >
                                                <span className="quest-insight-label">Applicants</span>
                                                <span className="quest-insight-value">120/200<IoIosArrowForward className="fs-5" /></span>
                                            </div>
                                            <div className="quest-insight-item">
                                                <span className="quest-insight-label">Likes</span>
                                                <span className="quest-insight-value">{quest.likes || 10}</span>
                                            </div>
                                            <div className="quest-insight-item">
                                                <span className="quest-insight-label">Performance</span>
                                                <span className="quest-insight-value">98%</span>
                                            </div>
                                            <div className="quest-insight-item">
                                                <span className="quest-insight-label">Analytics</span>
                                                <a href="/" className="quest-insight-link">View Analytics</a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <h5 className="quest-detail-description-title mt-3">Description</h5>
                                <div className="quest-detail-description">{quest.description.split("\n\n").map((paragraph, index) => (<p key={index}>{paragraph}</p>))}</div>
                            </>
                        ) : (
                            <StartQuestForm
                                onCancel={() => setIsQuestStarted(false)}
                                onSubmit={(values, files) => {
                                    console.log("Form submitted:", values, files);
                                    setIsQuestStarted(false);
                                }} />
                        )}
                    </div>
                </main>
            </div>
            <MobileNav />
            {isModalOpen && <ImageCarouselModal images={quest.images} startIndex={selectedImageIndex} onClose={() => setIsModalOpen(false)} />}
            {showCloseOverlay && (
                <div className="quest-close-overlay">
                    <div className="quest-close-card">
                        <h4>Process to close a Quest :</h4>
                        <div className="quest-close-middle-content px-3">
                            <ol className="quest-close-list text-align-start">
                                <li className="mb-2">Your request to close this quest will be sent to our team.</li>
                                <li className="mb-2">Our team will review your request before closing.</li>
                            </ol>
                            <p className="text-align-start px-3 mb-3">Do you wish to proceed?</p>
                        </div>
                        <div className="quest-close-buttons">
                            <button className="quest-close-cancel-button" onClick={() => setShowCloseOverlay(false)}>Cancel</button>
                            <button className="quest-close-continue-button" onClick={() => { setShowCloseOverlay(false); setShowCloseConfirmation(true); }}>Continue</button>
                        </div>
                    </div>
                </div>
            )}
            {showCloseConfirmation && (
                <div className="quest-close-overlay">
                    <div className="quest-close-card">
                        <h4>Process to close a Quest :</h4>
                        <div className="quest-close-middle-content px-5">
                            <p>Request to close this quest has been sent to our team. We will review it and process the closure shortly.</p>
                        </div>
                        <div className="quest-close-buttons">
                            <button className="quest-close-done-button" onClick={() => setShowCloseConfirmation(false)}>OK</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuestDetail;
