import React, { useState } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import "../styles/Global.css";
import "../styles/Wallet.css";
import { Link } from "react-router-dom";
import avatar1 from "../assets/compressed/avatar4.jpg";
import { IoArrowUp, IoArrowDown, IoSearch } from "react-icons/io5";
import { RiBankFill } from "react-icons/ri";
import { LuSettings2 } from "react-icons/lu";
import TransactionDetail from "../components/TransactionDetail";

const Wallet = () => {
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const transactions = [
        { type: "Deposit", source: "Bank Of London - xx2344", amount: "+$100", status: "Success", time: "11:45 AM", isNegative: false },
        { type: "Withdrawal", source: "FlickStar Wallet", amount: "-$100", status: "Failed", time: "11:45 AM", isNegative: true },
        { type: "Deposit", source: "Bank Of London - xx2344", amount: "+$100", status: "Success", time: "11:45 AM", isNegative: false },
        { type: "Deposit", source: "Bank Of London - xx2344", amount: "+$100", status: "Pending", time: "11:45 AM", isNegative: false }
    ];

    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>
                <main className="col-12 col-md-7 col-lg-7 wallet-main-content">
                    <h5 className="wallet-heading mb-0">Wallet</h5>
                    <hr className="wallet-border" />
                    <div className="wallet-header d-flex justify-content-between align-items-center mb-3">
                        <div className="wallet-box bg-light-blue text-center p-3 rounded-3 text-black">
                            <p className="wallet-subtitle">Current balance</p>
                            <h2 className="wallet-balance">$19,200.89</h2>
                        </div>
                        <div className="wallet-box bg-purple text-center p-3 rounded-3 text-white">
                            <p className="wallet-subtitle text-white">Total Earnings</p>
                            <h2 className="wallet-balance text-white">$61,200.89</h2>
                        </div>
                    </div>

                    <div className="wallet-actions d-flex gap-3 mb-3">
                        <button className="wallet-btn withdraw-btn d-flex align-items-center gap-2">
                            <IoArrowDown className="fs-4" /> Withdraw
                        </button>
                        <button className="wallet-btn deposit-btn d-flex align-items-center gap-2">
                            <IoArrowUp className="fs-4" /> Deposit
                        </button>
                    </div>

                    <div className="transaction-history">
                        <div className="d-flex align-items-center justify-content-between mb-0">
                            <h5 className="mb-0 transaction-history-heading">Transaction History</h5>
                            <button className="transaction-filter-btn">
                                <i className="transaction-filter-icon"><LuSettings2 /></i>
                            </button>
                        </div>
                        <p className="mb-3 transaction-history-date">28 Oct 2024</p>

                        {transactions.map((tx, index) => {
                            const statusClass = {
                                success: 'text-success',
                                failed: 'text-danger',
                                pending: 'text-warning',
                            }[tx.status.toLowerCase()] || '';

                            return (
                                <div key={index} className="wallet-transaction py-2 px-3 mb-2 rounded-3" onClick={() => setSelectedTransaction(tx)}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            <p className="wallet-transaction-heading mb-1">
                                                {tx.type} to FlickStar Wallet
                                            </p>
                                            <p className={`mb-1 ${statusClass}`}>
                                                {tx.status}
                                            </p>
                                            <p className="wallet-main-source mb-0">
                                                <span className="wallet-main-source-inline">
                                                    <RiBankFill className="wallet-main-bank-icon" />
                                                    {tx.source}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="text-end">
                                            <p className="mb-1 wallet-transaction-date">{tx.time}</p>
                                            <p className={`mb-0 ${tx.isNegative ? "text-danger" : "text-success"} wallet-transaction-amount`}>{tx.amount}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </main>
                <aside className="col-3 d-none d-lg-flex flex-column py-3 home-right-sidebar">
                    <div className="home-user-details d-flex align-items-center justify-content-between">
                        <Link to='/profile' className="d-flex align-items-center text-decoration-none">
                            <img src={avatar1} alt="User Avatar" className="user-avatar" />
                            <div className="ms-3">
                                <p className="username mb-0">John Smith</p>
                                <p className="person-name mb-0">@johnsmith2045</p>
                            </div>
                        </Link>
                    </div>
                    <hr />
                    {selectedTransaction ? (
                        <TransactionDetail tx={selectedTransaction} />
                    ) : (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
                            <div className="wallet-no-transaction text-center">
                                <IoSearch className="fs-1 mb-4 wallet-no-transaction-icon" />
                                <p className="mb-0 wallet-no-transaction-description">No transaction selected.</p>
                                <p className="mb-0 wallet-no-transaction-description">Please choose one to view details.</p>
                            </div>
                        </div>
                    )}
                </aside>
            </div>
            <MobileNav />
        </div>
    );
};

export default Wallet;


