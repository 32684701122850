import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import questData from "../data/QuestData";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import "../styles/Quest.css";
import "../styles/Global.css";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";

const ApplicantDetailPage = () => {
    const { questId, applicantId } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState("");

    const quest = questData.find((q) => q.id === parseInt(questId));
    const applicant = quest?.applicants?.find((a) => a.id === parseInt(applicantId));
    const [currentIndex, setCurrentIndex] = useState(0);

    if (!quest || !applicant) return <div className="p-4">Applicant not found</div>;

    const images = applicant.images || [];
    const isNoImageOnly = images.length <= 1;

    const prevSlide = () => {
        setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    };

    const nextSlide = () => {
        setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    };

    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>

                <main className="col-12 col-md-10 col-lg-10 quest-main-content">
                    <div className="applicant-detail-quest-container">
                        <h5 className="applicant-detail-quest-header d-flex align-items-center justify-content-start" >
                            <IoIosArrowBack
                                className="me-2"
                                onClick={() => navigate(-1)}
                            />
                            Application Details
                        </h5>

                        <div className="applicant-detail-quest-info-card">
                            <div>
                                <h6 className="applicant-detail-quest-title">{quest.title}</h6>
                                <small className="quest-id">Quest#{quest.id}</small>
                            </div>
                            <div className="applicant-detail-quest-submission">
                                <h6 className="amount">${applicant.amount?.toFixed(2) || "10.00"}</h6>
                                <small className="submitted-date">
                                    Submitted Date
                                    <p className="mb-0">{applicant.submittedDate}</p>
                                </small>
                            </div>
                        </div>

                        {images.length > 0 && (
                            <div className="applicant-detail-quest-carousel-container">
                                {!isNoImageOnly && (
                                    <button className="applicant-detail-quest-prev-button" onClick={prevSlide}>
                                        <IoIosArrowBack />
                                    </button>
                                )}
                                <img
                                    src={images[currentIndex]}
                                    alt="Carousel"
                                    className="applicant-detail-quest-carousel"
                                />
                                {!isNoImageOnly && (
                                    <button className="applicant-detail-quest-next-button" onClick={nextSlide}>
                                        <IoIosArrowForward />
                                    </button>
                                )}
                            </div>
                        )}

                        <div className="applicant-detail-quest-user-header mt-5">
                            <div className="quest-detail-user-left">
                                <img src={applicant.avatar} alt="Avatar" className="quest-detail-user-avatar" />
                                <div className="quest-detail-user-detail">
                                    <span className="quest-detail-user-name">{applicant.name}</span>
                                    <span className="quest-detail-user-username">{applicant.username}</span>
                                </div>
                            </div>

                            <div className="applicant-detail-quest-actions">
                                {!status && (
                                    <>
                                        <button className="applicant-accept-btn" onClick={() => setStatus("accepted")}>Accept</button>
                                        <button className="applicant-remove-btn" onClick={() => setStatus("removed")}>Remove</button>
                                    </>
                                )}
                                {status === "accepted" && <span className="status accepted">Accepted</span>}
                                {status === "removed" && <span className="status removed">Removed</span>}
                            </div>
                        </div>
                        <div className="applicant-detail-quest-description">
                            <h5 className="quest-detail-description-title mt-3">Description</h5>
                            <div className="quest-detail-description">{quest.description.split("\n\n").map((paragraph, index) => (<p key={index}>{paragraph}</p>))}</div>
                        </div>
                    </div>
                </main>
            </div >
            <MobileNav />
        </div >
    );
};

export default ApplicantDetailPage;
