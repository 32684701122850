import React, { useState, useRef, useCallback } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import { Formik, Form, Field, ErrorMessage } from "formik";
import imageCompression from "browser-image-compression";
import QuestUploadImageOverlay from "../components/QuestUploadImageOverlay";
import * as Yup from "yup";
import "../styles/Quest.css";
import "../styles/Global.css";
import { BiCurrentLocation } from "react-icons/bi";
import { IoWalletOutline } from "react-icons/io5";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

const CreateQuestForm = ({ onClose }) => {
    const navigate = useNavigate();
    const handleClose = () => {
        navigate("/quests");
    };

    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewIndex, setPreviewIndex] = useState(null);

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };
        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Image compression error:", error);
            return file;
        }
    };

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const compressedFiles = await Promise.all(files.map((file) => compressImage(file)));
        setSelectedFiles((prev) => [...prev, ...compressedFiles]);
    };

    const handleRemoveImage = (index) => {
        setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        const compressedFiles = await Promise.all(files.map((file) => compressImage(file)));
        setSelectedFiles((prev) => [...prev, ...compressedFiles]);
    };

    const moveImage = useCallback((fromIndex, toIndex) => {
        setSelectedFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            const [movedImage] = updatedFiles.splice(fromIndex, 1);
            updatedFiles.splice(toIndex, 0, movedImage);
            return updatedFiles;
        });
    }, []);

    const fetchLocation = async (setFieldValue) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    try {
                        const response = await fetch(
                            `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
                        );
                        const data = await response.json();
                        const address = data.display_name;
                        setFieldValue("location", address);
                    } catch (error) {
                        console.error("Error fetching location:", error);
                    }
                },
                (error) => {
                    console.error("Error getting location:", error);
                    alert("Failed to get current location. Please enable location services.");
                }
            );
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const [showBalance, setShowBalance] = useState(true);
    const balance = "$19,200.89";

    const initialValues = {
        title: "",
        description: "",
        mode: "GoFlick",
        location: "",
        applicantLimit: "",
        totalAmount: "",
        media: null,
    };

    const validationSchema = Yup.object({
        title: Yup.string().required("Title is required"),
        description: Yup.string().required("Description is required"),
        location: Yup.string().required("Location is required"),
        applicantLimit: Yup.number()
            .typeError("Must be a number")
            .positive("Must be positive")
            .integer("Must be an integer"),
        totalAmount: Yup.number()
            .typeError("Must be a number")
            .positive("Must be positive"),
    });

    const handleSubmit = (values) => {
        console.log("Form Data:", values);
        onClose();
    };

    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>

                <main className="col-12 col-md-10 col-lg-10 quest-main-content">
                    <div className="create-quest-page">
                        <div className="create-quest-header-section">
                            <div className="create-quest-header-content">
                                <h2 className="create-quest-title">Create New Quest</h2>
                                <p className="create-quest-description">Fill in the details below to create your quest.</p>
                            </div>
                            <button type="button" className="create-quest-cancel-icon" onClick={handleClose}><RxCross2 /></button>
                        </div>


                        <div className="create-quest-container">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ setFieldValue }) => (
                                    <Form className="create-quest-main">
                                        <label className="create-quest-label">Title</label>
                                        <Field type="text" name="title" className="create-quest-input" placeholder="Enter the title here" />
                                        <ErrorMessage name="title" component="div" className="error-message" />

                                        <label className="create-quest-label">Description</label>
                                        <Field as="textarea" name="description" className="create-quest-textarea mb-0" placeholder="Provide brief information about your quest." rows="4" />
                                        <ErrorMessage name="description" component="div" className="error-message" />

                                        {/* Preview Images */}
                                        {selectedFiles.length > 0 && (
                                            <div className="thumbnails-container mb-0 mt-3 gap-2">
                                                <div className="thumbnails-scroll">
                                                    {selectedFiles.map((file, index) => (
                                                        <div key={index} className="thumbnail-wrapper" onClick={() => setPreviewIndex(index)}>
                                                            <img src={URL.createObjectURL(file)} alt="preview" className="thumbnail-image" />
                                                            <button className="thumbnail-image-remove-btn" onClick={(e) => { e.stopPropagation(); handleRemoveImage(index); }}>
                                                                <RxCross2 size={18} />
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {/* Upload Media */}
                                        <label className="create-quest-label">Upload Media</label>
                                        <div className="create-quest-upload-box" onClick={() => fileInputRef.current.click()} onDragOver={(e) => e.preventDefault()} onDrop={handleDrop}>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                className="create-quest-upload-input"
                                                multiple
                                                accept="image/png, image/jpeg"
                                                onChange={handleFileChange}
                                                hidden
                                            />
                                            <div className="create-quest-upload-content">
                                                <span className="create-quest-upload-icon"><i className="bi bi-cloud-upload-fill"></i></span>
                                                <p className="create-quest-upload-text">
                                                    <span className="create-quest-upload-title">Upload Files</span>
                                                    <span className="create-quest-upload-subtext"> or drag and drop</span><br />
                                                    <span className="create-quest-upload-format">PNG, JPG up to 100MB</span>
                                                </p>
                                            </div>
                                        </div>
                                        {/* Choose Mode */}
                                        <label className="create-quest-label">Choose Mode</label>
                                        <div className="create-quest-radio-group">
                                            <label className="create-quest-radio-option">
                                                <Field type="radio" name="mode" value="GoFlick" /> &nbsp;GoFlick
                                            </label>
                                            <label className="create-quest-radio-option">
                                                <Field type="radio" name="mode" value="OnFlick" /> &nbsp;OnFlick
                                            </label>
                                        </div>

                                        {/* Location */}
                                        <div className="create-quest-location-input-container mb-5">
                                            <label className="create-quest-label">Location</label>
                                            <Field type="text" name="location" className="create-quest-input" placeholder="Enter location here" />
                                            <ErrorMessage name="location" component="div" className="error-message" />
                                            <button
                                                type="button"
                                                className="create-quest-location-button text-decoration-none"
                                                onClick={() => fetchLocation(setFieldValue)}>
                                                <BiCurrentLocation className="fs-5 me-1" /> Use Current Location
                                            </button>
                                        </div>

                                        {/* Wallet Balance (Static Display) */}
                                        <div className="create-quest-wallet-container">
                                            <i className="create-quest-wallet-icon"><IoWalletOutline /></i>
                                            <i className="create-quest-eye-icon" onClick={() => setShowBalance(!showBalance)}>
                                                {showBalance ? <IoMdEye /> : <IoMdEyeOff />}
                                            </i>
                                            <p className="create-quest-wallet-label">Wallet balance</p>
                                            <h3 className="create-quest-wallet-balance">{showBalance ? balance : "$ XX,XXX"}</h3>
                                        </div>

                                        {/* Applicants Limit */}
                                        <label className="create-quest-label mb-1">Applicants Limit</label>
                                        <p className="create-quest-description-text mb-2">Please specify the number of applicants eligible to participate in this quest.</p>
                                        <Field type="text" name="applicantLimit" className="create-quest-input mb-2" placeholder="Enter the Applicant limit" />
                                        <ErrorMessage name="applicantLimit" component="div" className="error-message" />
                                        <p className="create-quest-input-helper-text text-center mb-3">Eg. 100 maximum applicants can apply</p>

                                        {/* Total Amount */}
                                        <label className="create-quest-label mb-1">Total Amount</label>
                                        <p className="create-quest-description-text mb-2">Please enter the amount to be divided equally among the applicants.</p>
                                        <div className="create-quest-amount-input-container">
                                            <span className="create-quest-currency-label">USD $</span>
                                            <Field type="text" name="totalAmount" className="create-quest-amount-input" placeholder="Enter the total amount" />
                                        </div>
                                        <ErrorMessage name="totalAmount" component="div" className="error-message" />
                                    </Form>
                                )}
                            </Formik>

                            <QuestUploadImageOverlay
                                images={selectedFiles}
                                selectedIndex={previewIndex}
                                onClose={() => setPreviewIndex(null)}
                                moveImage={moveImage}
                                setPreviewIndex={setPreviewIndex}
                            />
                        </div>
                        <div className="create-quest-actions">
                            <button type="button" onClick={onClose} className="create-quest-button-cancel">Save Draft</button>
                            <button type="submit" className="create-quest-button-submit">Create Quest</button>
                        </div>
                    </div>
                </main>
            </div>
            <MobileNav />
        </div>
    );
};

export default CreateQuestForm;
