import React from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ItemType = "IMAGE";

const DraggableThumbnail = ({ file, index, moveImage, onClick }) => {
    const [, ref] = useDrag({
        type: ItemType,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveImage(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <div ref={(node) => ref(drop(node))} className="quest-overlay-image-thumbnail-wrapper" onClick={() => onClick(index)}>
            <img src={URL.createObjectURL(file)} alt="preview" className="quest-overlay-image-thumbnail-image" />
            {index === 0 && <span className="quest-overlay-image-cover-label">Cover</span>}
        </div>
    );
};

const QuestUploadImageOverlay = ({ images, selectedIndex, onClose, moveImage, setPreviewIndex }) => {
    if (selectedIndex === null) return null;

    const goToPrev = () => {
        setPreviewIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
    };

    const goToNext = () => {
        setPreviewIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="quest-overlay-image-container">
                <div className="quest-overlay-image-content">
                    {/* <button className="quest-overlay-image-close-btn" onClick={onClose}>
                        <RxCross2 size={24} />
                    </button> */}
                    <button className="quest-overlay-image-nav quest-overlay-image-nav-left" onClick={goToPrev}>
                        <FaChevronLeft />
                    </button>
                    <div className="quest-overlay-image-preview-container">
                        <img src={URL.createObjectURL(images[selectedIndex])} alt="preview" className="quest-overlay-image-preview" />
                    </div>
                    <button className="quest-overlay-image-nav quest-overlay-image-nav-right" onClick={goToNext}>
                        <FaChevronRight />
                    </button>
                    <div className="quest-overlay-image-thumbnail-list">
                        {images.map((file, index) => (
                            <DraggableThumbnail key={index} file={file} index={index} moveImage={moveImage} onClick={setPreviewIndex} />
                        ))}
                    </div>
                    <div className="quest-overlay-image-actions">
                        <button className="quest-overlay-image-button-submit" type="button" onClick={onClose}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};

export default QuestUploadImageOverlay;
