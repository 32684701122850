import React, { useState } from "react";
import { TbCameraFilled } from "react-icons/tb";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const OverlaySelector = ({
    options,
    selected,
    onSelect,
    onClose,
    heading,
}) => {
    return (
        <div className="manage-account-setting-overlay">
            <div className="manage-account-setting-overlay-box">
                <div className="manage-account-setting-overlay-header">
                    <span>{heading}</span>
                    <button onClick={onClose}>✕</button>
                </div>
                <div className="manage-account-setting-overlay-options">
                    {options.map((option, idx) => (
                        <label key={idx} className="manage-account-setting-radio-label">
                            <span>{option}</span>
                            <input
                                type="radio"
                                name={heading}
                                value={option}
                                checked={selected === option}
                                onChange={() => {
                                    onSelect(option);
                                    onClose();
                                }}
                            />
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};

const Support = () => {
    const [showFeatureOptions, setShowFeatureOptions] = useState(false);
    const [showIssueOptions, setShowIssueOptions] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState("");
    const [selectedIssue, setSelectedIssue] = useState("");
    const [step, setStep] = useState(1);
    const [description, setDescription] = useState("");
    const [usernameOrUrl, setUsernameOrUrl] = useState("");
    const [setEvidence] = useState(null);
    const appFeatures = [
        "Feed",
        "Story",
        "Profile",
        "Wallet",
        "Search",
        "Notifications",
        "Settings",
        "Flicks",
        "Quest",
    ];

    const issueTypes = [
        "Bug",
        "Slow performance",
        "Crash",
        "UI glitch",
        "Audio issue",
        "Video not playing",
        "Login problem",
        "Other",
    ];

    const handleNext = () => {
        if (selectedFeature && selectedIssue) {
            setStep(2);
        }
    };

    return (
        <div className="support-setting-container">
            {step === 1 && (
                <>
                    <h2 className="support-setting-heading">Report an issue</h2>
                    <p className="support-setting-subheading">
                        Please select an option below to report your issue. We’ll guide you through the process to resolve it.
                    </p>
                    <div className="support-setting-form">
                        <label className="setting-personal-detail-label">App Feature</label>
                        <input
                            type="text"
                            readOnly
                            placeholder="Select feature"
                            value={selectedFeature}
                            onClick={() => setShowFeatureOptions(true)}
                            className="setting-personal-detail-input mb-3"
                        />
                        <label className="setting-personal-detail-label">Issue Type</label>
                        <input
                            type="text"
                            readOnly
                            placeholder="Select issue type"
                            value={selectedIssue}
                            onClick={() => setShowIssueOptions(true)}
                            className="setting-personal-detail-input mb-3"
                        />
                        <div className="setting-personal-detail-actions">
                            <button className="setting-personal-detail-button save" onClick={handleNext}>Next</button>
                        </div>
                    </div>
                </>
            )}
            {step === 2 && (
                <div className="support-setting-form">
                    <div className="support-setting-summary">
                        <div className="support-setting-summary-row">
                            <span className="support-setting-summary-label">App Feature</span>
                            <span className="support-setting-summary-value">{selectedFeature}</span>
                        </div>
                        <div className="support-setting-summary-row">
                            <span className="support-setting-summary-label">Issue type</span>
                            <span className="support-setting-summary-value">{selectedIssue}</span>
                        </div>
                    </div>
                    <label className="setting-personal-detail-label">Description</label>
                    <textarea
                        className="setting-personal-detail-input"
                        placeholder="Please describe the issue in detail..."
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={5}
                    />
                    <label className="setting-personal-detail-label mt-3">Evidence</label>
                    <div className="support-setting-evidence-box">
                        <label className="support-setting-evidence-upload">
                            <input
                                type="file"
                                accept="image/*,video/*"
                                onChange={(e) => setEvidence(e.target.files[0])}
                                hidden
                                className="setting-personal-detail-input"
                            />
                            <div className="support-setting-evidence-content">
                                <span className="support-setting-evidence-icon"><TbCameraFilled className="fs-1 pb-1" /></span><br />
                                <span>Add Photos/Videos<br />(Optional)</span>
                            </div>
                        </label>
                    </div>
                    <label className="setting-personal-detail-label mt-3">Date of Incident</label>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        placeholderText=" DD/MM/YYYY"
                        className="setting-personal-detail-input"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                    <label className="setting-personal-detail-label mt-3">URL/Username Involved</label>
                    <input
                        type="text"
                        className="setting-personal-detail-input"
                        placeholder="Enter URL or username"
                        value={usernameOrUrl}
                        onChange={(e) => setUsernameOrUrl(e.target.value)}
                    />
                    <p className="support-setting-disclaimer">
                        By submitting this report, you agree that we may investigate and handle the issue according to our community guidelines and privacy policy.
                    </p>
                    <div className="setting-personal-detail-actions">
                        <button className="setting-personal-detail-button save">Submit Report</button>
                    </div>
                </div>
            )}

            {showFeatureOptions && (
                <OverlaySelector
                    options={appFeatures}
                    selected={selectedFeature}
                    onSelect={setSelectedFeature}
                    onClose={() => setShowFeatureOptions(false)}
                    heading="Select App Feature"
                />
            )}
            {showIssueOptions && (
                <OverlaySelector
                    options={issueTypes}
                    selected={selectedIssue}
                    onSelect={setSelectedIssue}
                    onClose={() => setShowIssueOptions(false)}
                    heading="Select Issue Type"
                />
            )}
        </div>
    );
};
export default Support;
