import React, { useState } from "react";
import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import avatar3 from "../assets/compressed/avatar3.jpg";
import { Link } from "react-router-dom";

const initialSuggestions = [
    { id: 1, username: "Julia Smith", handle: "@juliasmith", avatar: avatar1 },
    { id: 2, username: "Christina Hopper", handle: "@chrishopper", avatar: avatar2 },
    { id: 3, username: "John Smith", handle: "@johnsmith", avatar: avatar3 },
    { id: 4, username: "Nathan James", handle: "@nathanjames", avatar: avatar1 },
    { id: 5, username: "Taylor Swift", handle: "@taylorswift", avatar: avatar2 },
];

const additionalSuggestions = [
    { id: 6, username: "Mia Cooper", handle: "@miacooper", avatar: avatar3 },
    { id: 7, username: "Ethan Lewis", handle: "@ethanlewis", avatar: avatar1 },
    { id: 8, username: "Sophia Wilson", handle: "@sophiawilson", avatar: avatar2 },
    { id: 9, username: "Liam Brown", handle: "@liambrown", avatar: avatar3 },
];

function FriendSuggestions() {
    const [suggestions, setSuggestions] = useState(initialSuggestions);
    const [nextSuggestions, setNextSuggestions] = useState(additionalSuggestions);

    const replaceFriend = (id) => {
        setSuggestions((prev) => {
            const updatedList = prev.filter((friend) => friend.id !== id);
            const nextFriend = nextSuggestions.shift();
            if (nextFriend) {
                updatedList.push(nextFriend);
                setNextSuggestions([...nextSuggestions]);
            }
            return updatedList;
        });
    };

    const handleFollow = (id) => {
        setSuggestions((prev) =>
            prev.map((friend) =>
                friend.id === id ? { ...friend, followed: true } : friend
            )
        );

        setTimeout(() => {
            replaceFriend(id);
        }, 2000);
    };

    const handleRemove = (id) => {
        replaceFriend(id);
    };

    return (
        <div className="home-friend-suggestions">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <p className="friend-suggestions-title mb-0">Friend Suggestions</p>
                <div className="d-flex align-items-center see-all">
                    <span>See All</span>
                    <i className="bi bi-arrow-up-right ms-1"></i>
                </div>
            </div>

            <hr />

            <ul className="list-unstyled">
                {suggestions.map((friend) => (
                    <React.Fragment key={friend.id}>
                        <li className="d-flex align-items-center justify-content-between">
                            <Link to='/friend-profile' className="d-flex align-items-center text-decoration-none">
                                <img
                                    src={friend.avatar}
                                    alt={friend.username}
                                    className="friend-avatar"
                                />
                                <div className="ms-3">
                                    <p className="friend-username mb-0">{friend.username}</p>
                                    <p className="friend-name mb-0">{friend.handle}</p>
                                </div>
                            </Link>

                            <div className="d-flex align-items-center">
                                <button
                                    className={`btn btn-sm me-2 ${friend.followed ? "btn-secondary" : "btn-primary"
                                        }`}
                                    onClick={() => handleFollow(friend.id)}
                                    disabled={friend.followed}
                                >
                                    {friend.followed ? "Following" : "Follow"}
                                </button>
                                <i
                                    className="bi bi-x remove-icon"
                                    onClick={() => handleRemove(friend.id)}
                                ></i>
                            </div>
                        </li>
                        <hr />
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
}

export default FriendSuggestions;
