import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const FlickImagePreview = ({ imageList, videoSrc }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? imageList.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="image-preview-container">
            {videoSrc ? (
                <video
                    src={videoSrc}
                    className="video-preview"
                    controls
                    autoPlay
                    loop
                    style={{
                        width: "100%", height: "100%",
                    }}
                    controlsList="nodownload noremoteplayback noplaybackrate nofullscreen"
                    disablePictureInPicture
                />
            ) : (
                <div className="carousel">
                    <img
                        src={imageList[currentIndex]}
                        className="images-preview"
                        alt="preview"
                    />
                    {imageList.length > 1 && (
                        <>
                            <button className="prev-btn" onClick={handlePrev}><FaChevronLeft /></button>
                            <button className="next-btn" onClick={handleNext}><FaChevronRight /></button>
                        </>
                    )}
                </div>
            )}

            {imageList.length > 1 && !videoSrc && (
                <div className="carousel-dots">
                    {imageList.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentIndex ? "active" : ""}`}
                            onClick={() => setCurrentIndex(index)}
                        ></span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FlickImagePreview;
