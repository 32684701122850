import React, { useState } from "react";
import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import avatar3 from "../assets/compressed/avatar3.jpg";
import avatar4 from "../assets/compressed/avatar4.jpg";
import avatar5 from "../assets/compressed/avatar5.jpg";
import avatar6 from "../assets/compressed/avatar6.jpg";
import avatar7 from "../assets/compressed/avatar7.jpg";
import avatar8 from "../assets/compressed/avatar8.jpg";
import { RxCross2 } from "react-icons/rx";

export const followingData = [
    { avatar: avatar1, name: "Albert Einstein", username: "YO_albert", followed: false },
    { avatar: avatar2, name: "Isaac Newton", username: "newton_king", followed: false },
    { avatar: avatar3, name: "Nikola Tesla", username: "tesla_genius", followed: false },
    { avatar: avatar4, name: "Marie Curie", username: "curie_marie", followed: false },
    { avatar: avatar5, name: "Charles Darwin", username: "darwin_origin", followed: false },
    { avatar: avatar6, name: "Galileo Galilei", username: "galileo_star", followed: false },
    { avatar: avatar7, name: "Leonardo da Vinci", username: "da_vinci_art", followed: false },
    { avatar: avatar8, name: "Ada Lovelace", username: "ada_code", followed: false },
];

const FriendFollowingModal = ({ isOpen, onClose }) => {
    const [followings, setFollowings] = useState(followingData);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedFollowing, setSelectedFollowing] = useState(null);

    if (!isOpen) return null;

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const handleFollowClick = (following) => {
        setFollowings(prevFollowings =>
            prevFollowings.map(f =>
                f.username === following.username ? { ...f, followed: true } : f
            )
        );
    };

    const handleFollowingClick = (following) => {
        setSelectedFollowing(following);
    };

    const confirmUnfollow = () => {
        setFollowings(prevFollowings =>
            prevFollowings.map(f =>
                f.username === selectedFollowing.username ? { ...f, followed: false } : f
            )
        );
        setSelectedFollowing(null);
    };

    const filteredFollowings = followings.filter(
        (following) =>
            following.name.toLowerCase().includes(searchQuery) ||
            following.username.toLowerCase().includes(searchQuery)
    );

    return (
        <>
            <div className="overlay">
                <div className="modal-content">
                    <button className="close-btn" onClick={onClose}><RxCross2 /></button>
                    <h3 className="following-heading">Following</h3>
                    <div className="following-list">
                        <div className="following-search">
                            <input
                                type="text"
                                placeholder="Search people..."
                                className="share-search-input mb-2 py-2"
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        {filteredFollowings.length > 0 ? (
                            filteredFollowings.map((following, index) => (
                                <div key={index} className="following-row">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={following.avatar} alt={following.name} className="following-avatar" />
                                        <div className="following-info">
                                            <p className="following-name mb-0">{following.name}</p>
                                            <p className="following-username mb-0">@{following.username}</p>
                                        </div>
                                    </div>
                                    <button
                                        className={following.followed ? "following-friend-button" : "follow-friend-button"}
                                        onClick={() => following.followed ? handleFollowingClick(following) : handleFollowClick(following)}
                                    >
                                        {following.followed ? 'Following' : 'Follow'}
                                    </button>
                                </div>
                            ))
                        ) : (
                            <p className="no-results">No users found</p>
                        )}
                    </div>
                </div>
            </div>
            {selectedFollowing && (
                <div className="overlay">
                    <div className="modal-content-2">
                        <img src={selectedFollowing.avatar} alt={selectedFollowing.name} className="confirmation-avatar" />
                        <p className="confirmation-name mb-0">{selectedFollowing.name}</p>
                        <p className="mb-0 confirmation-description">Are you sure you want to unfollow this user?</p>
                        <div className="confirmation-buttons">
                            <button className="user-profile-remove-button" onClick={confirmUnfollow}>Unfollow</button>
                            <button className="user-profile-cancel-button" onClick={() => setSelectedFollowing(null)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FriendFollowingModal;