import React, { useState } from "react";
import MobileNav from "../components/MobileNav";
import LeftSidebar from "../components/LeftSidebar";
import { FaUserCheck, FaChartPie } from "react-icons/fa6";
import "../styles/Quest.css";
import "../styles/Global.css";
import { useParams, useNavigate } from "react-router-dom";
import questData from "../data/QuestData";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { PiUsersThreeFill } from "react-icons/pi";
import { IoArrowUpOutline, IoArrowDownOutline, IoEyeSharp } from "react-icons/io5";
import ApexRadialChart from "../components/RadialChart";

const ApplicantsPage = () => {
    const { id } = useParams();
    const quest = questData.find((q) => q.id === parseInt(id));
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [showRemoved, setShowRemoved] = useState(false);
    const [applicants, setApplicants] = useState(quest?.applicants || []);
    const [selectedApplicants, setSelectedApplicants] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmingApplicants, setConfirmingApplicants] = useState([]);

    const filteredApplicants = applicants.filter((applicant) => {
        const isMatch = applicant.name.toLowerCase().includes(searchTerm.toLowerCase());
        const isCorrectStatus = showRemoved
            ? applicant.status === "removed"
            : applicant.status !== "removed";
        return isMatch && isCorrectStatus;
    });

    const removedCount = applicants.filter(a => a.status === "removed").length;
    const isAllSelected = filteredApplicants.length > 0 && selectedApplicants.length === filteredApplicants.length;

    const handleRemove = (id) => {
        setApplicants((prev) =>
            prev.map((applicant) =>
                applicant.id === id ? { ...applicant, status: "removed" } : applicant
            )
        );
    };

    const handleAccept = (id) => {
        setConfirmingApplicants([id]);
        setShowConfirmModal(true);
    };

    const toggleSelectApplicant = (id) => {
        setSelectedApplicants((prev) =>
            prev.includes(id)
                ? prev.filter((aid) => aid !== id)
                : [...prev, id]
        );
    };

    const toggleSelectAll = () => {
        if (isAllSelected) {
            setSelectedApplicants([]);
        } else {
            setSelectedApplicants(filteredApplicants.map((a) => a.id));
        }
    };

    const handleBulkAccept = () => {
        setConfirmingApplicants([...selectedApplicants]);
        setShowConfirmModal(true);
    };

    const handleBulkRemove = () => {
        setApplicants((prev) =>
            prev.map((applicant) =>
                selectedApplicants.includes(applicant.id)
                    ? { ...applicant, status: "removed" }
                    : applicant
            )
        );
        setSelectedApplicants([]);
    };

    const confirmAcceptApplicants = () => {
        setApplicants((prev) =>
            prev.map((applicant) =>
                confirmingApplicants.includes(applicant.id)
                    ? { ...applicant, status: "accepted" }
                    : applicant
            )
        );
        setSelectedApplicants([]);
        setConfirmingApplicants([]);
        setShowConfirmModal(false);
    };

    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>
                <main className="col-12 col-md-6 col-lg-6 quest-main-content">
                    <div className="applicant-container">
                        <div className="applicants-header">
                            <h2 className="applicants-title d-flex align-items-center justify-content-center">
                                <IoIosArrowBack className="me-2" onClick={() => navigate(-1)} />
                                Applications
                            </h2>
                        </div>
                        <div className="applicants-search-bar">
                            <input
                                type="text"
                                className="applicants-search"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>

                        <div className="d-flex justify-content-between align-items-center mb-3 applicants-action-bar">
                            <div>
                                {!showRemoved && filteredApplicants.some(applicant => applicant.status !== "accepted") && (
                                    <label className="select-all-checkbox mb-0">
                                        <input
                                            type="checkbox"
                                            checked={isAllSelected}
                                            onChange={toggleSelectAll}
                                            className="applicant-checkbox"
                                        />
                                        {isAllSelected ? "Unselect All" : "Select All"}
                                    </label>
                                )}
                            </div>

                            <div>
                                {showRemoved ? (
                                    <span
                                        className="applicants-removed-link"
                                        onClick={() => setShowRemoved(false)}
                                    >
                                        View Active Applicants <IoIosArrowForward className="fs-6" />
                                    </span>
                                ) : selectedApplicants.length > 0 ? (
                                    <div className="applicants-bulk-actions">
                                        <span className="applicant-accept" onClick={handleBulkAccept}>
                                            Accept ({selectedApplicants.length})
                                        </span>
                                        <span className="applicant-remove" onClick={handleBulkRemove}>
                                            Remove ({selectedApplicants.length})
                                        </span>
                                    </div>
                                ) : (
                                    <span
                                        className="applicants-removed-link"
                                        onClick={() => setShowRemoved(true)}
                                    >
                                        Removed Applicants ({removedCount}) <IoIosArrowForward className="fs-6" />
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="applicants-list">
                            {filteredApplicants.map((applicant) => (
                                <div key={applicant.id} className="applicant-card d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        {!showRemoved && applicant.status !== "accepted" && (
                                            <input
                                                type="checkbox"
                                                className="me-2 applicant-checkbox"
                                                checked={selectedApplicants.includes(applicant.id)}
                                                onChange={() => toggleSelectApplicant(applicant.id)}
                                            />
                                        )}
                                        <div
                                            className="applicant-info"
                                            onClick={() => navigate(`/quest/${id}/applicant/${applicant.id}`)}
                                        >
                                            <img src={applicant.avatar} alt="Avatar" className="applicant-avatar" />
                                            <div>
                                                <span className="applicant-name">{applicant.name}</span>
                                                <p className="applicant-message">{applicant.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="applicant-actions">
                                        {applicant.status === "accepted" ? (
                                            <span className="accepted-label">Accepted</span>
                                        ) : applicant.status === "removed" ? (
                                            showRemoved ? (
                                                <button className="applicant-accept-btn" onClick={() => handleAccept(applicant.id)}>Accept</button>
                                            ) : (
                                                <span className="removed-label">Removed</span>
                                            )
                                        ) : (
                                            <>
                                                <button className="applicant-accept-btn" onClick={() => handleAccept(applicant.id)}>Accept</button>
                                                <button className="applicant-remove-btn" onClick={() => handleRemove(applicant.id)}>Remove</button>
                                            </>
                                        )}
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    {showConfirmModal && (
                        <div className="applicant-accept-overlay">
                            <div className="applicant-accept-card">
                                <h4>Confirm Accept</h4>
                                <p className="text-center mb-4">Are you sure you want to accept {confirmingApplicants.length} applicant{confirmingApplicants.length > 1 ? "s" : ""}?</p>
                                <div className="applicant-accept-close-buttons">
                                    <button className="applicant-accept-cancel-button" onClick={() => setShowConfirmModal(false)}>Cancel</button>
                                    <button className="applicant-accept-button" onClick={confirmAcceptApplicants}>Accept</button>
                                </div>
                            </div>
                        </div>
                    )}
                </main>

                <aside className="col-md-4 col-lg-4 quest-analysis-panel">
                    <div className="applicants-header">
                        <h2 className="applicants-title">Quest User Interaction Analysis</h2>
                    </div>
                    <div className="analysis-grid">
                        <div className="analysis-card text-start">
                            <p className="mb-0 analysis-card-title">
                                Total Applicants
                                <PiUsersThreeFill className="info-icon" />
                            </p>
                            <span>100</span><br />
                            <small className="trend-up"><IoArrowUpOutline className="fs-5" /> 12% vs last week</small>
                        </div>
                        <div className="analysis-card text-start">
                            <p className="mb-0 analysis-card-title">
                                Total Approved
                                <FaUserCheck className="info-icon" />
                            </p>
                            <span>89</span><br />
                            <small className="trend-up"><IoArrowUpOutline className="fs-5" /> 12% vs last week</small>
                        </div>
                        <div className="analysis-card text-start">
                            <p className="mb-0 analysis-card-title">
                                Total Views
                                <IoEyeSharp className="info-icon" />
                            </p>
                            <span>112</span><br />
                            <small className="trend-up"><IoArrowUpOutline className="fs-5" /> 12% vs last week</small>
                        </div>
                        <div className="analysis-card text-start">
                            <p className="mb-0 analysis-card-title">
                                Completion Rate
                                <FaChartPie className="info-icon" />
                            </p>
                            <span>67%</span><br />
                            <small className="trend-down"><IoArrowDownOutline className="fs-5" /> 8% vs last week</small>
                        </div>
                    </div>
                    <div className="applicants-header mt-5">
                        <h2 className="applicants-title">User Status Distribution</h2>
                    </div>
                    <ApexRadialChart />
                </aside>
            </div>
            <MobileNav />
        </div>
    );
};

export default ApplicantsPage;
