import React from "react";
import { MdDevices } from "react-icons/md";
import { BiDevices } from "react-icons/bi";

const mockDeviceData = [
    {
        id: 1,
        title: "Successful login from Chrome on Windows",
        ip: "192.168.1.1",
        location: "New York, USA",
        time: "5 Mins ago",
        status: "online",
    },
    {
        id: 2,
        title: "Successful login from Chrome on Windows",
        ip: "192.168.1.1",
        location: "New York, USA",
        time: "2 weeks ago",
    },
    {
        id: 3,
        title: "Successful login from Chrome on Windows",
        ip: "192.168.1.1",
        location: "New York, USA",
        time: "11 June 2024",
    },
];

const DeviceTab = () => {
    return (
        <div className="setting-device-container">
            <div className="text-center">
                <div className="setting-device-upload-icon-circle d-flex align-items-center justify-content-center">
                    <i className="setting-device-upload-icon"><MdDevices /></i>
                </div>
            </div>
            <h2 className="setting-device-heading text-center">Devices Activity</h2>
            <p className="setting-device-description">
                "Devices Recent Activity” displays a list of devices that have accessed your account, showing details like device
                type, location, and login time. This helps monitor unauthorized access and enhance security.
            </p>
            <div className="setting-device-current-session">
                <div className="setting-device-session-header">
                    <BiDevices className="setting-device-session-icon fs-1" />
                    <div className="setting-device-session-details">
                        <h3 className="setting-device-session-title mb-0">
                            Successful login from Chrome on Windows
                        </h3>
                        <p className="setting-device-session-subtext mb-0">
                            IP: 192.168.1.1 • New York, USA
                        </p>
                    </div>
                    <div className="setting-device-session-status">Online</div>
                </div>
                <hr className="setting-device-divider" />
                <span className="setting-device-terminate-button">
                    Terminate all the sessions
                </span>
            </div>

            <h4 className="setting-device-recent-heading">Devices Recent Activity</h4>
            <div className="setting-device-recent-list">
                {mockDeviceData.map((device) => (
                    <div key={device.id} className="setting-device-recent-item">
                        <BiDevices className="setting-device-recent-icon fs-1" />
                        <div className="setting-device-recent-info">
                            <h5 className="setting-device-recent-title mb-0">{device.title}</h5>
                            <p className="setting-device-recent-subtext mb-0">
                                IP: {device.ip} • {device.location}
                            </p>
                        </div>
                        <div className="setting-device-recent-time">{device.time}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DeviceTab;
