import React, { useState } from "react";
import hashtag from "../assets/compressed/hashtag.svg";

const initialSuggestions = [
    { id: 1, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 2, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 3, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 4, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 5, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 6, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 7, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 8, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 9, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
    { id: 10, username: "#flickstarRocks", handle: "420M Flicks ", avatar: hashtag, followed: false },
];

function Tags() {
    const [suggestions] = useState(initialSuggestions);

    return (
        <div className="home-friend-suggestions">
            <ul className="list-unstyled">
                {suggestions.map((friend) => (
                    <React.Fragment key={friend.id}>
                        <li className="d-flex align-items-center justify-content-between mb-3"> {/* Added margin-bottom here */}
                            <div className="d-flex align-items-center">
                                <img
                                    src={friend.avatar}
                                    alt={friend.username}
                                    className="hastag-avatar"
                                />
                                <div className="ms-3">
                                    <p className="friend-username mb-0">{friend.username}</p>
                                    <p className="friend-name mb-0">{friend.handle}</p>
                                </div>
                            </div>
                        </li>
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
}

export default Tags;
