import React from "react";

const FlickBottomActions = ({ onEdit }) => (
    <div className="bottom-buttons">
        <button className="edit-button" onClick={onEdit}>Edit</button>
        <div className="right-buttons">
            <button className="download-button">Save as Draft</button>
            <button className="post-story-button">Post</button>
        </div>
    </div>
);

export default FlickBottomActions;
