import React from "react";
import { GrUndo, GrRedo } from "react-icons/gr";


const UndoRedoControls = ({ undo, redo, canUndo, canRedo }) => {
    return (
        <div className="undo-redo-buttons">
            <button className="undo-button" onClick={undo} disabled={!canUndo}><GrUndo /></button>
            <button className="redo-button" onClick={redo} disabled={!canRedo}><GrRedo /></button>
        </div>
    );
};

export default UndoRedoControls;
