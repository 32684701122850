import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../features/auth/authSlice";
import InputField from "../components/InputField";
import Button from "../components/Button";
import SocialLogin from "../components/SocialLogin";
import FlickStarLogo from "../assets/compressed/logo.png";
import auth1 from "../assets/compressed/auth1.webp";
import auth2 from "../assets/compressed/auth2.webp";
import auth3 from "../assets/compressed/auth3.webp";
import AppStoreIcon from "../assets/compressed/appstore.svg";
import PlayStoreIcon from "../assets/compressed/googleplay.svg";
import { Link, useNavigate } from "react-router-dom";
import PhoneImages from "../components/PhoneImages";
import "../styles/Global.css"
import "../styles/Auth.css"

const LoginPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, error } = useSelector((state) => state.auth);

    const [userDetails, setUserDetails] = useState({ emailId: '', password: '' });

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(loginUser(userDetails)).unwrap()
            .then(() => {
                navigate('/home');
            })
            .catch((err) => {
                console.error('Login failed:', err);
            });
    };
    const images = [auth1, auth2, auth3];


    return (
        <div className="login-page">
            <PhoneImages images={images} />

            {/* Right Section */}
            <div className="right-section">
                <div className="logo-content">
                    <img src={FlickStarLogo} alt="FlickStar Logo" className="logo" />
                    <div className="content">FlickStar</div>
                </div>
                <div className="login-box">
                    <form onSubmit={handleLogin}>
                        <h2>Log In</h2>
                        <InputField
                            type="text"
                            placeholder="Username, email or phone number"
                            value={userDetails.emailId}
                            onChange={(e) => setUserDetails({ ...userDetails, emailId: e.target.value })}
                        />
                        <InputField
                            type="password"
                            placeholder="Password"
                            value={userDetails.password}
                            onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
                        />
                        {error && <div className="error-message">{error}</div>}
                        <div className="forgot-password">
                            <Link to="/forgot-password" className="text-decoration-none">
                                Forgot Password?
                            </Link>
                        </div>
                        <Button text={isLoading ? "Logging in..." : "Log in"} />
                        <div className="or">
                            <hr />
                            <span id="or">Sign up With</span> <br />
                        </div>
                        <SocialLogin />
                        <div className="signup-prompt">
                            Don’t have an account? <Link to="/signup" className="signup-link text-decoration-none">Sign up</Link>
                        </div>
                    </form>
                </div>

                {/* Get the App Section */}
                <div className="get-the-app mt-4">
                    <div className="app-links d-flex gap-3">
                        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                            <img src={AppStoreIcon} alt="App Store" className="app-icon" />
                        </a>
                        <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                            <img src={PlayStoreIcon} alt="Play Store" className="app-icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
