import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoLockClosedOutline, IoShieldCheckmarkOutline } from "react-icons/io5";
import { PiDesktopTowerLight } from "react-icons/pi";
import { BiSolidBell, BiDevices } from "react-icons/bi";

const PasswordSecurity = () => {
    const [activeSection, setActiveSection] = useState(null);

    const handleCloseSection = () => {
        setActiveSection(null);
    };

    const mockDeviceData = [
        {
            id: 1,
            title: "Successful login from Chrome on Windows",
            ip: "192.168.1.1",
            location: "New York, USA",
            time: "5 Mins ago",
            status: "online",
        },
        {
            id: 2,
            title: "Successful login from Chrome on Windows",
            ip: "192.168.1.1",
            location: "New York, USA",
            time: "2 weeks ago",
        },
        {
            id: 3,
            title: "Successful login from Chrome on Windows",
            ip: "192.168.1.1",
            location: "New York, USA",
            time: "11 June 2024",
        },
    ];

    return (
        <div className="password-security-setting-container">
            <section className="password-security-setting-section">
                {activeSection === null && (
                    <>
                        <h2 className="password-security-setting-section-title">Security and Recovery</h2>
                        <p className="password-security-setting-section-subtitle">
                            Manage your account security and authentication preferences
                        </p>
                        <div
                            className="password-security-setting-item"
                            onClick={() => setActiveSection("change-password")}
                        >
                            <span className="password-security-setting-item-title">Change Password</span>
                        </div>

                        <div
                            className="password-security-setting-item"
                            onClick={() => setActiveSection("two-factor")}
                        >
                            <span className="password-security-setting-item-title">Two-Factor Authentication</span>
                        </div>
                    </>
                )}

                {activeSection === "change-password" && (
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="password-security-change-password-card">
                            <div className="password-security-change-password-header">
                                <div className="password-security-change-password-icon-circle">
                                    <i className="password-security-change-password-icon-lock"><IoLockClosedOutline /></i>
                                </div>
                                <RxCross2
                                    className="password-security-change-password-close"
                                    onClick={handleCloseSection}
                                />
                            </div>

                            <h3 className="password-security-change-password-title">Change Password</h3>
                            <p className="password-security-change-password-description">
                                Your password must be at least 6 characters and should include a combination of numbers, letters and special characters (!$@%).
                            </p>

                            <label className="setting-personal-detail-label">Current Password</label>
                            <input type="password" className="setting-personal-detail-input" placeholder="Current Password" />

                            <label className="setting-personal-detail-label mt-2">New Password</label>
                            <input type="password" className="setting-personal-detail-input" placeholder="New Password" />

                            <label className="setting-personal-detail-label mt-2">Confirm New Password</label>
                            <input type="password" className="setting-personal-detail-input" placeholder="Confirm New Password" />

                            <button className="password-security-change-password-button">Change Password</button>
                        </div>
                    </div>
                )}

                {activeSection === "two-factor" && (
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="password-security-change-password-card">
                            <div className="password-security-change-password-header">
                                <div className="password-security-change-password-icon-circle">
                                    <i className="password-security-change-password-icon-lock"><IoShieldCheckmarkOutline /></i>
                                </div>
                                <RxCross2
                                    className="password-security-change-password-close"
                                    onClick={handleCloseSection}
                                />
                            </div>
                            <h3 className="password-security-change-password-title">Two-Factor Authentication</h3>
                            <p className="password-security-change-password-description">
                                Two-factor authentication protects your account by requiring an additional code when you log in on a device that we don’t recognise
                            </p>
                            <div className="password-security-two-factor-toggle">
                                <label className="setting-personal-detail-label">In-App Authentication</label>
                                <label className="setting-notification-switch">
                                    <input
                                        type="checkbox"
                                    />
                                    <span className="setting-notification-slider" />
                                </label>
                            </div>

                            <p className="password-security-two-factor-note">Recommended</p>
                            <p className="password-security-note">Add extra Security to your account</p>

                            <label className="setting-personal-detail-label">Authentication Method</label>
                            <select className="setting-personal-detail-select">
                                <option>SMS Verification</option>
                                <option>Email Verification</option>
                                <option>Authenticator App</option>
                            </select>
                            <div className="setting-personal-detail-actions mt-5">
                                <button className="setting-personal-detail-button cancel">Reset</button>
                                <button className="setting-personal-detail-button save">Save Changes</button>
                            </div>
                        </div>
                    </div>
                )}
            </section>

            {activeSection === null && (
                <section className="password-security-setting-section password-security-setting-mt-4">
                    <h2 className="password-security-setting-section-title">Security Checks</h2>
                    <p className="password-security-setting-section-subtitle">
                        Ensure safety by running security checks across apps, devices, and sent emails
                    </p>

                    <div className="password-security-setting-item" onClick={() => setActiveSection("device-activity")}>
                        <span className="password-security-setting-item-title">Device Recent Activity</span>
                    </div>
                    <div className="password-security-setting-item" onClick={() => setActiveSection("login-alerts")}>
                        <span className="password-security-setting-item-title">Login alerts</span>
                    </div>
                </section>
            )}
            {activeSection === "device-activity" && (
                <div className="d-flex align-items-center justify-content-center">
                    <div className="password-security-change-password-card">
                        <div className="password-security-change-password-header">
                            <div className="password-security-change-password-icon-circle">
                                <i className="password-security-change-password-icon-lock"><PiDesktopTowerLight /></i>
                            </div>
                            <RxCross2 className="password-security-change-password-close" onClick={handleCloseSection} />
                        </div>
                        <h3 className="password-security-change-password-title">Devices Recent Activity</h3>
                        <p className="password-security-change-password-description">
                            Displays a list of devices that have accessed your account with details like device type, location, and login time.
                        </p>
                        <div className="setting-device-recent-list">
                            {mockDeviceData.map((device) => (
                                <div key={device.id} className="setting-device-recent-item">
                                    <BiDevices className="setting-device-recent-icon fs-1" />
                                    <div className="setting-device-recent-info">
                                        <h5 className="setting-device-recent-title mb-0">{device.title}</h5>
                                        <p className="setting-device-recent-subtext mb-0">
                                            IP: {device.ip} • {device.location}
                                        </p>
                                    </div>
                                    <div className="setting-device-recent-time">{device.time}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {activeSection === "login-alerts" && (
                <div className="d-flex align-items-center justify-content-center">
                    <div className="password-security-change-password-card">
                        <div className="password-security-change-password-header">
                            <div className="password-security-change-password-icon-circle">
                                <i className="password-security-change-password-icon-lock"><BiSolidBell /></i>
                            </div>
                            <RxCross2 className="password-security-change-password-close" onClick={handleCloseSection} />
                        </div>
                        <h3 className="password-security-change-password-title">Login alerts</h3>
                        <p className="password-security-change-password-description">
                            Manage login alerts. Email alerts are always enabled. Toggle in-app notifications below.
                        </p>
                        <div className="password-security-two-factor-toggle">
                            <label className="setting-personal-detail-label">In-App Authentication</label>
                            <label className="setting-notification-switch">
                                <input
                                    type="checkbox"
                                />
                                <span className="setting-notification-slider" />
                            </label>
                        </div>
                        <div className="password-security-login-alerts-email mt-3">
                            <label className="setting-personal-detail-label mb-0 main-email">Email</label>
                            <div className="password-security-login-alerts-email-row">
                                <span className="password-security-login-alerts-email-text">someone@cmail.com</span>
                                <span className="always-on">Always On</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PasswordSecurity;
