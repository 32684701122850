import React, { useRef, useState } from "react";
import User from "../assets/compressed/user.jpg";
import { RiDeleteBin5Line } from "react-icons/ri";
import imageCompression from "browser-image-compression";
import { ColorRing } from "react-loader-spinner";
import { IoInformationCircleOutline } from "react-icons/io5";
import CountryData from '../data/CountryData';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PersonalDetails = () => {
    const fileInputRef = useRef(null);
    const [avatar, setAvatar] = useState(null);
    const [loading, setLoading] = useState(false);
    const defaultCountry = CountryData.find(c => c.code === 'GB')
    const [selectedPhoneCode, setSelectedPhoneCode] = useState(defaultCountry?.phone_code || '')
    const [phoneInput, setPhoneInput] = useState('')
    const [dob, setDob] = useState(null);
    const defaultAvatar = User;

    const handleEditClick = () => {
        fileInputRef.current.click();
    };

    const handleCountryChange = (e) => {
        const code = e.target.value
        setSelectedPhoneCode(code)
        setPhoneInput('')
    }

    const handlePhoneChange = (e) => {
        const raw = e.target.value.replace(/\D/g, '')
        const stripped = raw.startsWith(selectedPhoneCode.replace('+', ''))
            ? raw.slice(selectedPhoneCode.length - 1)
            : raw
        setPhoneInput(stripped)
    }

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };
        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Image compression error:", error);
            return file;
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const compressed = await compressImage(file);
            const imageURL = URL.createObjectURL(compressed);
            setAvatar(imageURL);
        }
    };

    const handleDelete = () => {
        setLoading(true);
        setTimeout(() => {
            setAvatar(null);
            setLoading(false);
        }, 2500);
    };

    return (
        <div className="setting-personal-detail-form-wrapper">
            <div className="setting-personal-detail-row three-col-layout">
                <div className="detail-left">
                    <h2 className="setting-personal-detail-section-title">Profile</h2>
                    <p className="setting-personal-detail-section-subtext">Set your account Details</p>
                </div>
                <div className="detail-middle">
                    <div className="setting-personal-detail-grid-2">
                        <div>
                            <label className="setting-personal-detail-label">Name</label>
                            <input type="text" placeholder="Enter your name" className="setting-personal-detail-input" />
                        </div>
                        <div>
                            <label className="setting-personal-detail-label">Surname</label>
                            <input type="text" placeholder="Enter your Surname" className="setting-personal-detail-input" />
                        </div>
                        <div className="full-span">
                            <label className="setting-personal-detail-label">Email</label>
                            <input type="email" placeholder="Enter your Email" className="setting-personal-detail-input" />
                        </div>
                    </div>
                </div>
                <div className="detail-right">
                    <div className="setting-personal-detail-avatar-wrapper">
                        <img src={avatar || defaultAvatar} alt="Profile" className="setting-personal-detail-avatar-img" />
                        {loading && avatar && (
                            <div className="setting-personal-detail-avatar-loader-overlay">
                                <ColorRing
                                    visible={true}
                                    height="70"
                                    width="70"
                                    ariaLabel="blocks-loading"
                                    wrapperClass="avatar-loader"
                                    colors={[""]}
                                />
                            </div>
                        )}
                    </div>
                    <div className="setting-personal-detail-photo-actions">
                        <button className="setting-personal-detail-button" onClick={handleEditClick} disabled={loading}>Edit Photo</button>
                        <button className="setting-personal-detail-delete-btn" onClick={handleDelete} disabled={!avatar || loading}>
                            <RiDeleteBin5Line className="fs-5" />
                        </button>
                    </div>
                    <input type="file" accept="image/*" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                </div>
            </div>
            <hr className="setting-personal-detail-divider" />
            <div className="setting-personal-detail-row three-col-layout">
                <div className="detail-left">
                    <h2 className="setting-personal-detail-section-title">User Name</h2>
                    <p className="setting-personal-detail-section-subtext">Set your Username</p>
                </div>
                <div className="detail-middle">
                    <div className="setting-personal-detail-grid-2">
                        <div className="full-span">
                            <label className="setting-personal-detail-label">Username</label>
                            <input type="text" placeholder="Enter your Username" className="setting-personal-detail-input" />
                            <p className="setting-personal-detail-hint">
                                <IoInformationCircleOutline className="fs-5" /> Set a username that uniquely identifies you. You can change this at any time.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="detail-right" />
            </div>
            <hr className="setting-personal-detail-divider" />
            <div className="setting-personal-detail-row three-col-layout">
                <div className="detail-left">
                    <h2 className="setting-personal-detail-section-title">Personal Details</h2>
                    <p className="setting-personal-detail-section-subtext">Fill your Personal details</p>
                </div>
                <div className="detail-middle">
                    <div className="setting-personal-detail-grid-2">
                        <div>
                            <label className="setting-personal-detail-label">Date of Birth</label>
                            <DatePicker
                                selected={dob}
                                onChange={(date) => setDob(date)}
                                dateFormat="dd/MM/yyyy"
                                placeholderText=" DD/MM/YYYY"
                                className="setting-personal-detail-input"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                        <div>
                            <label className="setting-personal-detail-label">Gender</label>
                            <select className="setting-personal-detail-select gender-select" defaultValue="">
                                <option value="" disabled>Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div>
                            <label className="setting-personal-detail-label">Phone Number</label>
                            <div className="setting-personal-detail-phone-wrapper">
                                <select className="setting-personal-detail-select country-code" onChange={handleCountryChange}>
                                    {CountryData.filter(c => c.code && c.phone_code).map((country) => (
                                        <option key={country.code} value={country.phone_code} >
                                            {String.fromCodePoint(...[...country.code].map(c => 127397 + c.charCodeAt()))} {country.phone_code}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="tel"
                                    value={`${selectedPhoneCode} ${phoneInput}`}
                                    onChange={handlePhoneChange}
                                    placeholder="(123) 456-7890"
                                    className="setting-personal-detail-input setting-phone"
                                />
                            </div>
                        </div>
                        <div>
                            <label className="setting-personal-detail-label">Country</label>
                            <select className="setting-personal-detail-select">
                                {CountryData.map((country) => (
                                    <option key={country.country}>{country.country}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="detail-right" />
            </div>
            <hr className="setting-personal-detail-divider" />
            <div className="setting-personal-detail-actions">
                <button className="setting-personal-detail-button cancel">Cancel</button>
                <button className="setting-personal-detail-button save">Save Changes</button>
            </div>
        </div>
    );
};

export default PersonalDetails;
