import React from "react";

const InputField = ({ type, placeholder, value, onChange, className = "" }) => {
    return (
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`form-control ${className}`}
        />
    );
};

export default InputField;
