const AspectRatioOptions = ({ handleAspectRatioChange }) => (
    <div className="aspect-ratio-options">
        <button onClick={() => handleAspectRatioChange("original")}>
            <span>Original</span>
        </button>
        <button onClick={() => handleAspectRatioChange(1)}>
            <div className="aspect-box square"></div>
            <span>1:1</span>
        </button>
        <button onClick={() => handleAspectRatioChange(4 / 3)}>
            <div className="aspect-box rectangle"></div>
            <span>4:3</span>
        </button>
        <button onClick={() => handleAspectRatioChange(9 / 16)}>
            <div className="aspect-box portrait"></div>
            <span>9:16</span>
        </button>
        <button onClick={() => handleAspectRatioChange("fit")}>
            <span>Fit to Screen</span>
        </button>
    </div>
);

export default AspectRatioOptions;
