import React, { useState } from "react";
import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import avatar3 from "../assets/compressed/avatar3.jpg";

const initialSuggestions = [
    { id: 1, username: "Julia Smith", handle: "@juliasmith", avatar: avatar1, followed: false },
    { id: 2, username: "Christina Hopper", handle: "@chrishopper", avatar: avatar2, followed: false },
    { id: 3, username: "John Smith", handle: "@johnsmith", avatar: avatar3, followed: false },
    { id: 4, username: "Nathan James", handle: "@nathanjames", avatar: avatar1, followed: false },
    { id: 5, username: "Taylor Swift", handle: "@taylorswift", avatar: avatar2, followed: false },
    { id: 6, username: "Julia Smith", handle: "@juliasmith", avatar: avatar1, followed: false },
    { id: 7, username: "Christina Hopper", handle: "@chrishopper", avatar: avatar2, followed: false },
    { id: 8, username: "John Smith", handle: "@johnsmith", avatar: avatar3, followed: false },
    { id: 9, username: "Nathan James", handle: "@nathanjames", avatar: avatar1, followed: false },
    { id: 10, username: "Taylor Swift", handle: "@taylorswift", avatar: avatar2, followed: false },
];

function People() {
    const [suggestions, setSuggestions] = useState(initialSuggestions);

    const handleFollow = (id) => {
        setSuggestions((prev) =>
            prev.map((friend) =>
                friend.id === id ? { ...friend, followed: !friend.followed } : friend
            )
        );
    };

    return (
        <div className="home-friend-suggestions">
            <ul className="list-unstyled">
                {suggestions.map((friend) => (
                    <React.Fragment key={friend.id}>
                        <li className="d-flex align-items-center justify-content-between mb-3"> {/* Added margin-bottom here */}
                            <div className="d-flex align-items-center">
                                <img
                                    src={friend.avatar}
                                    alt={friend.username}
                                    className="friend-avatar"
                                />
                                <div className="ms-3">
                                    <p className="friend-username mb-0">{friend.username}</p>
                                    <p className="friend-name mb-0">{friend.handle}</p>
                                </div>
                            </div>

                            <div className="d-flex align-items-center">
                                <button
                                    className={`btn btn-sm me-2  ${friend.followed ? "btn-secondary" : "btn-primary"}`}
                                    onClick={() => handleFollow(friend.id)}
                                >
                                    {friend.followed ? "Following" : "Follow"}
                                </button>
                            </div>
                        </li>
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
}

export default People;
