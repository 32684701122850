import React, { useEffect, useState } from "react";
import { HuePicker, ChromePicker } from "react-color";
import { BiSolidEyedropper } from "react-icons/bi";


const ColorPicker = ({ mode = "background", bgColor, handleBgColorChange }) => {
    const [showFullPicker, setShowFullPicker] = useState(false);

    useEffect(() => {
        const firstDiv = document.querySelector(".hue-vertical div");
        const secondDiv = document.querySelector(".hue-vertical div div");

        if (firstDiv) {
            firstDiv.style.setProperty("background", "transparent", "important");
        }

        if (secondDiv) {
            secondDiv.style.setProperty("background", bgColor, "important");
            secondDiv.style.setProperty("border", "2px solid #fff", "important");
        }
    }, [bgColor]);


    const pickColorFromImage = async () => {
        if (!window.EyeDropper) {
            alert("Your browser does not support the EyeDropper API.");
            return;
        }

        try {
            const eyeDropper = new window.EyeDropper();
            const result = await eyeDropper.open();
            handleBgColorChange(result.sRGBHex);
        } catch (error) {
            console.error("Eyedropper canceled or failed", error);
        }
    };

    return (
        <div className="color-picker-container">
            <div
                className="color-dropper"
                onClick={pickColorFromImage}
                title="Pick color from image"
            >
                <BiSolidEyedropper className="fs-5" />
            </div>
            <HuePicker
                color={bgColor}
                onChange={(color) => handleBgColorChange(color)}
                direction="vertical"
                className="hue-picker"
            />
            <div
                className="color-ball"
                onClick={() => setShowFullPicker(!showFullPicker)}
                title="More colors"
            ></div>
            {showFullPicker && (
                <div className="full-color-picker">
                    <ChromePicker color={bgColor}
                        onChange={(color) => handleBgColorChange(color)} />
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
