import React, { useState } from "react";
import avatar3 from "../assets/compressed/avatar3.jpg";
import { BsTrophyFill } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa6";
import questData from "../data/QuestData";
import { useLocation } from "react-router-dom";
import QuestList from "./QuestList";


const MyQuest = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.state?.subTab ?? "My Quest");

    const filteredQuests =
        activeTab === "My Quest"
            ? questData.filter((quest) => quest.questType === "created")
            : activeTab === "Applied Quest"
                ? questData.filter((quest) => quest.questType === "applied")
                : questData.filter((quest) => quest.questType === "favorite");

    return (
        <div className="profile-quest-container">
            <div className="profile-quest-header mb-3">
                <div className="profile-quest-info">
                    <img src={avatar3} alt="User Avatar" className="profile-quest-avatar" />
                    <div className="profile-quest-details">
                        <h4 className="profile-quest-name mb-0">John_Doe</h4>
                        <p className="profile-quest-username mb-0">FlickStar Quest</p>
                        <p className="profile-quest-stats mb-0"><BsTrophyFill className="me-2 fs-6" />17 Completed &nbsp; <FaDollarSign className="me-2 fs-6" />Earnings: $120</p>
                    </div>
                </div>
                <div className="profile-quest-balance">
                    <p className="profile-quest-balance-label mb-1">Total Balance</p>
                    <h2 className="profile-quest-balance-amount mb-0">$2,280</h2>
                </div>
            </div>
            <div className="profile-quest-tabs">
                {["My Quest", "Applied Quest", "Favorites"].map((tab) => (
                    <button
                        key={tab}
                        className={`profile-quest-tab-button ${activeTab === tab ? "active" : ""}`}
                        onClick={() => setActiveTab(tab)}>
                        {tab}
                    </button>
                ))}
            </div>
            <div className="quest-container">
                <QuestList quests={filteredQuests} activeTab="Profile" subTab={activeTab} />
            </div>
        </div>
    );
};

export default MyQuest;
