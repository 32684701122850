import React from "react";

const BottomActions = ({ onEdit, onDownload }) => (
    <div className="bottom-buttons">
        <button className="edit-button" onClick={onEdit}>Edit</button>
        <div className="right-buttons">
            <button className="download-button" onClick={onDownload}>Download</button>
            <button className="post-story-button">Post a Story</button>
        </div>
    </div>
);

export default BottomActions;
