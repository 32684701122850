import React, { useState } from "react";
import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import avatar3 from "../assets/compressed/avatar3.jpg";
import { Link } from "react-router-dom";

const initialSuggestions = [
    { id: 1, username: "Julia Smith", handle: "@juliasmith", avatar: avatar1 },
    { id: 2, username: "Christina Hopper", handle: "@chrishopper", avatar: avatar2 },
    { id: 3, username: "John Smith", handle: "@johnsmith", avatar: avatar3 },
    { id: 4, username: "Nathan James", handle: "@nathanjames", avatar: avatar1 },
    { id: 5, username: "Taylor Swift", handle: "@taylorswift", avatar: avatar2 },
    { id: 6, username: "Mia Cooper", handle: "@miacooper", avatar: avatar3 },
    { id: 7, username: "Ethan Lewis", handle: "@ethanlewis", avatar: avatar1 },
    { id: 8, username: "Sophia Wilson", handle: "@sophiawilson", avatar: avatar2 },
    { id: 9, username: "Liam Brown", handle: "@liambrown", avatar: avatar3 },
    { id: 10, username: "Julia Smith", handle: "@juliasmith", avatar: avatar1 },
    { id: 11, username: "Christina Hopper", handle: "@chrishopper", avatar: avatar2 },
    { id: 12, username: "John Smith", handle: "@johnsmith", avatar: avatar3 },
    { id: 13, username: "Nathan James", handle: "@nathanjames", avatar: avatar1 },
    { id: 14, username: "Taylor Swift", handle: "@taylorswift", avatar: avatar2 },
    { id: 15, username: "Mia Cooper", handle: "@miacooper", avatar: avatar3 },
    { id: 16, username: "Ethan Lewis", handle: "@ethanlewis", avatar: avatar1 },
    { id: 17, username: "Sophia Wilson", handle: "@sophiawilson", avatar: avatar2 },
    { id: 18, username: "Liam Brown", handle: "@liambrown", avatar: avatar3 },
];

function FriendRequest({ addNotification }) {
    const [suggestions, setSuggestions] = useState(initialSuggestions);

    const handleRemove = (id) => {
        setTimeout(() => {
            setSuggestions((prev) => prev.filter((friend) => friend.id !== id));
        }, 1000);
    };

    const handleAccept = (id, username, avatar) => {
        setSuggestions((prev) =>
            prev.map((friend) =>
                friend.id === id ? { ...friend, accepted: true } : friend
            )
        );

        addNotification({
            id,
            avatar,
            username,
            message: "started following you.",
            buttonState: "follow_back",
        });


        setTimeout(() => {
            setSuggestions((prev) => prev.filter((friend) => friend.id !== id));
        }, 2000);
    };


    return (
        <div className="home-friend-suggestions">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="friend-suggestions-title mb-0">
                    Follow Requests <span className="friend-request-number">({suggestions.length})</span>
                </p>
            </div>
            <hr />
            <div className="friend-list-container" style={{ maxHeight: "75vh", overflowY: "auto" }}>
                <ul className="list-unstyled">
                    {suggestions.map((friend) => (
                        <li key={friend.id} className="d-flex align-items-center justify-content-between mb-4">
                            <Link to='/friend-profile' className="d-flex align-items-center text-decoration-none">
                                <img src={friend.avatar} alt={friend.username} className="friend-avatar" />
                                <div className="ms-2">
                                    <p className="friend-username mb-0">{friend.username}</p>
                                    <p className="friend-name mb-0">{friend.handle}</p>
                                </div>
                            </Link>

                            <div className="d-flex align-items-center">
                                <button
                                    className="btn btn-sm btn-primary me-2"
                                    onClick={() => handleAccept(friend.id, friend.username, friend.avatar)}
                                >
                                    Accept
                                </button>
                                <button
                                    className="btn btn-sm reject-button"
                                    onClick={() => handleRemove(friend.id)}
                                >
                                    Remove
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}


export default FriendRequest;

