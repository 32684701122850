import { Cropper, ImageRestriction } from "react-advanced-cropper";
import { useState, useRef } from "react";
import { MdCancel } from "react-icons/md";
import { BiRotateRight } from "react-icons/bi";

const ImagePreview = ({
    activeTool,
    cropperRef,
    croppedImage,
    imageSrc,
    videoSrc,
    bgColor,
    rotation,
    flip,
    objectFit,
    aspectRatio,
    filter,
    textItems,
    setTextItems,
    selectedTextIndex,
    setSelectedTextIndex,
    addedStickers,
    setAddedStickers
}) => {

    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [isResizing, setIsResizing] = useState(false);
    const [isRotating, setIsRotating] = useState(false);
    const [setResizeStart] = useState({ width: 0, height: 0 });
    const textContainerRef = useRef(null);

    const handleMouseDown = (index, event) => {
        if (activeTool !== "text") return;
        if (!isResizing && !isRotating) {
            setIsDragging(true);
            setSelectedTextIndex(index);
            setDragStart({
                x: event.clientX - textItems[index].x,
                y: event.clientY - textItems[index].y
            });
        }
        event.stopPropagation();
    };

    const handleMouseMove = (event) => {
        if (activeTool !== "text") return;
        if (isDragging && selectedTextIndex !== null) {
            const newTextItems = [...textItems];
            newTextItems[selectedTextIndex].x = event.clientX - dragStart.x;
            newTextItems[selectedTextIndex].y = event.clientY - dragStart.y;
            setTextItems(newTextItems);
        } else if (isResizing && selectedTextIndex !== null) {
            const newTextItems = [...textItems];
            const item = newTextItems[selectedTextIndex];
            const deltaX = event.clientX - dragStart.x;
            const scaleFactor = Math.max(0.5, 1 + deltaX / 100);
            item.scale = scaleFactor;
            setTextItems(newTextItems);
        } else if (isRotating && selectedTextIndex !== null) {
            const newTextItems = [...textItems];
            const item = newTextItems[selectedTextIndex];
            const rect = event.target.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            const radians = Math.atan2(event.clientY - centerY, event.clientX - centerX);
            const angle = radians * (180 / Math.PI);
            item.rotation = angle;
            setTextItems(newTextItems);
        }
    };

    const handleMouseUp = () => {
        if (activeTool !== "text") return;
        setIsDragging(false);
        setIsResizing(false);
        setIsRotating(false);
    };

    const handleResizeMouseDown = (index, event) => {
        if (activeTool !== "text") return;
        setIsResizing(true);
        setSelectedTextIndex(index);
        setDragStart({ x: event.clientX, y: event.clientY });
        setResizeStart({ width: textItems[index].width, height: textItems[index].height });
        event.stopPropagation();
    };

    const handleRotateMouseDown = (index, event) => {
        if (activeTool !== "text") return;
        setIsRotating(true);
        setSelectedTextIndex(index);
        event.stopPropagation();
    };

    const handleDeleteText = (index, event) => {
        if (activeTool !== "text") return;
        event.stopPropagation();
        const newTextItems = textItems.filter((_, i) => i !== index);
        setTextItems(newTextItems);
        setSelectedTextIndex(null);
    };


    const [stickerDragging, setStickerDragging] = useState(false);
    const [stickerDragStart, setStickerDragStart] = useState({ x: 0, y: 0 });
    const [stickerResizing, setStickerResizing] = useState(false);
    const [stickerRotating, setStickerRotating] = useState(false);
    const [selectedStickerIndex, setSelectedStickerIndex] = useState(null);
    const stickerContainerRef = useRef(null);

    const handleStickerMouseDown = (index, event) => {
        if (activeTool !== "sticker") return;
        if (!stickerResizing && !stickerRotating) {
            setStickerDragging(true);
            setSelectedStickerIndex(index);
            setStickerDragStart({
                x: event.clientX - addedStickers[index].x,
                y: event.clientY - addedStickers[index].y
            });
        }
        event.stopPropagation();
    };

    const handleStickerMouseMove = (event) => {
        if (activeTool !== "sticker") return;
        if (stickerDragging && selectedStickerIndex !== null) {
            const newStickers = [...addedStickers];
            newStickers[selectedStickerIndex].x = event.clientX - stickerDragStart.x;
            newStickers[selectedStickerIndex].y = event.clientY - stickerDragStart.y;
            setAddedStickers(newStickers);
        } else if (stickerResizing && selectedStickerIndex !== null) {
            const newStickers = [...addedStickers];
            const sticker = newStickers[selectedStickerIndex];
            const deltaX = event.clientX - stickerDragStart.x;
            const scaleFactor = Math.max(0.5, 1 + deltaX / 100);
            sticker.scale = scaleFactor;
            setAddedStickers(newStickers);
        } else if (stickerRotating && selectedStickerIndex !== null) {
            const newStickers = [...addedStickers];
            const sticker = newStickers[selectedStickerIndex];
            const rect = event.target.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            const radians = Math.atan2(event.clientY - centerY, event.clientX - centerX);
            const angle = radians * (180 / Math.PI);
            sticker.rotation = angle;
            setAddedStickers(newStickers);
        }
    };

    const handleStickerMouseUp = () => {
        if (activeTool !== "sticker") return;
        setStickerDragging(false);
        setStickerResizing(false);
        setStickerRotating(false);
    };

    const handleStickerResizeMouseDown = (index, event) => {
        if (activeTool !== "sticker") return;
        setStickerResizing(true);
        setSelectedStickerIndex(index);
        setStickerDragStart({ x: event.clientX, y: event.clientY });
        event.stopPropagation();
    };

    const handleStickerRotateMouseDown = (index, event) => {
        if (activeTool !== "sticker") return;
        setStickerRotating(true);
        setSelectedStickerIndex(index);
        event.stopPropagation();
    };

    const handleStickerDelete = (index, event) => {
        if (activeTool !== "sticker") return;
        event.stopPropagation();
        const newStickers = addedStickers.filter((_, i) => i !== index);
        setAddedStickers(newStickers);
        setSelectedStickerIndex(null);
    };

    const handleClickOutside = (event) => {
        if (activeTool === "text") {
            if (textContainerRef.current && !textContainerRef.current.contains(event.target)) {
                setSelectedTextIndex(null);
            }
        } else if (activeTool === "sticker") {
            if (stickerContainerRef.current && !stickerContainerRef.current.contains(event.target)) {
                setSelectedStickerIndex(null);
            }
        }
    };

    return (
        <div
            className="image-preview-container"
            style={{ backgroundColor: bgColor, position: "relative" }}
            onMouseMove={activeTool === "text" ? handleMouseMove : activeTool === "sticker" ? handleStickerMouseMove : undefined}
            onMouseUp={activeTool === "text" ? handleMouseUp : activeTool === "sticker" ? handleStickerMouseUp : undefined}
            onClick={handleClickOutside}>
            {videoSrc ? (
                <video
                    src={videoSrc}
                    className="video-preview"
                    controls
                    autoPlay
                    loop
                    style={{
                        objectFit: objectFit, backgroundColor: bgColor, width: "100%", height: "100%", transform: `rotate(${rotation}deg) scale(${flip.horizontal ? -1 : 1}, ${flip.vertical ? -1 : 1})`,
                    }}
                    transformImage={true}
                    stencilProps={{
                        handlers: true,
                        movable: true,
                        resizable: true,
                        aspectRatio: aspectRatio,
                    }}
                    controlsList="nodownload noremoteplayback noplaybackrate nofullscreen"
                    disablePictureInPicture
                />
            ) : activeTool === "crop" ? (
                <Cropper
                    imageRestriction={ImageRestriction.fillArea}
                    ref={cropperRef}
                    src={croppedImage || imageSrc}
                    className={`cropper ${filter}`}
                    stencilProps={{
                        handlers: true,
                        movable: true,
                        resizable: true,
                        aspectRatio: aspectRatio,
                    }}
                    transformImage={true}
                    style={{
                        backgroundColor: bgColor,
                        transform: `rotate(${rotation}deg) scale(${flip.horizontal ? -1 : 1}, ${flip.vertical ? -1 : 1})`,
                        objectFit: objectFit,
                    }} />
            ) : (
                <img
                    src={croppedImage || imageSrc}
                    className={`image-preview ${filter}`}
                    alt="preview"
                    style={{ objectFit: objectFit }} />
            )}

            {addedStickers.map((sticker, index) => (
                <div
                    key={index}
                    ref={selectedStickerIndex === index ? stickerContainerRef : null}
                    className={`sticker-box ${selectedStickerIndex === index ? "selected" : ""}`}
                    style={{
                        left: `${sticker.x}px`,
                        top: `${sticker.y}px`,
                        transform: `rotate(${sticker.rotation || 0}deg) scale(${sticker.scale || 0.5})`
                    }}
                    onMouseDown={activeTool === "sticker" ? (e) => handleStickerMouseDown(index, e) : undefined}>
                    <img src={sticker.src} className="added-sticker" alt="Sticker" />
                    {selectedStickerIndex === index && activeTool === "sticker" && (
                        <>
                            <MdCancel className="delete-sticker fs-4" onClick={(e) => handleStickerDelete(index, e)} />
                            <div className="sticker-resize-handle" onMouseDown={(e) => handleStickerResizeMouseDown(index, e)}></div>
                            <div className="sticker-rotate-handle" onMouseDown={(e) => handleStickerRotateMouseDown(index, e)}>
                                <BiRotateRight className="rotate-icon fs-4" />
                            </div>
                        </>
                    )}
                </div>
            ))}

            {textItems.map((item, index) => (
                item.text && (
                    <div
                        key={index}
                        ref={selectedTextIndex === index ? textContainerRef : null}
                        className={`text-box ${selectedTextIndex === index ? "selected" : ""}`}
                        style={{
                            left: `${item.x}px`,
                            top: `${item.y}px`,
                            transform: `rotate(${item.rotation || 0}deg) scale(${item.scale || 1})`,
                        }}
                        onMouseDown={activeTool === "text" ? (e) => handleMouseDown(index, e) : undefined}>
                        <div
                            className="text-content"
                            style={{
                                fontSize: item.style.fontSize,
                                transform: `scaleX(${flip.horizontal ? -1 : 1})`,
                                fontFamily: item.style.fontFamily,
                                fontWeight: item.style.fontWeight,
                                color: item.style.color,
                            }}
                            contentEditable={activeTool === "text"}
                            suppressContentEditableWarning={true}>
                            {item.text}
                        </div>
                        {selectedTextIndex === index && activeTool === "text" && (
                            <>
                                <MdCancel className="delete-text fs-4" onClick={(e) => handleDeleteText(index, e)} />
                                <div className="resize-handle" onMouseDown={(e) => handleResizeMouseDown(index, e)}></div>
                                <div className="rotate-handle" onMouseDown={(e) => handleRotateMouseDown(index, e)}>
                                    <BiRotateRight className="rotate-icon fs-4" />
                                </div>
                            </>
                        )}
                    </div>
                )
            ))}
        </div>
    );
};

export default ImagePreview;