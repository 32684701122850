import { RxAspectRatio, RxRotateCounterClockwise } from "react-icons/rx";
import { BiSolidColor } from "react-icons/bi";
import { CgEditFlipH, CgEditFlipV } from "react-icons/cg";

const CropTools = ({ handleToolClick, handleRotation, handleFlipHorizontal, handleFlipVertical, handleCropDone }) => (
    <div className="right-side-tools">
        <button className="crop-tool-button" onClick={() => handleToolClick("aspect")}><span><RxAspectRatio /></span></button>
        <button className="crop-tool-button" onClick={handleRotation}><span><RxRotateCounterClockwise /></span></button>
        <button className="crop-tool-button" onClick={() => handleToolClick("bgColor")}><span><BiSolidColor /></span></button>
        <button className="crop-tool-button" onClick={handleFlipHorizontal}><span><CgEditFlipH /></span></button>
        <button className="crop-tool-button" onClick={handleFlipVertical}><span><CgEditFlipV /></span></button>
        <button className="done-button btn btn-outline" onClick={handleCropDone}>Save</button>
    </div>
);

export default CropTools;
