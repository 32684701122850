import React, { useState } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import avatar3 from "../assets/compressed/avatar3.jpg";

const initialSuggestions = [
    { id: 1, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar1 },
    { id: 2, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar2 },
    { id: 3, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar3 },
    { id: 4, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar1 },
    { id: 5, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar2 },
    { id: 6, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar1 },
    { id: 7, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar2 },
    { id: 8, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar3 },
    { id: 9, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar1 },
    { id: 10, username: "Dil Tu Jaan Tu", handle: "Arvind Goyal", flicks: "54 Flicks", avatar: avatar2 },
];

function Music() {
    const [suggestions, setSuggestions] = useState(initialSuggestions);

    const handleTogglePlay = (id) => {
        setSuggestions((prev) =>
            prev.map((friend) =>
                friend.id === id ? { ...friend, playing: !friend.playing } : friend
            )
        );
    };

    return (
        <div className="home-friend-suggestions">
            <ul className="list-unstyled">
                {suggestions.map((friend) => (
                    <React.Fragment key={friend.id}>
                        <li className="d-flex align-items-center justify-content-between mb-3">
                            <div className="d-flex align-items-center">
                                <img
                                    src={friend.avatar}
                                    alt={friend.username}
                                    className="music-avatar"
                                />
                                <div className="ms-3">
                                    <p className="friend-username mb-0">{friend.username}</p>
                                    <div className="friend-details d-flex align-items-center">
                                        <span className="friend-name mb-0">{friend.handle}</span>
                                        <span className="search-dot mx-2">•</span>
                                        <span className="friend-name mb-0">{friend.flicks}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex align-items-center music-icon">
                                <button
                                    className="btn btn-sm"
                                    onClick={() => handleTogglePlay(friend.id)}
                                >
                                    {friend.playing ? <FaPause /> : <FaPlay />}
                                </button>
                            </div>
                        </li>
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
}

export default Music;
