const PopupConfirmation = ({ showPopup, actionType, confirmAction, setShowPopup }) => {
    if (!showPopup) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-box">
                <p className="popup-heading mb-0">Are you sure you want to {actionType === "cancel" ? "cancel this story?" : "go back?"}</p>
                <div className="confirmation-buttons">
                    <button className="remove-button" onClick={confirmAction}>Discard</button>
                    <button className="cancel-box-button" onClick={() => setShowPopup(false)}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default PopupConfirmation;
