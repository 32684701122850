const TransactionDetail = ({ tx }) => {
    const isSuccess = tx.status.toLowerCase() === "success";
    const amount = tx.amount.startsWith("-") ? tx.amount : `+ ${tx.amount.replace("+", "")}`;
    const pillColor = isSuccess ? "wallet-pill-success" : "wallet-pill-failed";
    const cardColor = isSuccess ? "wallet-card-success" : "wallet-card-failed";

    return (
        <div className="wallet-detail-wrapper">
            <h5 className="wallet-detail-title">Transaction Details</h5>

            <div className="wallet-detail-amount text-center mb-2">
                <span className={`wallet-detail-amount-value ${isSuccess ? "text-success" : "text-danger"}`}>
                    {amount}
                </span>
            </div>

            <div className={`wallet-pill ${pillColor} text-center mb-3`}>
                <span className="pill-dot" />
                {isSuccess ? "Successful" : "Failed"}
            </div>

            <div className={`wallet-detail-status-card ${cardColor}`}>
                <div className="wallet-detail-step">
                    <div className="step-icon done" />
                    <div className="step-line" />
                    <div className="step-info">
                        <p className="step-label">Debited From</p>
                        <p className="step-source">{tx.source}</p>
                        <p className="step-time">Sep 10, 2024 3:12 PM</p>
                    </div>
                </div>

                <div className="wallet-detail-step">
                    <div className={`step-icon ${isSuccess ? "done" : "failed"}`} />
                    <div className="step-line transparent-line" />
                    <div className="step-info">
                        <p className="step-label">Credited To</p>
                        <p className="step-source">FlickStar Wallet</p>
                        <p className="step-time">Sep 10, 2024 3:20 PM</p>
                    </div>
                </div>
            </div>

            <div className="wallet-transfer-info mt-4">
                <p><strong>Transaction ID:</strong> TZ34567890-989</p>
                <p><strong>Date and Time:</strong> Sep10, 2024 3:12 AM</p>
                <p><strong>Status:</strong> Completed</p>
            </div>
        </div>
    );
};

export default TransactionDetail;
