import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import PersonalDetails from "./pages/signup/PersonalDetails";
import SetUsername from "./pages/signup/SetUsername";
import HomePage from "./pages/HomePage";
import SetPassword from "./pages/signup/SetPassword";
import Verification from "./pages/signup/Verification";
import Signup from "./pages/signup/Signup";
import { ThemeProvider } from "./context/ThemeContext";
import ForgotPassword from "./pages/forgetPassword/ForgotPassword";
import Verify from "./pages/forgetPassword/Verify";
import ChangePassowrd from "./pages/forgetPassword/ChangePassword";
import useViewportHeight from "./hooks/useViewportHeight";
import Search from "./pages/Search";
import "./styles/Global.css";
import Profile from "./pages/Profile";
import FriendProfile from "./pages/FriendProfile";
import Notification from "./pages/Notification";
import Create from "./pages/Create";
import Quest from "./pages/Quest";
import CreateQuestForm from "./pages/CreateQuestForm";
import QuestDetail from "./pages/QuestDetail";
import ApplicantsPage from "./pages/ApplicantsPage";
import ApplicantDetailPage from "./pages/ApplicantDetailPage";
import Setting from "./pages/Setting";
import Wallet from "./pages/Wallet";

function App() {
  useViewportHeight();
  return (
    <ThemeProvider>
      <Router>
        <div className="scroll-container">
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password/verify" element={<Verify />} />
            <Route path="/forgot-password/verify/change-password" element={<ChangePassowrd />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/search" element={<Search />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/friend-profile" element={<FriendProfile />} />
            <Route path="/notifications" element={<Notification />} />
            <Route path="/quests" element={<Quest />} />
            <Route path="/quests/:id" element={<QuestDetail />} />
            <Route path="/quests/:id/applicants" element={<ApplicantsPage />} />
            <Route path="/quest/:questId/applicant/:applicantId" element={<ApplicantDetailPage />} />
            <Route path="/create-quest" element={<CreateQuestForm />} />
            <Route path="/create" element={<Create />} />
            <Route path="/settings" element={<Setting />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup/personal-detail" element={<PersonalDetails />} />
            <Route path="/signup/set-username" element={<SetUsername />} />
            <Route path="/signup/set-password" element={<SetPassword />} />
            <Route path="/signup/verification" element={<Verification />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
