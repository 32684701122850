import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import avatar3 from "../assets/compressed/avatar3.jpg";
import avatar4 from "../assets/compressed/avatar4.jpg";
import avatar5 from "../assets/compressed/avatar5.jpg";
import avatar6 from "../assets/compressed/avatar6.jpg";
import logo1 from "../assets/compressed/logo1.png";
import quest from "../assets/compressed/quest.webp";
import quest1 from "../assets/compressed/quest1.webp";
import quest2 from "../assets/compressed/quest2.webp";
import quest3 from "../assets/compressed/quest3.webp";
import quest4 from "../assets/compressed/quest4.jpg";
import quest5 from "../assets/compressed/quest5.jpg";
import quest6 from "../assets/compressed/quest6.jpg";

const questData = [
    {
        id: 1,
        images: [quest, quest1, quest2, quest5, quest6, quest4],
        avatar: avatar1,
        name: "Alice Carter",
        username: "@alicecarter",
        title: "Explore The Secret Library of Wisdom",
        status: "OnFlick",
        location: "Downtown, New York, USA",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 50,
        date: "24 Mar 2023",
        questType: "created",
        questStatus: "Opened",
        appliedStatus: "Pending",
        applicantCount: 2,
        applicants: [
            {
                id: 101,
                avatar: avatar1,
                name: "John Doe",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest6, quest5, quest4, quest3],
            },
            {
                id: 102,
                avatar: avatar2,
                name: "Alice Bloom",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1],
            },
            {
                id: 103,
                avatar: avatar1,
                name: "Alice Carter",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest3, quest2, quest6],
            },
            {
                id: 104,
                avatar: avatar3,
                name: "Alice Bloom",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1],
            },
            {
                id: 105,
                avatar: avatar5,
                name: "John Doe",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest, quest2],
            },
            {
                id: 106,
                avatar: avatar2,
                name: "Alice Alice",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest6, quest5, quest4],
            },
            {
                id: 107,
                avatar: avatar1,
                name: "Jonathan Doe",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest3, quest2, quest4],
            },
            {
                id: 108,
                avatar: avatar6,
                name: "Nathan Bloom",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1],
            },
            {
                id: 112,
                avatar: avatar3,
                name: "Emma Brown",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest, quest2],
            },
            {
                id: 109,
                avatar: avatar5,
                name: "Alice Bloom",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1, quest5, quest4],
            },
            {
                id: 110,
                avatar: avatar4,
                name: "Sophia Martinez",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest, quest2, quest3],
            },
            {
                id: 111,
                avatar: avatar2,
                name: "James Anderson",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1, quest, quest6],
            }
        ],
        totalApplicants: 50
    },
    {
        id: 2,
        images: [quest2, quest3, quest4],
        avatar: avatar2,
        name: "James Anderson",
        username: "@jamesanderson",
        title: "Mountain Survival Training",
        status: "GoFlick",
        location: "Rocky Mountains, Colorado, USA",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 200,
        date: "10 Sep 2024",
        questType: "created",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 35,
        applicants: [
            {
                id: 101,
                avatar: avatar1,
                name: "John Doe",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest6, quest5, quest4, quest3],
            },
            {
                id: 102,
                avatar: avatar2,
                name: "Alice Bloom",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1],
            },
            {
                id: 103,
                avatar: avatar1,
                name: "Alice Carter",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest3, quest2, quest6],
            },
            {
                id: 104,
                avatar: avatar3,
                name: "Alice Bloom",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1],
            },
            {
                id: 105,
                avatar: avatar5,
                name: "John Doe",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest, quest2],
            },
            {
                id: 106,
                avatar: avatar2,
                name: "Alice Alice",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest6, quest5, quest4],
            },
            {
                id: 107,
                avatar: avatar1,
                name: "Jonathan Doe",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest3, quest2, quest4],
            },
            {
                id: 108,
                avatar: avatar6,
                name: "Nathan Bloom",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1],
            },
            {
                id: 112,
                avatar: avatar3,
                name: "Emma Brown",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest, quest2],
            },
            {
                id: 109,
                avatar: avatar5,
                name: "Alice Bloom",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1, quest5, quest4],
            },
            {
                id: 110,
                avatar: avatar4,
                name: "Sophia Martinez",
                username: "john_doe",
                message: "Hey, I'm applying for your quest",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "29 Mar 2024",
                images: [quest, quest2, quest3],
            },
            {
                id: 111,
                avatar: avatar2,
                name: "James Anderson",
                username: "aliceb",
                message: "Here's my submission!",
                description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
                status: "pending",
                submittedDate: "28 Mar 2024",
                images: [quest1, quest, quest6],
            }
        ],
        totalApplicants: 100
    },
    {
        id: 3,
        images: [],
        avatar: avatar3,
        name: "Sophia Martinez",
        username: "@sophiam",
        title: "Secret Pizza Tasting",
        status: "GoFlick",
        location: "Naples, Italy",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 80,
        date: "15 Jan 2023",
        questType: "created",
        questStatus: "Closed",
        appliedStatus: "Rejected",
        applicantCount: 10,
        totalApplicants: 50
    },
    {
        id: 4,
        images: [quest4, quest5, quest6, quest, quest1, quest2],
        avatar: avatar4,
        name: "Emma Brown",
        username: "@emmabrown",
        title: "Underwater Diving Experience",
        status: "OnFlick",
        location: "Great Barrier Reef, Australia",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 300,
        date: "05 Jul 2025",
        questType: "created",
        questStatus: "Opened",
        appliedStatus: "Pending",
        applicantCount: 15,
        totalApplicants: 60
    },
    {
        id: 5,
        images: [quest5, quest6, quest1],
        avatar: avatar5,
        name: "Daniel White",
        username: "@danielwhite",
        title: "Astronomy Night Camp",
        status: "GoFlick",
        location: "Arizona Desert, USA",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 120,
        date: "20 Dec 2025",
        questType: "created",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 25,
        totalApplicants: 90
    },
    {
        id: 6,
        images: [quest1, quest2, quest3],
        avatar: avatar6,
        name: "Rachel Green",
        username: "@rachelgreen",
        title: "Eco-Friendly Farming Workshop",
        status: "GoFlick",
        location: "California, USA",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 120,
        date: "10 Jul 2025",
        questType: "applied",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 20,
        totalApplicants: 80
    },
    {
        id: 7,
        images: [quest3, quest4, quest5],
        avatar: avatar2,
        name: "Michael Johnson",
        username: "@michaelj",
        title: "Sunset Yoga Retreat",
        status: "OnFlick",
        location: "Bali, Indonesia",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 150,
        date: "20 Aug 2024",
        questType: "applied",
        questStatus: "In Review",
        appliedStatus: "Pending",
        applicantCount: 40,
        totalApplicants: 100
    },
    {
        id: 8,
        images: [quest2, quest5, quest6],
        avatar: logo1,
        name: "FlickStar",
        username: "Sponsored",
        title: "Exclusive Movie Premiere Night",
        status: "GoFlick",
        location: "Hollywood, California, USA",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 250,
        date: "18 Dec 2025",
        questType: "sponsored",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 50,
        totalApplicants: 200
    },
    {
        id: 9,
        images: [quest1, quest3, quest4],
        avatar: logo1,
        name: "FlickStar",
        username: "Sponsored",
        title: "Midnight Espresso Tasting",
        status: "OnFlick",
        location: "New York, USA",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 180,
        date: "05 Feb 2024",
        questType: "sponsored",
        questStatus: "Closed",
        appliedStatus: "Pending",
        applicantCount: 70,
        totalApplicants: 120
    },
    {
        id: 10,
        images: [quest4, quest5, quest6],
        avatar: logo1,
        name: "FlickStar",
        username: "Sponsored",
        title: "Exclusive Movie Premiere Night",
        status: "GoFlick",
        location: "Hollywood, California, USA",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 250,
        date: "18 Dec 2025",
        questType: "sponsored",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 50,
        totalApplicants: 200
    },
    {
        id: 11,
        images: [quest, quest3, quest4],
        avatar: logo1,
        name: "FlickStar",
        username: "Sponsored",
        title: "Midnight Espresso Tasting",
        status: "OnFlick",
        location: "New York, USA",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 180,
        date: "05 Feb 2024",
        questType: "sponsored",
        questStatus: "Closed",
        appliedStatus: "Pending",
        applicantCount: 70,
        totalApplicants: 120
    },
    {
        id: 12,
        images: [quest5, quest3, quest4],
        avatar: logo1,
        name: "FlickStar",
        username: "Sponsored",
        title: "Midnight Espresso Tasting",
        status: "OnFlick",
        location: "New York, USA",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 180,
        date: "05 Feb 2024",
        questType: "sponsored",
        questStatus: "Closed",
        appliedStatus: "Pending",
        applicantCount: 70,
        totalApplicants: 120
    },
    {
        id: 13,
        images: [quest4, quest5, quest6],
        avatar: avatar5,
        name: "Emma Williams",
        username: "@emmaw",
        title: "Gourmet Chocolate Tasting",
        status: "GoFlick",
        location: "Brussels, Belgium",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 300,
        date: "11 Mar 2024",
        questType: "favorite",
        questStatus: "Closed",
        appliedStatus: "Closed",
        applicantCount: 200,
        totalApplicants: 500
    },
    {
        id: 14,
        images: [quest2],
        avatar: avatar3,
        name: "John Smith",
        username: "@johnsmith",
        title: "Photography Contest 2024",
        status: "OnFlick",
        location: "Paris, France",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 400,
        date: "21 Apr 2025",
        questType: "favorite",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 150,
        totalApplicants: 300
    },
    {
        id: 15,
        images: [quest4, quest5, quest6, quest1, quest],
        avatar: avatar5,
        name: "William Johnson",
        username: "@williamj",
        title: "Wildlife Photography Expedition",
        status: "GoFlick",
        location: "Serengeti, Tanzania",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 500,
        date: "10 Jun 2025",
        questType: "general",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 30,
        totalApplicants: 100
    },
    {
        id: 16,
        images: [quest1, quest3, quest5, quest4],
        avatar: avatar2,
        name: "Olivia Harris",
        username: "@oliviah",
        title: "Ancient Ruins Exploration",
        status: "OnFlick",
        location: "Machu Picchu, Peru",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 450,
        date: "15 Jul 2025",
        questType: "general",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 30,
        totalApplicants: 100,
    },
    {
        id: 17,
        images: [quest2, quest4, quest6],
        avatar: avatar3,
        name: "Ethan Brown",
        username: "@ethanb",
        title: "Underwater Cave Diving",
        status: "GoFlick",
        location: "Yucatán Peninsula, Mexico",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 600,
        date: "02 Sep 2025",
        questType: "general",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 30,
        totalApplicants: 100
    },
    {
        id: 18,
        images: [quest5, quest6],
        avatar: avatar4,
        name: "Sophia Wilson",
        username: "@sophiaw",
        title: "Hot Air Balloon Adventure",
        status: "OnFlick",
        location: "Cappadocia, Turkey",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 550,
        date: "22 Aug 2025",
        questType: "general",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 30,
        totalApplicants: 100
    },
    {
        id: 19,
        images: [quest1, quest2, quest3],
        avatar: avatar6,
        name: "Liam Carter",
        username: "@liamc",
        title: "Northern Lights Photography",
        status: "GoFlick",
        location: "Tromsø, Norway",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 700,
        date: "10 Dec 2025",
        questType: "general",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 30,
        totalApplicants: 100
    },
    {
        id: 20,
        images: [quest3, quest4, quest5],
        avatar: avatar5,
        name: "Emma White",
        username: "@emmaw",
        title: "Jungle Safari Expedition",
        status: "OnFlick",
        location: "Amazon Rainforest, Brazil",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 650,
        date: "05 Oct 2025",
        questType: "general",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 30,
        totalApplicants: 100
    },
    {
        id: 21,
        images: [quest2, quest5, quest6],
        avatar: avatar1,
        name: "Noah Martinez",
        username: "@noahm",
        title: "Volcano Trekking Challenge",
        status: "GoFlick",
        location: "Mount Fuji, Japan",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 500,
        date: "18 Nov 2025",
        questType: "general",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 30,
        totalApplicants: 100
    },
    {
        id: 22,
        images: [quest4, quest5, quest6],
        avatar: avatar3,
        name: "Mia Johnson",
        username: "@miaj",
        title: "Desert Survival Training",
        status: "OnFlick",
        location: "Sahara Desert, Africa",
        description: `A café is more than just a place to grab a cup of coffee; it is a space that blends comfort, culture, and conversation. Whether it’s a small, cozy corner shop or a modern, vibrant café bustling with energy, these spaces serve as a hub for people to relax, work, or socialize. The aroma of freshly brewed coffee, the soothing hum of conversations, and the inviting atmosphere create an experience that extends beyond just a beverage. Many cafés focus on crafting unique ambiances, from rustic wooden interiors and warm lighting to minimalist, contemporary designs. The choice of seating, music, and even the type of cups used contributes to the overall experience, making each café distinct in its charm and appeal. In urban settings, cafés often double as co-working spaces, offering free Wi-Fi and comfortable seating arrangements for freelancers, students, and professionals looking for a change of scenery.\n\nCoffee, the heart and soul of any café, is more than just a drink—it is an art form. From the robust depth of an espresso to the creamy, velvety smoothness of a cappuccino, each cup tells a story of its origin, roast, and brewing method. Coffee culture varies across the world; in Italy, an espresso is a quick, strong shot to be enjoyed at the bar, while in Scandinavia, coffee is often served with pastries as part of a leisurely “fika” tradition. Specialty coffee shops take this appreciation to another level, offering single-origin beans, carefully calibrated brewing techniques, and latte art that turns a simple cup into a masterpiece. The process of making coffee, from selecting high-quality beans to the precise extraction of flavors, requires skill and passion. Whether it’s a simple black coffee or an intricate flavored latte, coffee remains a daily ritual for millions, bringing warmth, energy, and comfort in every sip.`,
        amount: 750,
        date: "12 Jan 2026",
        questType: "general",
        questStatus: "Opened",
        appliedStatus: "Approved",
        applicantCount: 30,
        totalApplicants: 100
    }
];

export default questData;
