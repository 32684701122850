import React from "react";
import Image1 from "../assets/compressed/post1.jpg";
import Image2 from "../assets/compressed/post2.jpg";
import Image3 from "../assets/compressed/post3.jpg";
import Image4 from "../assets/compressed/post4.jpg";
import Image5 from "../assets/compressed/post.jpg";

const Flicks = () => {
    const flicks = [
        Image1, Image2, Image3, Image4, Image5,
        Image1, Image2, Image3, Image4, Image5,
        Image1, Image2
    ];

    return (
        <div className="flicks-grid row g-2">
            {flicks.map((flick, index) => (
                <div key={index} className="col-4 col-md-3">
                    <img src={flick} alt={`Flick ${index + 1}`} className="img-fluid rounded" />
                </div>
            ))}
        </div>
    );
};

export default Flicks;
