import React, { useState } from 'react'
import { FaChevronRight, FaTimes, FaCheck } from "react-icons/fa";


const friendsList = [
    { id: 1, username: "John_Doe", avatar: "https://randomuser.me/api/portraits/men/1.jpg" },
    { id: 2, username: "Jane_Smith", avatar: "https://randomuser.me/api/portraits/women/2.jpg" },
    { id: 3, username: "Mike_Johnson", avatar: "https://randomuser.me/api/portraits/men/3.jpg" },
    { id: 4, username: "Emily_Davis", avatar: "https://randomuser.me/api/portraits/women/4.jpg" },
    { id: 5, username: "Chris_Wilson", avatar: "https://randomuser.me/api/portraits/men/5.jpg" },
    { id: 6, username: "Sophia_Moore", avatar: "https://randomuser.me/api/portraits/women/6.jpg" },
    { id: 7, username: "Daniel_Brown", avatar: "https://randomuser.me/api/portraits/men/7.jpg" },
    { id: 8, username: "Olivia_White", avatar: "https://randomuser.me/api/portraits/women/8.jpg" },
    { id: 9, username: "Liam_Taylor", avatar: "https://randomuser.me/api/portraits/men/9.jpg" },
    { id: 10, username: "Emma_Anson", avatar: "https://randomuser.me/api/portraits/women/10.jpg" },
    { id: 11, username: "Noah_Thomas", avatar: "https://randomuser.me/api/portraits/men/11.jpg" },
    { id: 12, username: "Ava_Jackson", avatar: "https://randomuser.me/api/portraits/women/12.jpg" },
    { id: 13, username: "William_H", avatar: "https://randomuser.me/api/portraits/men/13.jpg" },
    { id: 14, username: "Isabella_Mar", avatar: "https://randomuser.me/api/portraits/women/14.jpg" },
    { id: 15, username: "James_Thomas", avatar: "https://randomuser.me/api/portraits/men/15.jpg" },
    { id: 16, username: "Charlotte", avatar: "https://randomuser.me/api/portraits/women/16.jpg" },
    { id: 17, username: "Benjamin", avatar: "https://randomuser.me/api/portraits/men/17.jpg" },
    { id: 18, username: "Mia_Lewis", avatar: "https://randomuser.me/api/portraits/women/18.jpg" }
];

const StorySetting = () => {
    const [overlayType, setOverlayType] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedFriends, setSelectedFriends] = useState([]);

    const toggleOverlay = (type) => {
        setOverlayType(type);
    };

    const filteredFriends = friendsList.filter(friend =>
        friend.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const toggleSelectFriend = (friendId) => {
        setSelectedFriends(prevSelected =>
            prevSelected.includes(friendId)
                ? prevSelected.filter(id => id !== friendId)
                : [...prevSelected, friendId]
        );
    };

    return (
        <div className="story-setting-container">
            <h3 className="story-setting-header">Advanced Settings</h3>

            <div className="story-setting-section">
                <label className="story-setting-subheader">Views</label>
                <div className="story-setting-row" onClick={() => toggleOverlay('close-friends')}>
                    <span className="story-setting-label">Close Friends</span>
                    <div className="story-setting-value">
                        <span className="story-setting-number">{selectedFriends.length}</span>
                        <FaChevronRight className="story-setting-icon" />
                    </div>
                </div>
                <p className="story-setting-description">Share your story with your close friends.</p>

                <div className="story-setting-row" onClick={() => toggleOverlay('hide-story')}>
                    <span className="story-setting-label">Hide Story</span>
                    <div className="story-setting-value">
                        <span className="story-setting-number">{selectedFriends.length}</span>
                        <FaChevronRight className="story-setting-icon" />
                    </div>
                </div>
                <p className="story-setting-description">You can hide your story from specific people.</p>
            </div>
            {overlayType && (
                <div className="overlay">
                    <div className="overlay-content">
                        <div className="overlay-header">
                            <h3 className='overlay-heading'>Friends</h3>
                            <FaTimes className="close-icon" onClick={() => setOverlayType(null)} />
                        </div>
                        <input
                            type="text"
                            placeholder="Search..."
                            className="setting-search-bar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="friends-grid">
                            {filteredFriends.map((friend) => (
                                <div
                                    className="friend-item"
                                    key={friend.id}
                                    onClick={() => toggleSelectFriend(friend.id)}
                                >
                                    <div className="avatar-wrapper">
                                        <img src={friend.avatar} alt={friend.username} className="setting-avatar" />
                                        {selectedFriends.includes(friend.id) && (
                                            <span className="tick-mark"><FaCheck /></span>
                                        )}
                                    </div>
                                    <span className="setting-username">{friend.username}</span>
                                </div>
                            ))}
                        </div>
                        <button className="close-friend-btn" type='submit' onClick={() => setOverlayType(null)}>
                            {overlayType === 'close-friends' ? 'Close Friend' : 'Hide'}
                        </button>
                    </div>
                </div>
            )}

            <div className="story-setting-section story-setting-border-top">
                <label className="story-setting-subheader">Reply</label>
                <div className="story-setting-options">
                    <label className="story-setting-option">
                        <input type="radio" name="reply" value="followers" className="story-setting-radio" /> People who Follow
                    </label>
                    <label className="story-setting-option">
                        <input type="radio" name="reply" value="anyone" className="story-setting-radio" /> Anyone
                    </label>
                    <label className="story-setting-option">
                        <input type="radio" name="reply" value="off" className="story-setting-radio" /> Off
                    </label>
                </div>
            </div>

            <div className="story-setting-section story-setting-border-top">
                <label className="story-setting-subheader">Reactions</label>
                <div className="story-setting-options">
                    <label className="story-setting-option">
                        <input type="radio" name="reactions" value="followers" className="story-setting-radio" /> People who Follow
                    </label>
                    <label className="story-setting-option">
                        <input type="radio" name="reactions" value="anyone" className="story-setting-radio" /> Anyone
                    </label>
                    <label className="story-setting-option">
                        <input type="radio" name="reactions" value="off" className="story-setting-radio" /> Off
                    </label>
                </div>
            </div>
        </div>
    )
}

export default StorySetting
