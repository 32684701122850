import React, { useState } from "react";

const SortDropdown = ({ onApply, onClose }) => {
    const [sortOptions, setSortOptions] = useState({
        date: "None",
        amount: "None",
        location: "None"
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSortOptions((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const applySorting = () => {
        onApply(sortOptions);
        onClose();
    };
    return (
        <div className="sort-dropdown">
            <div className="sort-option">
                <label>Date</label>
                <select name="date" value={sortOptions.date} onChange={handleChange}>
                    <option value="None">None</option>
                    <option value="asc">Oldest to Newest</option>
                    <option value="desc">Newest to Oldest</option>
                </select>
            </div>
            <div className="sort-option">
                <label>Amount</label>
                <select name="amount" value={sortOptions.amount} onChange={handleChange}>
                    <option value="None">None</option>
                    <option value="asc">Lowest to Highest</option>
                    <option value="desc">Highest to Lowest</option>
                </select>
            </div>
            <div className="sort-option">
                <label>Location</label>
                <select name="location" value={sortOptions.location} onChange={handleChange}>
                    <option value="None">None</option>
                    <option value="nearest">Nearest</option>
                    <option value="farthest">Farthest</option>
                </select>
            </div>
            <button onClick={applySorting} className="apply-sort-btn">Apply</button>
        </div>
    );
};

export default SortDropdown;