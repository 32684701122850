import React, { memo, lazy, Suspense, useState } from "react";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";
import { AiOutlineExclamationCircle, AiOutlineLink, AiOutlineInfoCircle } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { GoBookmark } from "react-icons/go";
import { MdOutlineCancel } from "react-icons/md";
import CommentsSection from "./CommentsSection";
import ShareOptions from "./ShareOptions";
import RepostFlick from "./RepostFlick";
import song from "../assets/compressed/song.png"
import avatar from "../assets/compressed/avatar1.jpg";
import StoryCard, { yourStory } from "./StoryCard";
import StoryView from "./StoryView";

const PostImage = lazy(() => import("../components/PostImage"));

const Post = memo(({ post, toggleHeart, toggleMute, isMuted, toggleSidebar, stories }) => {
    const [isFollowing, setIsFollowing] = useState(false);
    const [activeOverlay, setActiveOverlay] = useState(null);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [showAboutProfile, setShowAboutProfile] = useState(false);
    const [showReportBox, setShowReportBox] = useState(false);
    const [isStoryContainerVisible, setIsStoryContainerVisible] = useState(false);

    const toggleStoryContainer = () => {
        setIsStoryContainerVisible((prev) => !prev);
    };

    const closeStoryContainer = () => {
        setIsStoryContainerVisible(false);
    };


    const handleOverlayClick = (type) => {
        if (window.innerWidth > 1024) {
            toggleSidebar(type, post.comments);
        } else {
            setActiveOverlay(type);
        }
    };

    const toggleMoreOptions = () => {
        setShowMoreOptions((prev) => !prev);
        setShowAboutProfile(false);
    };
    const openAboutProfile = () => {
        setShowMoreOptions(false);
        setShowAboutProfile(true);
    };
    const openReportBox = () => {
        setShowMoreOptions(false);
        setShowReportBox(true);
    };


    const closeAllOverlays = () => {
        setActiveOverlay(null);
        setShowMoreOptions(false);
        setShowAboutProfile(false);
        setShowReportBox(false);
    };

    const [isStoryViewOpen, setIsStoryViewOpen] = useState(false);
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
    const openStoryView = (index) => {
        if (index === 0) {
            setCurrentStoryIndex(index);
            setIsStoryViewOpen(true);
        } else {
            setCurrentStoryIndex(index);
            setIsStoryViewOpen(true);
        }
    };
    const closeStoryView = () => {
        setIsStoryViewOpen(false);
    };


    return (
        <div className="home-post mt-2 mb-5">
            {post.id === 1 && ( /* Show top-avatar only for the first post */
                <div className="top-avatar" onClick={toggleStoryContainer}>
                    <img src={avatar} alt="User Avatar" className="avatar" />
                </div>
            )}
            {isStoryContainerVisible && (
                <div className="story-container-overlay mobile-only">
                    <div className="stories-container">
                        {stories.map((story, index) => (
                            <div
                                key={story.id}
                                onClick={() => openStoryView(index)}
                            >
                                <StoryCard
                                    story={story}
                                    isYourStory={story.id === 1}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {isStoryViewOpen && (
                <StoryView
                    stories={currentStoryIndex === 0 ? [yourStory] : stories}
                    currentStoryIndex={currentStoryIndex}
                    onClose={closeStoryView}
                />
            )}
            <div className="post-container d-flex align-items-end position-relative" onClick={closeStoryContainer}>
                {/* Story Container Overlay */}
                <Suspense fallback={<div>Loading Image...</div>}>
                    <PostImage src={post.img} alt={post.caption} className="post-image" />
                </Suspense>

                <div className="post-icons d-flex flex-column ms-2 position-relative">
                    <div className="icon mb-2 heart-icon" onClick={toggleHeart}>
                        <i className="bi bi-heart-fill"></i>
                        <span>1.5M</span>
                    </div>
                    <div className="icon mb-2" onClick={() => handleOverlayClick("comments")}>
                        <i className="bi bi-chat"></i>
                        <span>2M</span>
                    </div>
                    <div className="icon mb-2" onClick={() => handleOverlayClick("share")}>
                        <i className="bi bi-reply" style={{ transform: 'scaleX(-1)' }}></i>
                        <span>1.2K</span>
                    </div>
                    <div className="icon mb-2" onClick={() => handleOverlayClick("repost")}>
                        <i className="bi bi-repeat"></i>
                        <span>2M</span>
                    </div>
                    <div className="icon mb-2" onClick={toggleMoreOptions}>
                        <i className="bi bi-three-dots"></i>
                    </div>
                    <button className="mute-btn mt-3 text-white" onClick={toggleMute}>
                        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                    </button>
                    {/* More Options Box */}
                    {showMoreOptions && (
                        <div className="more-options-card position-absolute">
                            <div className="option report" onClick={openReportBox}>
                                <i><AiOutlineExclamationCircle className="fs-4" /></i> Report
                            </div>
                            <div className="option">
                                <i><AiOutlineLink className="fs-4" /></i> Copy Link
                            </div>
                            <div className="option">
                                <i><GoBookmark className="fs-4" /></i> Save
                            </div>
                            <div className="option" onClick={openAboutProfile}>
                                <i><AiOutlineInfoCircle className="fs-4" /></i> About this Account
                            </div>
                            <div className="option cancel" onClick={closeAllOverlays}>
                                <i><MdOutlineCancel className="fs-4" /></i> Cancel
                            </div>
                        </div>
                    )}
                    {showAboutProfile && (
                        <div className="about-profile-card">
                            <div className="close-btn close" onClick={closeAllOverlays}>
                                <RxCross2 />
                            </div>
                            <div className="profile-header">
                                <img
                                    src={post.avatar}
                                    alt="User Avatar"
                                    className="profile-avatar"
                                />
                            </div>
                            <div className="profile-info">
                                <p className="username mb-4">{post.username}</p>
                                <p className="date-joined text-start">Date Joined</p>
                                <p className="text-center date-joined">16 January 2021</p>
                                <hr />
                                <p className="location text-start">Location</p>
                                <p className="text-center location">South Africa</p>
                            </div>
                        </div>
                    )}
                    {showReportBox && (
                        <div className="report-box">
                            <div className="close-btn" onClick={closeAllOverlays}>
                                <RxCross2 />
                            </div>
                            <h5>Report</h5>
                            <hr />
                            <p>Why are you reporting this post?</p>
                            <ul className="report-options-list">
                                {[
                                    "I just don’t like it",
                                    "Bullying or unwanted contact",
                                    "Suicide, self-injury, or eating disorders",
                                    "Violence, hate, or exploitation",
                                    "Selling or promoting restricted items",
                                    "Nudity or sexual activity",
                                    "Scam, fraud, or spam",
                                ].map((reason) => (
                                    <li
                                        key={reason}
                                        className="report-option"
                                    // onClick={() => handleReportOption(reason)}
                                    >
                                        {reason}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            <div className="post-caption mt-2">
                <div className="d-flex align-items-center">
                    <img src={post.avatar} alt={post.username} className="post-avatar" loading="lazy" />
                    <p className="username mx-2 text-white">@{post.username}</p>
                    <button
                        className={`btn py-0 mx-2 ${isFollowing ? "btn-following" : "btn-outline-light text-white"}`}
                        onClick={() => setIsFollowing(!isFollowing)}
                    >
                        {isFollowing ? "Following" : "Follow"}
                    </button>
                </div>
                <p className="post-data mb-0">{post.caption}</p>
                <div className="song-info">
                    <button className="song-button">
                        <img src={song} alt="song-avatar" className="song-avatar" />
                        <span className="text-white">{post.song}</span>
                        <span className="text-white">•</span>
                        <span className="text-white">Remix</span>
                    </button>
                </div>
            </div>
            {activeOverlay === "comments" && (
                <div className="comment-section-overlay">
                    <CommentsSection
                        comments={post.comments || []}
                        onCancel={closeAllOverlays}
                    />
                </div>
            )}
            {activeOverlay === "share" && (
                <div className="share-section-overlay">
                    <ShareOptions onCancel={closeAllOverlays} />
                </div>
            )}
            {activeOverlay === "repost" && (
                <div className="repost-section-overlay">
                    <RepostFlick onCancel={closeAllOverlays} />
                </div>
            )}

        </div>
    );
});

export default Post;
