import React, { useState } from 'react';

const NotificationTab = () => {
    const [pauseAll, setPauseAll] = useState(false);
    const [inAppSounds, setInAppSounds] = useState(true);
    const [inAppVibrate, setInAppVibrate] = useState(false);
    const [inAppPreview, setInAppPreview] = useState(true);
    const resetNotifications = () => {
        setPauseAll(false);
        setInAppSounds(false);
        setInAppVibrate(false);
        setInAppPreview(false);
    };

    return (
        <div className="setting-notification-container">
            <div className="setting-notification-section">
                <h6 className="setting-notification-subtitle">All Notification</h6>
                <div className="setting-notification-row">
                    <div className="setting-notification-labels">
                        <div className="setting-notification-label">Pause all</div>
                        <div className="setting-notification-description">Pause all the notifications</div>
                    </div>
                    <label className="setting-notification-switch">
                        <input
                            type="checkbox"
                            checked={pauseAll}
                            onChange={() => {
                                const newPauseAll = !pauseAll;
                                setPauseAll(newPauseAll);
                                if (newPauseAll) {
                                    setInAppSounds(false);
                                    setInAppVibrate(false);
                                    setInAppPreview(false);
                                }
                            }}
                        />
                        <span className="setting-notification-slider" />
                    </label>
                </div>

                <h6 className="setting-notification-subtitle">In app Notifications</h6>
                <div className="setting-notification-row">
                    <div className="setting-notification-label">In-app Sounds</div>
                    <label className="setting-notification-switch">
                        <input
                            type="checkbox"
                            checked={inAppSounds}
                            onChange={() => setInAppSounds(!inAppSounds)}
                        />
                        <span className="setting-notification-slider" />
                    </label>
                </div>
                <div className="setting-notification-row">
                    <div className="setting-notification-label">In-app Vibrate</div>
                    <label className="setting-notification-switch">
                        <input
                            type="checkbox"
                            checked={inAppVibrate}
                            onChange={() => setInAppVibrate(!inAppVibrate)}
                        />
                        <span className="setting-notification-slider" />
                    </label>
                </div>
                <div className="setting-notification-row">
                    <div className="setting-notification-label">In-app Preview</div>
                    <label className="setting-notification-switch">
                        <input
                            type="checkbox"
                            checked={inAppPreview}
                            onChange={() => setInAppPreview(!inAppPreview)}
                        />
                        <span className="setting-notification-slider" />
                    </label>
                </div>
                <span
                    className="setting-notification-reset"
                    onClick={resetNotifications}
                >Reset All Notifications
                </span>
            </div>
        </div>
    );
};

export default NotificationTab;
