import React from "react";
import Chart from "react-apexcharts";

const ApexDonutChart = () => {
    const series = [100, 89, 112, 67];
    const labels = ['Applicants', 'Approved', 'Views', 'Completion'];

    const options = {
        chart: {
            type: 'donut',
            height: 300,
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 1000,
                animateGradually: {
                    enabled: true,
                    delay: 200
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 500
                }
            },
            toolbar: {
                show: false
            }
        },
        labels,
        fill: {
            type: 'gradient',
            gradient: {
                gradientToColors: ['#66B2FF', '#6DFFB2', '#FFD466', '#FF6E6E'],
                shade: 'light',
                type: 'diagonal1',
                shadeIntensity: 0.5,
                opacityFrom: 0.8,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return `${val.toFixed(1)}%`;
            },
            style: {
                fontSize: '14px',
                fontWeight: 600
            },
            dropShadow: {
                enabled: true,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.35
            }
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '65%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '14px',
                            fontWeight: 600
                        },
                        value: {
                            show: true,
                            fontSize: '16px',
                            fontWeight: 700,
                            color: '#BBBBBE'
                        },
                        total: {
                            show: false,
                        }
                    }
                }
            }
        },
        legend: {
            show: false
        },
        stroke: {
            show: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    height: 250
                }
            }
        }]
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <div style={{ width: '250px' }}>
                <Chart options={options} series={series} type="donut" height={300} />
            </div>
            <div>
                {labels.map((label, index) => (
                    <div key={label} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                background: `linear-gradient(135deg, ${options.fill.gradient.gradientToColors[index]} 0%, ${options.fill.gradient.gradientToColors[index]} 100%)`,
                                borderRadius: '50%',
                                marginRight: '8px'
                            }}
                        />
                        <span style={{ fontWeight: 500, color: '#BBBBBE' }}>{label}:</span>
                        <span style={{ marginLeft: '6px', fontWeight: 600, color: '#BBBBBE' }}>{series[index]}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ApexDonutChart;
