import React from "react";
import Filter from "../assets/compressed/filter.webp";

const filters = [
    { name: "No Effect", className: "no-effect" },
    { name: "Jaipur", className: "filter-jaipur" },
    { name: "New York", className: "filter-new-york" },
    { name: "Tokyo Nights", className: "filter-tokyo-nights" },
    { name: "Bali", className: "filter-bali" },
    { name: "Santorini", className: "filter-santorini" },
    { name: "Marrakech", className: "filter-marrakech" },
    { name: "Venice", className: "filter-venice" },
    { name: "Sydney", className: "filter-sydney" },
    { name: "Inkwell", className: "filter-inkwell" },
    { name: "Lark", className: "filter-lark" },
    { name: "Gingham", className: "filter-gingham" },
    { name: "Hudson", className: "filter-hudson" },
    { name: "Amaro", className: "filter-amaro" },
    { name: "Reyes", className: "filter-reyes" },
    { name: "Aden", className: "filter-aden" },
    { name: "Perpetua", className: "filter-perpetua" },
    { name: "Slumber", className: "filter-slumber" },
    { name: "X-Pro II", className: "filter-xpro-ii" },
    { name: "Ethereal", className: "filter-ethereal" },
    { name: "Noir", className: "filter-noir" },
    { name: "Sun-kissed", className: "filter-sun-kissed" },
    { name: "Dusty Film", className: "filter-dusty-film" },
    { name: "Velvet", className: "filter-velvet" },
    { name: "Glass", className: "filter-glass" },
    { name: "Pastel Pop", className: "filter-pastel-pop" },
    { name: "Sunflare", className: "filter-sunflare" },
    { name: "Glow Bloom", className: "filter-glow-bloom" },
    { name: "Frostbite", className: "filter-frostbite" },
    { name: "Vintage", className: "filter-vintage" },
    { name: "Warm", className: "filter-warm" },
    { name: "Cool", className: "filter-cool" },
    { name: "Cinematic", className: "filter-cinematic" },
    { name: "Black & White", className: "filter-bw" },
    { name: "Brighten", className: "filter-brighten" },
    { name: "Darken", className: "filter-darken" },
    { name: "Soft Glow", className: "filter-soft-glow" },
    { name: "Deep Contrast", className: "filter-deep-contrast" },
    { name: "Pastel", className: "filter-pastel" },
    { name: "Matte", className: "filter-matte" },
    { name: "Fade", className: "filter-fade" },
    { name: "Ocean Blue", className: "filter-ocean-blue" },
    { name: "Retro", className: "filter-retro" },
    { name: "Golden Hour", className: "filter-golden-hour" },
    { name: "Lush Green", className: "filter-lush-green" },
    { name: "Purple Haze", className: "filter-purple-haze" },
    { name: "Sunset Glow", className: "filter-sunset-glow" },
    { name: "Drama", className: "filter-drama" },
    { name: "Moody", className: "filter-moody" },
    { name: "Cyberpunk", className: "filter-cyberpunk" },
    { name: "Neon Lights", className: "filter-neon-lights" },
    { name: "Sepia", className: "filter-sepia" },
    { name: "Dreamy", className: "filter-dreamy" },
    { name: "Glow Up", className: "filter-glow-up" },
    { name: "Frosted", className: "filter-frosted" },
    { name: "Dusky", className: "filter-dusky" },
    { name: "Holographic", className: "filter-holographic" },
    { name: "Muted", className: "filter-muted" },
    { name: "Midnight", className: "filter-midnight" },
    { name: "Vivid", className: "filter-vivid" },
    { name: "Forest", className: "filter-forest" },
    { name: "Skyline", className: "filter-skyline" },
    { name: "Faded Pink", className: "filter-faded-pink" },
    { name: "Cloudy", className: "filter-cloudy" },
    { name: "Electric", className: "filter-electric" },
    { name: "Autumn", className: "filter-autumn" },
    { name: "Parisian", className: "filter-parisian" },
    { name: "Los Angeles", className: "filter-los-angeles" },
];

const FiltersPanel = ({ onSelectFilter, activeFilter }) => {
    return (
        <div className="filters-container">
            <h5 className="filter-heading mb-3">Filters</h5>
            <div className="filters-grid">
                {filters.map((filter) => (
                    <button
                        key={filter.name}
                        className={`filter-item ${filter.className} ${activeFilter === filter.className ? 'active' : ''}`}
                        onClick={() => onSelectFilter(filter.className)}
                    >
                        <div className={`filter-preview-wrapper ${filter.className} ${filter.name === "No Effect" ? "no-effect-box" : ""}`}>
                            {filter.name === "No Effect" ? (
                                <svg className="no-effect-icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                    <path d="M25 0C20.0556 0 15.222 1.46622 11.1107 4.21325C6.99952 6.96031 3.79522 10.8648 1.90302 15.4329C0.0108295 20.0011 -0.484254 25.0278 0.480385 29.8772C1.445 34.7267 3.82603 39.1814 7.32233 42.6778C10.8187 46.1739 15.2732 48.555 20.1228 49.5197C24.9722 50.4842 29.9989 49.9892 34.5672 48.097C39.1353 46.2047 43.0397 43.0006 45.7867 38.8892C48.5339 34.7781 50 29.9444 50 25C50 18.3696 47.3661 12.0108 42.6778 7.32233C37.9892 2.63392 31.6306 0 25 0ZM4.16666 25C4.16247 20.1956 5.83225 15.5399 8.88889 11.8333L38.1667 41.1111C35.1183 43.6114 31.4219 45.1942 27.5086 45.675C23.5953 46.1556 19.6261 45.5144 16.063 43.8261C12.5 42.1378 9.49014 39.4717 7.38397 36.1386C5.27777 32.8056 4.16202 28.9428 4.16666 25ZM41.1111 38.1667L11.8333 8.88889C15.8266 5.621 20.8917 3.95358 26.0453 4.21036C31.1986 4.46714 36.0731 6.62978 39.7217 10.2784C43.3703 13.927 45.5328 18.8013 45.7897 23.9547C46.0464 29.1083 44.3789 34.1733 41.1111 38.1667Z" fill="#BBBBBE" />
                                </svg>
                            ) : (
                                <img src={Filter} alt={filter.name} className="filter-preview" />
                            )}
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
};


export default FiltersPanel;
