import React from "react";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import SocialLogin from "../../components/SocialLogin";
import FlickStarLogo from "../../assets/compressed/logo.png";
import auth1 from "../../assets/compressed/auth1.webp";
import auth2 from "../../assets/compressed/auth2.webp";
import auth3 from "../../assets/compressed/auth3.webp";
import { Link } from "react-router-dom";
import PhoneImages from "../../components/PhoneImages";
import AppStoreIcon from "../../assets/compressed/appstore.svg";
import PlayStoreIcon from "../../assets/compressed/googleplay.svg";


const SetPassword = () => {
    const images = [auth1, auth2, auth3];
    return (
        <div className="login-page">
            <PhoneImages images={images} />


            <div className="right-section">
                <div className="logo-content">
                    <img src={FlickStarLogo} alt="FlickStar Logo" className="logo" />
                    <div className="content">FlickStar</div>
                </div>
                <div className="login-box">
                    <form>
                        <h2>Set Password</h2>
                        <p className="text-center pb-4 info-text">Strong password includes alphabets,
                            numbers and special characters</p>
                        <InputField type="text" placeholder="Password" />
                        <InputField type="text" placeholder="Confirm Password" />

                        <Link to="/signup/verification" className="text-decoration-none">
                            <Button text="Next" />
                        </Link>
                        <div className="or">
                            <hr />
                            <span id="or">Sign up With</span>
                            <br />
                        </div>
                        <SocialLogin />
                        <div className="signup-prompt">
                            Already have an account?{" "}
                            <Link to="/" className="signup-link text-decoration-none">
                                Log In
                            </Link>
                        </div>
                    </form>
                </div>
                {/* Get the App Section */}
                <div className="get-the-app mt-4">
                    <div className="app-links d-flex gap-3">
                        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                            <img src={AppStoreIcon} alt="App Store" className="app-icon" />
                        </a>
                        <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                            <img src={PlayStoreIcon} alt="Play Store" className="app-icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetPassword;
