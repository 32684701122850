import React, { useState, useRef, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import imageCompression from "browser-image-compression";
import QuestUploadImageOverlay from "./QuestUploadImageOverlay";
import { RxCross2 } from "react-icons/rx";

const StartQuestForm = ({ onCancel, onSubmit }) => {
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewIndex, setPreviewIndex] = useState(null);

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };
        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Image compression error:", error);
            return file;
        }
    };

    const handleFileChange = async (event) => {
        const files = Array.from(event.target.files);
        const compressedFiles = await Promise.all(files.map((file) => compressImage(file)));
        setSelectedFiles((prev) => [...prev, ...compressedFiles]);
    };

    const handleRemoveImage = (index) => {
        setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        const compressedFiles = await Promise.all(files.map((file) => compressImage(file)));
        setSelectedFiles((prev) => [...prev, ...compressedFiles]);
    };

    const moveImage = useCallback((fromIndex, toIndex) => {
        setSelectedFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            const [movedImage] = updatedFiles.splice(fromIndex, 1);
            updatedFiles.splice(toIndex, 0, movedImage);
            return updatedFiles;
        });
    }, []);


    return (
        <div className="quest-form-container mt-5">
            <Formik
                initialValues={{ description: "" }}
                validate={(values) => {
                    const errors = {};
                    if (!values.description) {
                        errors.description = "Description is required.";
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values, selectedFiles);
                    setSubmitting(false);
                }}>
                {({ isSubmitting }) => (
                    <Form className="quest-detail-form">
                        <label className="quest-detail-label">Description</label>
                        <Field
                            as="textarea"
                            name="description"
                            className="create-quest-textarea"
                            placeholder="Provide brief information about your quest."
                            rows="8"
                        />
                        <ErrorMessage name="description" component="div" className="error-message" />
                        {selectedFiles.length > 0 && (
                            <div className="thumbnails-container mb-0 mt-3 gap-2">
                                <div className="thumbnails-scroll">
                                    {selectedFiles.map((file, index) => (
                                        <div key={index} className="thumbnail-wrapper" onClick={() => setPreviewIndex(index)}>
                                            <img src={URL.createObjectURL(file)} alt="preview" className="thumbnail-image" />
                                            <button className="thumbnail-image-remove-btn" onClick={(e) => { e.stopPropagation(); handleRemoveImage(index); }}>
                                                <RxCross2 size={18} />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <label className="quest-detail-label mt-3">Upload Media</label>
                        <div className="quest-detail-upload-box" onClick={() => fileInputRef.current.click()} onDragOver={(e) => e.preventDefault()} onDrop={handleDrop}>
                            <input type="file" ref={fileInputRef} className="quest-detail-file-input" multiple accept="image/png, image/jpeg" onChange={handleFileChange} hidden />
                            <div className="create-quest-upload-content">
                                <span className="create-quest-upload-icon"><i className="bi bi-cloud-upload-fill"></i></span>
                                <p className="create-quest-upload-text">
                                    <span className="create-quest-upload-title">Upload Files</span>
                                    <span className="create-quest-upload-subtext"> or drag and drop</span><br />
                                    <span className="create-quest-upload-format">PNG, JPG up to 100MB</span>
                                </p>
                            </div>
                        </div>
                        <div className="quest-detail-form-actions">
                            <button className="quest-detail-form-button-cancel" type="button" onClick={onCancel}>
                                Cancel
                            </button>
                            <button className="quest-detail-form-button-submit" type="submit" disabled={isSubmitting}>
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

            <QuestUploadImageOverlay
                images={selectedFiles}
                selectedIndex={previewIndex}
                onClose={() => setPreviewIndex(null)}
                moveImage={moveImage}
                setPreviewIndex={setPreviewIndex}
            />
        </div>
    );
};

export default StartQuestForm;
