import React, { useState, useCallback, useRef, useEffect } from "react";
import avatar1 from "../assets/compressed/avatar1.jpg";
import avatar2 from "../assets/compressed/avatar2.jpg";
import avatar3 from "../assets/compressed/avatar3.jpg";
import post1 from "../assets/compressed/post1.jpg";
import post2 from "../assets/compressed/post2.jpg";
import post3 from "../assets/compressed/post3.jpg";
import post4 from "../assets/compressed/post4.jpg";
import post5 from "../assets/compressed/post5.jpg";
import LeftSidebar from "../components/LeftSidebar";
import StoryCard, { stories, yourStory } from "../components/StoryCard";
import Post from "../components/Post";
import FriendSuggestions from "../components/FriendSuggestions.js";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import MobileNav from "../components/MobileNav.js";
import CommentSection from "../components/CommentsSection.js";
import ShareOptions from "../components/ShareOptions.js";
import RepostFlick from "../components/RepostFlick.js";
import StoryView from "../components/StoryView";
import { Link } from "react-router-dom";
import "../styles/Global.css"
import "../styles/Home.css"

function HomePage() {
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);
    const storiesContainerRef = useRef(null);
    const scrollDistance = storiesContainerRef.current
        ? storiesContainerRef.current.offsetWidth / 2
        : 240;

    const scrollStories = useCallback(() => {
        if (storiesContainerRef.current) {
            storiesContainerRef.current.scrollLeft += scrollDistance;
        }
    }, [scrollDistance]);

    const scrollBack = useCallback(() => {
        if (storiesContainerRef.current) {
            storiesContainerRef.current.scrollLeft -= scrollDistance;
        }
    }, [scrollDistance]);

    const handleScroll = useCallback(() => {
        const container = storiesContainerRef.current;
        if (container) {
            const isAtStart = container.scrollLeft === 0;
            const isAtEnd =
                container.scrollLeft + container.offsetWidth >= container.scrollWidth;
            setShowLeftButton(!isAtStart);
            setShowRightButton(!isAtEnd);
        }
    }, []);

    useEffect(() => {
        const container = storiesContainerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }
        handleScroll();
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [handleScroll]);

    const [isMuted, setIsMuted] = useState(false);
    const toggleMute = useCallback(() => {
        setIsMuted((prev) => !prev);
    }, []);

    const toggleHeart = useCallback((e) => {
        const icon = e.target.closest(".heart-icon").querySelector("i");
        icon.classList.toggle("bi-heart-fill");
        icon.classList.toggle("bi-heart");
        icon.style.color = icon.classList.contains("bi-heart-fill") ? "red" : "";
    }, []);

    const posts = [
        {
            id: 1,
            username: "john_doe",
            caption: "Lorem ipsum dolor sit amet, consequetor meow meow meow",
            song: "coldplay",
            img: post1,
            avatar: avatar1,
            comments: [
                {
                    avatar: avatar1,
                    username: "JohnDoe",
                    time: "53 mins",
                    text: "This is amazing!",
                    liked: false,
                    likes: 10,
                    replies: [
                        {
                            avatar: avatar2,
                            username: "JaneDoe",
                            time: "50 mins",
                            text: "Totally agree!",
                            liked: true,
                            likes: 5,
                        },
                        {
                            avatar: avatar3,
                            username: "TravelGuy",
                            time: "45 mins",
                            text: "Best post ever!",
                            liked: false,
                            likes: 2,
                        },
                    ],
                },
            ],
        },
        {
            id: 2,
            username: "jane_doe",
            caption: "Healthy vibes 🌱",
            song: "Imagine Dragons",
            img: post2,
            avatar: avatar2,
            comments: [
                {
                    avatar: avatar2,
                    username: "JaneDoe",
                    time: "30 mins",
                    text: "Loving the green!",
                    liked: true,
                    likes: 8,
                    replies: [],
                },
            ],
        },
        {
            id: 3,
            username: "travel_guy",
            caption: "The mountains are calling",
            song: "Arctic Monkeys",
            img: post5,
            avatar: avatar3,
            comments: [
                {
                    avatar: avatar3,
                    username: "TravelGuy",
                    time: "1 hr",
                    text: "This view is breathtaking!",
                    liked: false,
                    likes: 15,
                    replies: [
                        {
                            avatar: avatar1,
                            username: "JohnDoe",
                            time: "58 mins",
                            text: "Absolutely stunning!",
                            liked: false,
                            likes: 4,
                        },
                    ],
                },
            ],
        },
        {
            id: 4,
            username: "travel_guy",
            caption: "The mountains are calling",
            song: "Arctic Monkeys",
            img: post4,
            avatar: avatar3,
            comments: [
                {
                    avatar: avatar3,
                    username: "TravelGuy",
                    time: "1 hr",
                    text: "This view is breathtaking!",
                    liked: false,
                    likes: 15,
                    replies: [
                        {
                            avatar: avatar1,
                            username: "JohnDoe",
                            time: "58 mins",
                            text: "Absolutely stunning!",
                            liked: false,
                            likes: 4,
                        },
                    ],
                },
            ],
        },
        {
            id: 5,
            username: "travel_guy",
            caption: "The mountains are calling",
            song: "Arctic Monkeys",
            img: post3,
            avatar: avatar3,
            comments: [
                {
                    avatar: avatar3,
                    username: "TravelGuy",
                    time: "1 hr",
                    text: "This view is breathtaking!",
                    liked: false,
                    likes: 15,
                    replies: [
                        {
                            avatar: avatar1,
                            username: "JohnDoe",
                            time: "58 mins",
                            text: "Absolutely stunning!",
                            liked: false,
                            likes: 4,
                        },
                    ],
                },
            ],
        },
    ];

    const [isStoryViewOpen, setIsStoryViewOpen] = useState(false);
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
    const openStoryView = (index) => {
        if (index === 0) {
            setCurrentStoryIndex(index);
            setIsStoryViewOpen(true);
        } else {
            setCurrentStoryIndex(index);
            setIsStoryViewOpen(true);
        }
    };

    const closeStoryView = () => {
        setIsStoryViewOpen(false);
    };

    const [activeSidebar, setActiveSidebar] = useState("friendSuggestions");
    const [currentSidebarComments, setCurrentSidebarComments] = useState([]);

    const toggleSidebar = (view, comments = []) => {
        setActiveSidebar(view);
        if (view === "comments") {
            setCurrentSidebarComments(comments);
        }
    };

    const renderSidebarContent = () => {
        switch (activeSidebar) {
            case "comments":
                return (
                    <CommentSection
                        comments={currentSidebarComments || []}
                        onCancel={() => toggleSidebar("friendSuggestions")}
                    />
                );
            case "share":
                return (
                    <ShareOptions
                        onCancel={() => toggleSidebar("friendSuggestions")}
                    />
                );
            case "repost":
                return (
                    <RepostFlick
                        onCancel={() => toggleSidebar("friendSuggestions")}
                    />
                );
            default:
                return <FriendSuggestions />;
        }
    };

    return (
        <div className="container-fluid home-page">
            <div className="row h-100">
                <aside className="col-2 d-none d-md-flex flex-column p-3 home-left-sidebar">
                    <LeftSidebar />
                </aside>
                <main className="col-12 col-md-10 col-lg-7 home-main-content">
                    <div className="story-section fixed-top" ref={storiesContainerRef}>
                        <div className="stories-container d-flex" >
                            {stories.map((story, index) => (
                                <div
                                    key={story.id}
                                    onClick={() => openStoryView(index)}
                                >
                                    <StoryCard
                                        story={story}
                                        isYourStory={story.id === 1}
                                    />
                                </div>
                            ))}

                        </div>
                        {showLeftButton && (
                            <button className="scroll-arrow left-arrow" onClick={scrollBack}>
                                <FaAngleLeft />
                            </button>
                        )}
                        {showRightButton && (
                            <button className="scroll-arrow right-arrow" onClick={scrollStories}>
                                <FaAngleRight />
                            </button>
                        )}
                    </div>

                    <div className="posts-container mt-2">
                        {posts.map((post) => (
                            <Post
                                key={post.id}
                                post={post}
                                toggleHeart={toggleHeart}
                                toggleMute={toggleMute}
                                isMuted={isMuted}
                                toggleSidebar={(view) => toggleSidebar(view, post.comments)}
                                stories={stories}
                            />
                        ))}
                    </div>
                </main>
                {isStoryViewOpen && (
                    <StoryView
                        stories={currentStoryIndex === 0 ? [yourStory] : stories}
                        currentStoryIndex={currentStoryIndex}
                        onClose={closeStoryView}
                    />
                )}

                <aside className="col-3 d-none d-lg-flex flex-column py-3 home-right-sidebar">
                    <div className="home-user-details mb-1 d-flex align-items-center justify-content-between">
                        <Link to='/profile' className="d-flex align-items-center text-decoration-none">
                            <img src={avatar1} alt="User Avatar" className="user-avatar" />
                            <div className="ms-3">
                                <p className="username mb-0">John Smith</p>
                                <p className="person-name mb-0">@johnsmith2045 </p>
                            </div>
                        </Link>

                        <div className="user-icons">
                            <svg className="mx-2" xmlns="http://www.w3.org/2000/svg" width="31" height="29" viewBox="0 0 31 29" fill="none">
                                <path d="M27.5556 29H3.44444C1.55 29 0 27.55 0 25.7778V3.22222C0 1.45 1.55 0 3.44444 0H27.5556C29.45 0 31 1.45 31 3.22222V25.7778C31 27.55 29.45 29 27.5556 29Z" fill="#007AFF" />
                                <path d="M21.3557 18.5012C21.3557 22.605 17.3104 23.1179 16.5652 23.1179V25.375H14.649V23.1179C14.0102 23.0153 9.53906 22.605 9.53906 17.6804H13.0521C13.0521 18.1934 13.0521 20.5531 15.7135 20.5531C17.9491 20.5531 17.9491 18.809 17.9491 18.6038C17.9491 14.9104 10.2843 16.4493 10.2843 10.704C10.2843 7.2158 13.5844 6.08726 15.0748 6.08726V3.625H16.991V6.08726C18.162 6.18986 21.4621 7.11321 21.4621 11.4222H17.9491C17.9491 10.4988 17.7362 8.65212 15.7135 8.65212C13.6909 8.65212 13.6908 10.3962 13.6908 10.704C13.5844 14.0896 21.3557 12.6533 21.3557 18.5012Z" fill="white" />
                            </svg>
                        </div>
                    </div>
                    <hr />
                    {renderSidebarContent()}
                </aside>
            </div>
            <MobileNav />
        </div>
    );
}

export default HomePage;
