import React from "react";
import { ReactComponent as GoogleIcon } from "../assets/compressed/google.svg";
import { ReactComponent as AppleIcon } from "../assets/compressed/apple.svg";
import { ReactComponent as FacebookIcon } from "../assets/compressed/facebook.svg";
import { ReactComponent as XIcon } from "../assets/compressed/twitter.svg";

const SocialLogin = () => {
    return (
        <div className="social-login">
            <a href="/google-login" className="social-icon google">
                <GoogleIcon className="icon" />
            </a>
            <a href="/apple-login" className="social-icon apple">
                <AppleIcon className="icon" />
            </a>
            <a href="/facebook-login" className="social-icon facebook">
                <FacebookIcon className="icon" />
            </a>
            <a href="/twitter-login" className="social-icon twitter">
                <XIcon className="icon" />
            </a>
        </div>
    );
};

export default SocialLogin;
