import React, { useEffect, useState } from "react";
import { IoMusicalNote } from "react-icons/io5";
import { ImBin } from "react-icons/im";

const StoryMusic = ({ selectedSong, audioRef, onSelectSong, onDeleteSong }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        if (selectedSong) {
            if (audioRef.current) {
                audioRef.current.pause();
            }
            audioRef.current = new Audio(selectedSong.previewUrl);
            audioRef.current.currentTime = selectedSong.startTime || 0;
            audioRef.current.loop = false;

            const stopAtEndTime = () => {
                if (audioRef.current && audioRef.current.currentTime >= selectedSong.endTime) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = selectedSong.startTime || 0;
                    audioRef.current.play().catch((error) => console.error("Playback restart failed:", error));
                }
            };
            audioRef.current.addEventListener("timeupdate", stopAtEndTime);
            setTimeout(() => {
                audioRef.current.play().then(() => setIsPlaying(true)).catch((error) => console.error("Playback failed:", error));
            }, 100);

            return () => {
                if (audioRef.current) {
                    audioRef.current.removeEventListener("timeupdate", stopAtEndTime);
                    audioRef.current.pause();
                    audioRef.current = null;
                }
            };
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
            setIsPlaying(false);
        }
    }, [selectedSong]);

    return (
        <div
            className={`capsule-button ${isPlaying ? "playing" : ""}`}
            onClick={() => {
                if (!selectedSong) {
                    onSelectSong();
                } else {
                    setShowDelete(true);
                }
            }}>
            <div className="avatar">
                {selectedSong ? (
                    <img src={selectedSong.artworkUrl60} alt="Music Cover" className="music-avatar" />
                ) : (
                    <IoMusicalNote className="icon" />
                )}
            </div>
            <div className="sound-info">
                <p className="scrolling-text">
                    <span>{selectedSong ? selectedSong.trackName : "Add Sound"}</span>
                </p>
                <span className="scrolling-text">
                    <span>{selectedSong ? selectedSong.artistName : "Click here..."}</span>
                </span>
            </div>
            <div className="equalizer">
                <span className="left"></span>
                <span className="middle"></span>
                <span className="right"></span>
            </div>
            {showDelete && selectedSong && (
                <button className="delete-song-button" onClick={(e) => {
                    e.stopPropagation();
                    setShowDelete(false);
                    onDeleteSong();
                }}>
                    <ImBin />
                </button>
            )}
        </div>

    );
};

export default StoryMusic;
