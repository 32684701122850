import React from "react";
import QuestCard from "./QuestCard";

const QuestList = ({ quests, activeTab, subTab }) => {
    return (
        <div className="quest-grid">
            {quests.map((data, index) => (
                <QuestCard key={index} data={data} activeTab={activeTab} subTab={subTab} />
            ))}
        </div>
    );
};


export default QuestList;
