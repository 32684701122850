import React, { useEffect, useState } from "react";
import "react-advanced-cropper/dist/style.css";
import { RxCross2 } from "react-icons/rx";
import FlickToolButtons from "./FlickToolButtons";
import FlickBottomActions from "./FlickBottomActions";
import FlickPopupConfirmation from "./FlickPopupConfirmation";
import FlickImagePreview from "./FlickImagePreview";

const FlickOptions = ({ file, onCancel }) => {
    const [imageSrcList, setImageSrcList] = useState([]);
    const [videoSrc, setVideoSrc] = useState(null);
    const [activeTool] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [actionType, setActionType] = useState(null);

    useEffect(() => {
        if (file) {
            if (Array.isArray(file)) {
                const images = file.filter(f => f.type.startsWith("image/")).map(f => URL.createObjectURL(f));
                const videos = file.filter(f => f.type.startsWith("video/"));

                setImageSrcList(images);
                setVideoSrc(videos.length > 0 ? URL.createObjectURL(videos[0]) : null);
            } else {
                if (file.type.startsWith("image/")) {
                    setImageSrcList([URL.createObjectURL(file)]);
                } else if (file.type.startsWith("video/")) {
                    setVideoSrc(URL.createObjectURL(file));
                }
            }
        }
    }, [file]);

    const handleCancel = () => {
        setActionType("cancel");
        setShowPopup(true);
    };

    const confirmAction = () => {
        if (actionType === "cancel") {
            onCancel();
        }
        setShowPopup(false);
    };

    return (
        <div className="story-options-container">
            <div className="top-section">
                <button className="reset-button">Reset</button>
                <div className="cancel-finalize-buttons text-end">
                    <button className="finalize-button">Preview</button>
                    <button className="close-button" onClick={handleCancel}><RxCross2 /></button>
                </div>
            </div>
            <div className="image-container">
                <FlickToolButtons activeTool={activeTool} />
                <FlickImagePreview activeTool={activeTool} imageList={imageSrcList} videoSrc={videoSrc} />
            </div>
            <FlickBottomActions />
            <FlickPopupConfirmation showPopup={showPopup} actionType={actionType} confirmAction={confirmAction} setShowPopup={setShowPopup} />
        </div>
    );
};

export default FlickOptions;
