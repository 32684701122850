import React, { useRef } from "react";
import imageCompression from "browser-image-compression";

const UploadContainer = ({ onUpload, activeTab }) => {
    const fileInputRef = useRef(null);
    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };
        try {
            return await imageCompression(file, options);
        } catch (error) {
            console.error("Image compression error:", error);
            return file;
        }
    };

    const handleFileChange = async (event) => {
        let files = Array.from(event.target.files);

        if (activeTab === "Story") {
            files = files.length > 0 ? [files[0]] : [];
        } else if (activeTab === "Flicks") {
            const imageFiles = files.filter(file => file.type.startsWith("image/"));
            const videoFiles = files.filter(file => file.type.startsWith("video/"));

            if (videoFiles.length > 0) {
                files = [videoFiles[0]];
            } else {
                files = imageFiles.slice(0, 14);
            }
        }
        const compressedFiles = await Promise.all(
            files.map(async (file) => {
                if (file.type.startsWith("image/")) {
                    return compressImage(file);
                }
                return file;
            })
        );

        onUpload(activeTab === "Flicks" ? compressedFiles : compressedFiles[0]);
    };

    return (
        <div className="upload-outer-container">
            <div className="upload-container d-flex flex-column align-items-center justify-content-center">
                <div className="upload-icon-circle d-flex align-items-center justify-content-center">
                    <i className="bi bi-cloud-upload-fill upload-icon"></i>
                </div>
                <h3 className="upload-text">Upload your Content</h3>
                <p className="upload-subtext">
                    Drag your photos and videos with your followers
                </p>
                <input
                    type="file"
                    accept="image/*,video/*,.mp4,.mov,.avi,.wmv,.flv,.mkv,.webm,.heic,.heif"
                    className="d-none"
                    id="fileInput"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    multiple={activeTab === "Flicks"}
                />
                <label htmlFor="fileInput" className="btn btn-primary select-files-button">
                    + Select Files
                </label>
            </div>
        </div>
    );
};

export default UploadContainer;
