import React from "react";
import quest3 from "../assets/compressed/quest3.webp";
import quest2 from "../assets/compressed/quest2.webp";
import quest1 from "../assets/compressed/quest1.webp";

const questData = [
    {
        image: quest2,
        title: "Visit My Coffee Cafe",
        status: "400/400",
        description: "Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us! Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us!",
        amount: 100,
    },
    {
        image: quest3,
        title: "Visit My Coffee Cafe",
        status: "100/400",
        description: "Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us! Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us!",
        amount: 100,
    },
    {
        image: quest1,
        title: "Visit My Coffee Cafe",
        status: "400/400",
        description: "Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us! Get a free latte with a 10% discount. Offer valid only for today at our Coffee Cafe. Come and enjoy your time with us!",
        amount: 100,
    },
];

const QuestCard = ({ data }) => {
    return (
        <div className="user-profile-quest-card mb-2">
            {/* Image Section */}
            <div className="user-profile-quest-image-wrapper">
                <img src={data.image} alt="Quest" className="user-profile-quest-image" />
                {/* Top-right Icons */}
                <div className="user-profile-quest-overlay-top-right">
                    <h6 className="user-profile-quest-status">Completed</h6>
                </div>
            </div>

            {/* Title and GO Flick (Inline, No Background) */}
            <div className="user-profile-quest-title-wrapper">
                <h5 className="user-profile-quest-title mb-0">{data.title}</h5>
                <span className="user-profile-quest-go-flick text-white">{data.status}</span>
            </div>

            {/* Bottom Overlay with Description and Amount (Inline) */}
            <div className="user-profile-quest-overlay-bottom">
                <p className="user-profile-quest-description mb-0">{data.description}</p>
                <span className="user-profile-quest-amount">${data.amount}</span>
            </div>
        </div>
    );
};

const ProfileQuest = () => {
    return (
        <div className="user-profile-quest-container">
            {questData.map((data, index) => (
                <QuestCard key={index} data={data} />
            ))}
        </div>
    );
};

export default ProfileQuest;
