
const CountryData = [
    {
        "country": "Afghanistan",
        "code": "AF",
        "flag": "https://flagcdn.com/w320/af.png",
        "phone_code": "+93"
    },
    {
        "country": "Albania",
        "code": "AL",
        "flag": "https://flagcdn.com/w320/al.png",
        "phone_code": "+355"
    },
    {
        "country": "Algeria",
        "code": "DZ",
        "flag": "https://flagcdn.com/w320/dz.png",
        "phone_code": "+213"
    },
    {
        "country": "Andorra",
        "code": "AD",
        "flag": "https://flagcdn.com/w320/ad.png",
        "phone_code": "+376"
    },
    {
        "country": "Angola",
        "code": "AO",
        "flag": "https://flagcdn.com/w320/ao.png",
        "phone_code": "+244"
    },
    {
        "country": "Argentina",
        "code": "AR",
        "flag": "https://flagcdn.com/w320/ar.png",
        "phone_code": "+54"
    },
    {
        "country": "Armenia",
        "code": "AM",
        "flag": "https://flagcdn.com/w320/am.png",
        "phone_code": "+374"
    },
    {
        "country": "Australia",
        "code": "AU",
        "flag": "https://flagcdn.com/w320/au.png",
        "phone_code": "+61"
    },
    {
        "country": "Austria",
        "code": "AT",
        "flag": "https://flagcdn.com/w320/at.png",
        "phone_code": "+43"
    },
    {
        "country": "Azerbaijan",
        "code": "AZ",
        "flag": "https://flagcdn.com/w320/az.png",
        "phone_code": "+994"
    },
    {
        "country": "Bahamas",
        "code": "BS",
        "flag": "https://flagcdn.com/w320/bs.png",
        "phone_code": "+1"
    },
    {
        "country": "Bahrain",
        "code": "BH",
        "flag": "https://flagcdn.com/w320/bh.png",
        "phone_code": "+973"
    },
    {
        "country": "Bangladesh",
        "code": "BD",
        "flag": "https://flagcdn.com/w320/bd.png",
        "phone_code": "+880"
    },
    {
        "country": "Barbados",
        "code": "BB",
        "flag": "https://flagcdn.com/w320/bb.png",
        "phone_code": "+1"
    },
    {
        "country": "Belarus",
        "code": "BY",
        "flag": "https://flagcdn.com/w320/by.png",
        "phone_code": "+375"
    },
    {
        "country": "Belgium",
        "code": "BE",
        "flag": "https://flagcdn.com/w320/be.png",
        "phone_code": "+32"
    },
    {
        "country": "Belize",
        "code": "BZ",
        "flag": "https://flagcdn.com/w320/bz.png",
        "phone_code": "+501"
    },
    {
        "country": "Benin",
        "code": "BJ",
        "flag": "https://flagcdn.com/w320/bj.png",
        "phone_code": "+229"
    },
    {
        "country": "Bhutan",
        "code": "BT",
        "flag": "https://flagcdn.com/w320/bt.png",
        "phone_code": "+975"
    },
    {
        "country": "Bolivia",
        "code": "BO",
        "flag": "https://flagcdn.com/w320/bo.png",
        "phone_code": "+591"
    },
    {
        "country": "Bosnia and Herzegovina",
        "code": "BA",
        "flag": "https://flagcdn.com/w320/ba.png",
        "phone_code": "+387"
    },
    {
        "country": "Botswana",
        "code": "BW",
        "flag": "https://flagcdn.com/w320/bw.png",
        "phone_code": "+267"
    },
    {
        "country": "Brazil",
        "code": "BR",
        "flag": "https://flagcdn.com/w320/br.png",
        "phone_code": "+55"
    },
    {
        "country": "Bulgaria",
        "code": "BG",
        "flag": "https://flagcdn.com/w320/bg.png",
        "phone_code": "+359"
    },
    {
        "country": "Burkina Faso",
        "code": "BF",
        "flag": "https://flagcdn.com/w320/bf.png",
        "phone_code": "+226"
    },
    {
        "country": "Burundi",
        "code": "BI",
        "flag": "https://flagcdn.com/w320/bi.png",
        "phone_code": "+257"
    },
    {
        "country": "Cambodia",
        "code": "KH",
        "flag": "https://flagcdn.com/w320/kh.png",
        "phone_code": "+855"
    },
    {
        "country": "Cameroon",
        "code": "CM",
        "flag": "https://flagcdn.com/w320/cm.png",
        "phone_code": "+237"
    },
    {
        "country": "Canada",
        "code": "CA",
        "flag": "https://flagcdn.com/w320/ca.png",
        "phone_code": "+1"
    },
    {
        "country": "Chile",
        "code": "CL",
        "flag": "https://flagcdn.com/w320/cl.png",
        "phone_code": "+56"
    },
    {
        "country": "China",
        "code": "CN",
        "flag": "https://flagcdn.com/w320/cn.png",
        "phone_code": "+86"
    },
    {
        "country": "Colombia",
        "code": "CO",
        "flag": "https://flagcdn.com/w320/co.png",
        "phone_code": "+57"
    },
    {
        "country": "Costa Rica",
        "code": "CR",
        "flag": "https://flagcdn.com/w320/cr.png",
        "phone_code": "+506"
    },
    {
        "country": "Croatia",
        "code": "HR",
        "flag": "https://flagcdn.com/w320/hr.png",
        "phone_code": "+385"
    },
    {
        "country": "Cuba",
        "code": "CU",
        "flag": "https://flagcdn.com/w320/cu.png",
        "phone_code": "+53"
    },
    {
        "country": "Cyprus",
        "code": "CY",
        "flag": "https://flagcdn.com/w320/cy.png",
        "phone_code": "+357"
    },
    {
        "country": "Czech Republic",
        "code": "CZ",
        "flag": "https://flagcdn.com/w320/cz.png",
        "phone_code": "+420"
    },
    {
        "country": "Denmark",
        "code": "DK",
        "flag": "https://flagcdn.com/w320/dk.png",
        "phone_code": "+45"
    },
    {
        "country": "Ecuador",
        "code": "EC",
        "flag": "https://flagcdn.com/w320/ec.png",
        "phone_code": "+593"
    },
    {
        "country": "Egypt",
        "code": "EG",
        "flag": "https://flagcdn.com/w320/eg.png",
        "phone_code": "+20"
    },
    {
        "country": "El Salvador",
        "code": "SV",
        "flag": "https://flagcdn.com/w320/sv.png",
        "phone_code": "+503"
    },
    {
        "country": "Estonia",
        "code": "EE",
        "flag": "https://flagcdn.com/w320/ee.png",
        "phone_code": "+372"
    },
    {
        "country": "Ethiopia",
        "code": "ET",
        "flag": "https://flagcdn.com/w320/et.png",
        "phone_code": "+251"
    },
    {
        "country": "Fiji",
        "code": "FJ",
        "flag": "https://flagcdn.com/w320/fj.png",
        "phone_code": "+679"
    },
    {
        "country": "Finland",
        "code": "FI",
        "flag": "https://flagcdn.com/w320/fi.png",
        "phone_code": "+358"
    },
    {
        "country": "France",
        "code": "FR",
        "flag": "https://flagcdn.com/w320/fr.png",
        "phone_code": "+33"
    },
    {
        "country": "Georgia",
        "code": "GE",
        "flag": "https://flagcdn.com/w320/ge.png",
        "phone_code": "+995"
    },
    {
        "country": "Germany",
        "code": "DE",
        "flag": "https://flagcdn.com/w320/de.png",
        "phone_code": "+49"
    },
    {
        "country": "Ghana",
        "code": "GH",
        "flag": "https://flagcdn.com/w320/gh.png",
        "phone_code": "+233"
    },
    {
        "country": "Greece",
        "code": "GR",
        "flag": "https://flagcdn.com/w320/gr.png",
        "phone_code": "+30"
    },
    {
        "country": "Guatemala",
        "code": "GT",
        "flag": "https://flagcdn.com/w320/gt.png",
        "phone_code": "+502"
    },
    {
        "country": "Honduras",
        "code": "HN",
        "flag": "https://flagcdn.com/w320/hn.png",
        "phone_code": "+504"
    },
    {
        "country": "Hungary",
        "code": "HU",
        "flag": "https://flagcdn.com/w320/hu.png",
        "phone_code": "+36"
    },
    {
        "country": "Iceland",
        "code": "IS",
        "flag": "https://flagcdn.com/w320/is.png",
        "phone_code": "+354"
    },
    {
        "country": "India",
        "code": "IN",
        "flag": "https://flagcdn.com/w320/in.png",
        "phone_code": "+91"
    },
    {
        "country": "Indonesia",
        "code": "ID",
        "flag": "https://flagcdn.com/w320/id.png",
        "phone_code": "+62"
    },
    {
        "country": "Iran",
        "code": "IR",
        "flag": "https://flagcdn.com/w320/ir.png",
        "phone_code": "+98"
    },
    {
        "country": "Iraq",
        "code": "IQ",
        "flag": "https://flagcdn.com/w320/iq.png",
        "phone_code": "+964"
    },
    {
        "country": "Ireland",
        "code": "IE",
        "flag": "https://flagcdn.com/w320/ie.png",
        "phone_code": "+353"
    },
    {
        "country": "Israel",
        "code": "IL",
        "flag": "https://flagcdn.com/w320/il.png",
        "phone_code": "+972"
    },
    {
        "country": "Italy",
        "code": "IT",
        "flag": "https://flagcdn.com/w320/it.png",
        "phone_code": "+39"
    },
    {
        "country": "Jamaica",
        "code": "JM",
        "flag": "https://flagcdn.com/w320/jm.png",
        "phone_code": "+1"
    },
    {
        "country": "Japan",
        "code": "JP",
        "flag": "https://flagcdn.com/w320/jp.png",
        "phone_code": "+81"
    },
    {
        "country": "Jordan",
        "code": "JO",
        "flag": "https://flagcdn.com/w320/jo.png",
        "phone_code": "+962"
    },
    {
        "country": "Kazakhstan",
        "code": "KZ",
        "flag": "https://flagcdn.com/w320/kz.png",
        "phone_code": "+7"
    },
    {
        "country": "Kenya",
        "code": "KE",
        "flag": "https://flagcdn.com/w320/ke.png",
        "phone_code": "+254"
    },
    {
        "country": "Kuwait",
        "code": "KW",
        "flag": "https://flagcdn.com/w320/kw.png",
        "phone_code": "+965"
    },
    {
        "country": "Lebanon",
        "code": "LB",
        "flag": "https://flagcdn.com/w320/lb.png",
        "phone_code": "+961"
    },
    {
        "country": "Malaysia",
        "code": "MY",
        "flag": "https://flagcdn.com/w320/my.png",
        "phone_code": "+60"
    },
    {
        "country": "Maldives",
        "code": "MV",
        "flag": "https://flagcdn.com/w320/mv.png",
        "phone_code": "+960"
    },
    {
        "country": "Mexico",
        "code": "MX",
        "flag": "https://flagcdn.com/w320/mx.png",
        "phone_code": "+52"
    },
    {
        "country": "Moldova",
        "code": "MD",
        "flag": "https://flagcdn.com/w320/md.png",
        "phone_code": "+373"
    },
    {
        "country": "Monaco",
        "code": "MC",
        "flag": "https://flagcdn.com/w320/mc.png",
        "phone_code": "+377"
    },
    {
        "country": "Mongolia",
        "code": "MN",
        "flag": "https://flagcdn.com/w320/mn.png",
        "phone_code": "+976"
    },
    {
        "country": "Montenegro",
        "code": "ME",
        "flag": "https://flagcdn.com/w320/me.png",
        "phone_code": "+382"
    },
    {
        "country": "Morocco",
        "code": "MA",
        "flag": "https://flagcdn.com/w320/ma.png",
        "phone_code": "+212"
    },
    {
        "country": "Nepal",
        "code": "NP",
        "flag": "https://flagcdn.com/w320/np.png",
        "phone_code": "+977"
    },
    {
        "country": "Netherlands",
        "code": "NL",
        "flag": "https://flagcdn.com/w320/nl.png",
        "phone_code": "+31"
    },
    {
        "country": "New Zealand",
        "code": "NZ",
        "flag": "https://flagcdn.com/w320/nz.png",
        "phone_code": "+64"
    },
    {
        "country": "Nigeria",
        "code": "NG",
        "flag": "https://flagcdn.com/w320/ng.png",
        "phone_code": "+234"
    },
    {
        "country": "North Macedonia",
        "code": "MK",
        "flag": "https://flagcdn.com/w320/mk.png",
        "phone_code": "+389"
    },
    {
        "country": "Norway",
        "code": "NO",
        "flag": "https://flagcdn.com/w320/no.png",
        "phone_code": "+47"
    },
    {
        "country": "Pakistan",
        "code": "PK",
        "flag": "https://flagcdn.com/w320/pk.png",
        "phone_code": "+92"
    },
    {
        "country": "Palestine",
        "code": "PS",
        "flag": "https://flagcdn.com/w320/ps.png",
        "phone_code": "+970"
    },
    {
        "country": "Panama",
        "code": "PA",
        "flag": "https://flagcdn.com/w320/pa.png",
        "phone_code": "+507"
    },
    {
        "country": "Peru",
        "code": "PE",
        "flag": "https://flagcdn.com/w320/pe.png",
        "phone_code": "+51"
    },
    {
        "country": "Philippines",
        "code": "PH",
        "flag": "https://flagcdn.com/w320/ph.png",
        "phone_code": "+63"
    },
    {
        "country": "Poland",
        "code": "PL",
        "flag": "https://flagcdn.com/w320/pl.png",
        "phone_code": "+48"
    },
    {
        "country": "Portugal",
        "code": "PT",
        "flag": "https://flagcdn.com/w320/pt.png",
        "phone_code": "+351"
    },
    {
        "country": "Qatar",
        "code": "QA",
        "flag": "https://flagcdn.com/w320/qa.png",
        "phone_code": "+974"
    },
    {
        "country": "Romania",
        "code": "RO",
        "flag": "https://flagcdn.com/w320/ro.png",
        "phone_code": "+40"
    },
    {
        "country": "Russia",
        "code": "RU",
        "flag": "https://flagcdn.com/w320/ru.png",
        "phone_code": "+7"
    },
    {
        "country": "Saudi Arabia",
        "code": "SA",
        "flag": "https://flagcdn.com/w320/sa.png",
        "phone_code": "+966"
    },
    {
        "country": "Serbia",
        "code": "RS",
        "flag": "https://flagcdn.com/w320/rs.png",
        "phone_code": "+381"
    },
    {
        "country": "Singapore",
        "code": "SG",
        "flag": "https://flagcdn.com/w320/sg.png",
        "phone_code": "+65"
    },
    {
        "country": "Slovakia",
        "code": "SK",
        "flag": "https://flagcdn.com/w320/sk.png",
        "phone_code": "+421"
    },
    {
        "country": "Slovenia",
        "code": "SI",
        "flag": "https://flagcdn.com/w320/si.png",
        "phone_code": "+386"
    },
    {
        "country": "South Africa",
        "code": "ZA",
        "flag": "https://flagcdn.com/w320/za.png",
        "phone_code": "+27"
    },
    {
        "country": "South Korea",
        "code": null,
        "flag": null,
        "phone_code": null
    },
    {
        "country": "Spain",
        "code": "ES",
        "flag": "https://flagcdn.com/w320/es.png",
        "phone_code": "+34"
    },
    {
        "country": "Sri Lanka",
        "code": "LK",
        "flag": "https://flagcdn.com/w320/lk.png",
        "phone_code": "+94"
    },
    {
        "country": "Sweden",
        "code": "SE",
        "flag": "https://flagcdn.com/w320/se.png",
        "phone_code": "+46"
    },
    {
        "country": "Switzerland",
        "code": "CH",
        "flag": "https://flagcdn.com/w320/ch.png",
        "phone_code": "+41"
    },
    {
        "country": "Thailand",
        "code": "TH",
        "flag": "https://flagcdn.com/w320/th.png",
        "phone_code": "+66"
    },
    {
        "country": "Turkey",
        "code": "TR",
        "flag": "https://flagcdn.com/w320/tr.png",
        "phone_code": "+90"
    },
    {
        "country": "UAE",
        "code": "AE",
        "flag": "https://flagcdn.com/w320/ae.png",
        "phone_code": "+971"
    },
    {
        "country": "UK",
        "code": "GB",
        "flag": "https://flagcdn.com/w320/gb.png",
        "phone_code": "+44"
    },
    {
        "country": "USA",
        "code": "US",
        "flag": "https://flagcdn.com/w320/us.png",
        "phone_code": "+1"
    },
    {
        "country": "Ukraine",
        "code": "UA",
        "flag": "https://flagcdn.com/w320/ua.png",
        "phone_code": "+380"
    },
    {
        "country": "Venezuela",
        "code": "VE",
        "flag": "https://flagcdn.com/w320/ve.png",
        "phone_code": "+58"
    },
    {
        "country": "Vietnam",
        "code": "VN",
        "flag": "https://flagcdn.com/w320/vn.png",
        "phone_code": "+84"
    },
    {
        "country": "Yemen",
        "code": "YE",
        "flag": "https://flagcdn.com/w320/ye.png",
        "phone_code": "+967"
    },
    {
        "country": "Zambia",
        "code": "ZM",
        "flag": "https://flagcdn.com/w320/zm.png",
        "phone_code": "+260"
    },
    {
        "country": "Zimbabwe",
        "code": "ZW",
        "flag": "https://flagcdn.com/w320/zw.png",
        "phone_code": "+263"
    }
]

export default CountryData
